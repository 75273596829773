import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import {
  baseApiURL,
  artistPressCount,
  lottieOption,
} from "../../Helpers/Constant";
import {
  disableLangButton,
  enableLangButton,
  splitArray,
  openInNewTab,
} from "../../Helpers/Helper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FairListSkeleton } from "../../components/Skeletons/Fairs/FairListSkeleton";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";

import { FairListCard } from "./FairListCard";
import axios from "axios";

export const FairList = () => {
  let navigate = useNavigate();

  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [fairYears, setFairYears] = useState([]);

  const [fairs, setFairs] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [type, setType] = useState(0);
  const [year, setYear] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [blockUiFair, setblockUiFair] = useState(true);
  const [recordCount, setRecordCount] = useState(6);
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const _showMore = () => {
    var nextPage = pageNumber + 1;
    if (nextPage > maxPageNumber) return;

    setIsLoading(true);
    setPageNumber(nextPage);
  };

  const _getFairYears = async () => {
    const getartistDetailUrl = baseApiURL + "/fairs/getPublishedFairYears";
    const requestData = {
      langId: dirimart.langId,
    };
    var config = {
      method: "post",
      url: getartistDetailUrl,
      data: requestData,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getFairs = async () => {
    const getFairsUrl = baseApiURL + "/fairs/getPublishedFairs";

    const data = {
      langId: dirimart.langId,
      pageNumber: pageNumber,
      recordCount: recordCount,
      year: year,
    };
    var config = {
      method: "post",
      url: getFairsUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _getTotalCount = async () => {
    const url = baseApiURL + "/fairs/getFairTotalCount";
    const data = { year: year };
    var config = {
      method: "post",
      url: url,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _changeYear = (year) => {
    if (pageNumber !== 1) {
      setPageNumber(1);
      return;
    }
    setYear(year);
  };

  const _prepare = () => {
    setDirimartData(disableLangButton(dirimart));
    setblockUiFair(true);
    _getFairYears().then((response) => {
      setFairYears(response.years);
    });
    _getFairs().then((response) => {
      setFairs(response.publishedFairs);
      setIsLoading(false);
    });
    _getTotalCount().then((response) => {
      var maxPage = Math.ceil(response / recordCount);
      if (pageNumber < maxPage) setShowMore(true);
      setMaxPageNumber(maxPage);
      setblockUiFair(false);
      setDirimartData(enableLangButton(dirimart));
    });
  };
  useEffect(() => {
    setIsInitialLoad(false);
    _prepare();
  }, []);
  useEffect(() => {
    if (isInitialLoad) return;
    setIsLoading(true);
    setFairs([]);
    setShowMore(false);
    _getTotalCount().then((response) => {
      var maxPage = Math.ceil(response / recordCount);
      if (pageNumber < maxPage) setShowMore(true);
      setMaxPageNumber(maxPage);
    });
    _getFairs().then((response) => {
      setFairs(response.publishedFairs);
      setIsLoading(false);
    });
  }, [year]);

  useEffect(() => {
    if (isInitialLoad) return;

    _getFairs().then((response) => {
      if (pageNumber !== 1) setFairs(fairs.concat(response.publishedFairs));
      else setFairs(response.publishedFairs);

      setIsLoading(false);
    });
    if (pageNumber === maxPageNumber) setShowMore(false);
  }, [pageNumber]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
      return;
    }
    _prepare();
  }, [dirimart.langId]);
  return (
    <>
      <div className="fairsPageContainer">
        <div>
          <div className="head">
            <div>
              <h2>{translation.Fair.Title}</h2>
            </div>
            <div>
              {fairYears &&
                fairYears.map((fairYear) => {
                  return (
                    <a
                      key={fairYear.year}
                      onClick={() => _changeYear(fairYear.year)}
                      className={fairYear.year == year ? "active" : ""}
                    >
                      {fairYear.year}
                    </a>
                  );
                })}
              <a onClick={() => _changeYear(null)}>{translation.Fair.all}</a>
            </div>
          </div>
          <div>
            <div className="fairs">
              {fairs &&
                fairs.map((fair, index) => {
                  let fairName = fair.title;
                  return (
                    <Link
                      key={fair.title + index}
                      to={
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                        `/fairs/${removeSpecialCharactersAndWhiteSpace(
                          fairName
                        )}/${fair.seoId}`
                      }
                    >
                      <FairListCard
                        imageUrl={fair.imageURL}
                        title={fair.title}
                        summary={fair.summary}
                      />
                    </Link>
                  );
                })}
              {isLoading && <FairListSkeleton></FairListSkeleton>}
            </div>
          </div>
        </div>
        <div className="grid place-content-center">
          {showMore && 
          <button
            className="btnWhite"
            onClick={() => _showMore()}
            disabled={!showMore}
          >
            {translation.Common.seemoreFairs}
          </button>
          }
        </div>
      </div>
    </>
  );
};
