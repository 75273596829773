import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const SelectedWorksComponentSkeleton = () => {
  return (
    <div id="selectedWorks" className="selectedWorks skeletonPublicationComponent">
      <h1 style={{width:"30%"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></h1>
      <div className="works noneClass">
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
        <div className="workCard showModalBtn" style={{backgrpund:"transparent"}}>
          <div style={{height:"300px",width:"100%",background:"transparent"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></div>
         
        </div>
       
        
      </div>
      
    </div>
  )
}

