import React,{useContext} from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import {openInNewTab} from "../../Helpers/Helper";
import { AppContext } from '../../ContextAPI/AppProvider'
import parse from 'html-react-parser';



export const Summary=(props)=> {
  const navigate=useNavigate();
  const { translation } = useContext(AppContext);
  return (
    <>
      <div className="bioText" style={{maxWidth:"650px"}}>
          <h1>{props.fullName}</h1>
          <p>{props.summary&& parse(props.summary)}</p>
          <a className="downloadCV" onClick={()=>{openInNewTab(props.cvUrl)}} >{translation.Common.downloadcv}<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 12L12 4" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M5.5 4H12V10.5" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </a>
        </div>
    </>
  )
}
