/* eslint-disable */
import { lang } from "moment";
import React, { createContext, useState, useCallback } from "react";
import language from "../Helpers/language.json"
import { cultures } from "../Helpers/Constant"
import {useLocalStorage} from '../Helpers/useLocalStorage';
import {LangId} from "../Helpers/LangId";
export const AppContext = createContext({});



export const AppProvider = ({ children }) => {
  
  const [langId,setLangId]=useState(LangId);

  const[cart,setCart]=useLocalStorage("cart",null)
 
  const initialDirimart = {
    langId: langId||1055,
    culture:cultures[langId||1055],
    langButtonDisabled:false,
    cart:cart
  };
  const [dirimart, setDirimart] = useState(initialDirimart);
  let translation = language[dirimart.langId];
  const setDirimartData = useCallback(
    (args) => {
      translation = language[args.langId]
      setDirimart(args);
      setCart(args.cart)
    },
    [dirimart]
  );

  return (
    <AppContext.Provider
      value={{
        dirimart,
        translation,
        setDirimartData
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
