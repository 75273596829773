import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import {
  baseApiURL,
  newsListCount,
  newsTypes,
  lottieOption,
} from "../../Helpers/Constant";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import axios from "axios";
import BlockUI from "react-block-ui";
import Masonry from "react-masonry-css";

export const RelatedNewsList = (props) => {
  let navigate = useNavigate();

  const { dirimart, translation } = useContext(AppContext);
  const [news, setNews] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [blockUi, setBlockUi] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const _showMore = () => {
    var nextPage = pageNumber + 1;
    if (nextPage > maxPageNumber) return;
    setPageNumber(nextPage);
  };

  const _getNews = async () => {
    const getFormDataUrl = baseApiURL + "/news/getRealtedPublishedNews";
    const data = {
      langId: dirimart.langId,
      recordCount: newsListCount,
      relatedEntityId: props.relatedEntityId,
      pageNumber: pageNumber,
      relatedEntityName: props.relatedEntityName,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.news))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getNewsCount = async () => {
    const getFormDataUrl = baseApiURL + "/news/getRealtedNewsTotalCount";
    let data = {
      realtedEntityId: props.relatedEntityId,
      langId: dirimart.langId,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  useEffect(() => {
    _getNews().then((response) => {
      setNews(response);
      setBlockUi(false);
    });
    _getNewsCount().then((response) => {
      var maxPage = Math.ceil(response / newsListCount);
      if (pageNumber < maxPage) setShowMore(true);
      setMaxPageNumber(maxPage);

      setTotalCount(response);
    });
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (isInitialLoad) return;
    _getNews().then((response) => {
      setNews(news.concat(response));
      setBlockUi(false);
    });
    if (pageNumber === maxPageNumber) {
      setShowMore(false);
    }
  }, [pageNumber]);

  return (
   
      <div className="container news-list-container">
        <div
          className={
            (totalCount % 2 == 0 ? "news-even" : "news-odd") + " news news-list"
          }
        >
          <Masonry
            breakpointCols={3}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {news &&
              news.map((element) => {
                return (
                  <Link to={"/News/"+element.title.replace(/ /g,"-").replace("#","")+"/" + element.newsId}>
                  <div
                    className="news-card"
                    
                  >
                    <img src={element.imageURL} alt="news" />
                    <h4>{element.title}</h4>
                    <p>{element.summary}</p>
                  </div>
                  </Link>
                );
              })}
          </Masonry>
        </div>
        <div
          className="grid place-content-center"
          style={{ marginTop: "2rem" }}
        >
          <button
            className="btnWhite"
            onClick={() => _showMore()}
            disabled={!showMore}
          >
            {translation.Common.seemoreNews}
          </button>
        </div>
      </div>

  );
};
