import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ArtistListSkeleton = () => {
  return (
    <div className="artistlistskeleton">
      <div className="artistCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={443}
          height={322}
          style={{ marginBottom: "1rem" }}
        ></Skeleton>
        <Skeleton width={443} height={522}></Skeleton>
      </div>
      <div className="artistCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={443}
          height={522}
          style={{ marginBottom: "1rem" }}
        ></Skeleton>
        <Skeleton width={443} height={322}></Skeleton>
      </div>
      <div className="artistCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={443}
          height={322}
          style={{ marginBottom: "1rem" }}
        ></Skeleton>
        <Skeleton width={443} height={522}></Skeleton>
      </div>
    </div>
  );
};
