import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const NewsSkeleton = () => {
    return (
        <div className="newsResults newsPageContainer">
            <div className="news">
                <div className="news-card">
                    <Skeleton width={668} height={376} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                   

                </div>
                <div className="news-card">
                    <Skeleton width={668} height={376} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                    <Skeleton width={668} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
                   

                </div>
            </div>

        </div>
    )
}

