import React, { useContext, useEffect, useState, useCallback } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import { useParams, useLocation } from "react-router-dom";
import { NewsComponent } from "../../components/NewsComponent";
import { SelectedWorksComponent } from "../../components/SelectedWorksComponent";
import { ExhibitionsDetailSkeleton } from "../../components/Skeletons/Exhibitions/ExhibitionsDetailSkeleton";

import {
  baseApiURL,
  exhibitionsNewsCount,
  selectedWorksCount,
  mediasCount,
  lottieOption,
} from "../../Helpers/Constant";
import { openInNewTab, _htmlToPlaintext } from "../../Helpers/Helper";
import { ImageSliderComponent } from "../../components/ImageSliderComponent";
import Lottie from "react-lottie";
import axios from "axios";
import parse from "html-react-parser";
import HashLoader from "react-spinners/HashLoader";
import BlockUI from "react-block-ui";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";

import "react-block-ui/style.css";
export const ExhibitionDetail = () => {
  let activeMenu = {};
  let router = useLocation();
  let scrollToViewOption = {
    behavior: "smooth",
    block: "start",
  };
  const params = useParams();
  const { dirimart, translation } = useContext(AppContext);
  const [scrollActive, setScrollActive] = useState(activeMenu);
  const [exhibition, setExhibition] = useState({});
  const [blockUi, setBlockUi] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [blockExhibitionImagesUi, setBlockExhibitionImagesUi] = useState(true);
  const [detail, setDetail] = useState("");
  const [fullDetail, setFullDetail] = useState("");
  const [readMore, setReadMore] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [readMoreText, setReadMoreText] = useState(translation.Common.readmore);
  const [readMoreIcon, setReadMoreIcon] = useState("+");
  const [exhibitionImages, setExhibitionImages] = useState([]);
  const [exhibitionImagesMaxPage, setExhibitionImagesMaxPage] = useState(0);
  const [exhibitionImagesTotalCount, setExhibitionImagesTotalCount] =
    useState(0);
  const _getExhibition = async () => {
    const getExhibitionDetailUrl =
      baseApiURL + "/Exhibition/getPublishedExhibitionBySeoId";
    const data = { langId: dirimart.langId, seoId: params.Id };
    var config = {
      method: "post",
      url: getExhibitionDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getImages = async (pageNumber) => {
    const getImagesUrl =
      baseApiURL + "/exhibitionImage/getNPublishedExhibitionImages";
    const data = {
      Id: exhibition.Id,
      recordCount: 1,
      pageNumber: pageNumber ? pageNumber : 1,
    };
    var config = {
      method: "post",
      url: getImagesUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getImagesTotalCount = async () => {
    const getTotalCountUrl =
      baseApiURL + "/exhibitionImage/getNPublishedExhibitionImagesTotalCount";
    const data = { Id: exhibition.Id };
    var config = {
      method: "post",
      url: getTotalCountUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const handleScrollToggle = (element) => {
    switch (element) {
      case "overview":
        activeMenu.overview = true;
        break;
      case "selectedWorks":
        activeMenu.selectedWorks = true;
        break;
      case "news":
        activeMenu.news = true;
        break;
      case "images":
        activeMenu.images = true;
        break;
      default:
        activeMenu.overview = true;
        break;
    }
    setScrollActive(activeMenu);
  };
  const toggleReadMore = async () => {
    setReadMore(!readMore);
  };

  const getImagesNextPage = (pageNumber) => {
    setBlockExhibitionImagesUi(true);
    _getImages(pageNumber).then((response) => {
      let images = [];
      response.exhibitionImages.forEach((element) => {
        let img = { imageUrl: element.exhibitionMediaUrl };
        images.push(img);
      });
      setExhibitionImages(exhibitionImages.concat(images));
      setBlockExhibitionImagesUi(false);
    });
  };
  const handleScroll = useCallback(() => {
    setScrollActive(false);
  }, []);
  useEffect(() => {
    if (initialLoad) {
      return;
    }
    if (readMore) {
      setDetail(fullDetail);
      setReadMoreText(translation.Common.readless);
      setReadMoreIcon("-");
      return;
    }
    setDetail(
      fullDetail.substring(
        fullDetail.indexOf("<div>") != 0 ? 0 : fullDetail.indexOf("<div>"),
        fullDetail.indexOf("</div>") < 105
          ? fullDetail.indexOf(".") < 150
            ? 150
            : fullDetail.indexOf(".")
          : fullDetail.indexOf("</div>")
      ) + "..."
    );
    setReadMoreText(translation.Common.readmore);
    setReadMoreIcon("+");
  }, [readMore]);
  useEffect(() => {
    _getExhibition().then((response) => {
      setFullDetail(response.exhibition.detail);
      setDetail(
        response.exhibition.detail.substring(
          response.exhibition.detail.indexOf("<div>") != 0
            ? 0
            : response.exhibition.detail.indexOf("<div>"),
          response.exhibition.detail.indexOf("</div>") < 105
            ? response.exhibition.detail.indexOf(".") < 150
              ? 150
              : response.exhibition.detail.indexOf(".")
            : response.exhibition.detail.indexOf("</div>")
        ) + "..."
      );
      setExhibition(response.exhibition);
      setIsLoading(false);
      setInitialLoad(false);
    });
  }, [router]);
  useEffect(() => {
    setExhibitionImagesMaxPage(
      Math.ceil(exhibitionImagesTotalCount / mediasCount)
    );
  }, [exhibitionImagesTotalCount]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setScrollActive(false);
  }, [handleScroll]);
  useEffect(() => {
    if (initialLoad) {
      return;
    }
    _getImages().then((response) => {
      let images = [];
      response.exhibitionImages.forEach((element) => {
        let img = { imageUrl: element.exhibitionMediaUrl };
        images.push(img);
      });
      setExhibitionImages(images);
      setBlockExhibitionImagesUi(false);
    });
    _getImagesTotalCount().then((response) => {
      setExhibitionImagesTotalCount(response);
    });
  }, [exhibition.Id]);
  useEffect(() => {
    if (initialLoad) {
      return;
    }
    setBlockUi(true);
    _getExhibition().then((response) => {
      setFullDetail(response.exhibition.detail);
      setDetail(
        response.exhibition.detail.substring(
          response.exhibition.detail.indexOf("<div>") != 0
            ? 0
            : response.exhibition.detail.indexOf("<div>"),
          response.exhibition.detail.indexOf("</div>") < 105
            ? response.exhibition.detail.indexOf(".") < 150
              ? 150
              : response.exhibition.detail.indexOf(".")
            : response.exhibition.detail.indexOf("</div>")
        ) + "..."
      );
      setExhibition(response.exhibition);
      setIsLoading(false);
      setReadMoreText(translation.Common.readmore);
    });
  }, [dirimart.langId]);

  return (
    <div>
      <div className="subMenuDiv">
        <ul className="subMenu">
          <li key={"overview"}>
            <a onClick={() => handleScrollToggle("overview")}>
              {translation.Exhibition.overview}
            </a>
          </li>
          <li key={"selectedWorks"}>
            <a onClick={() => handleScrollToggle("selectedWorks")}>
              {translation.SelectedWork.Title}
            </a>
          </li>

          <li key={"images"}>
            <a onClick={() => handleScrollToggle("images")}>
              {translation.Exhibition.images}
            </a>
          </li>
          <li key={"news"}>
            <a onClick={() => handleScrollToggle("news")}>
              {translation.News.Title}
            </a>
          </li>
        </ul>
      </div>
      {isLoading && <ExhibitionsDetailSkeleton></ExhibitionsDetailSkeleton>}
      {exhibition.mainTitle && (
        <ScrollIntoViewIfNeeded
          active={scrollActive.overview ? true : false}
          options={scrollToViewOption}
        >
          <div id="overview" className="exhibitionOverview">
            <div className="leftSide">
              <h1>{exhibition.mainTitle}</h1>
              <h2>{exhibition.title}</h2>
              <p>{exhibition.location}</p>
              <p className="date">{exhibition.datesTitle}</p>
              {exhibition.exhibitionTextUrl && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openInNewTab(exhibition.exhibitionTextUrl);
                  }}
                >
                  {translation.Exhibition.exhibitionText}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 12L12 4"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M5.5 4H12V10.5"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </a>
              )}
              {exhibition.pressReleaseUrl && (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    openInNewTab(exhibition.pressReleaseUrl);
                  }}
                >
                  {translation.Exhibition.pressrelease}{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 12L12 4"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M5.5 4H12V10.5"
                      stroke="#888888"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </a>
              )}
            </div>

            <div className="rightSide">
              <img src={exhibition.imageURL} alt="exhibition" />
              <div>{parse(detail)}</div>

              <a style={{ cursor: "pointer" }} onClick={() => toggleReadMore()}>
                {readMoreText} {readMoreIcon}
              </a>
            </div>
          </div>
        </ScrollIntoViewIfNeeded>
      )}

      <ScrollIntoViewIfNeeded
        active={scrollActive.selectedWorks ? true : false}
        options={scrollToViewOption}
      >
        {exhibition.Id && (
          <SelectedWorksComponent
          key={exhibition.Id} 
            recordCount={selectedWorksCount}
            getSelectedWorkUrl={
              "/exhibitionSelectedWork/getNPublishedExhibitionSelectedWorks"
            }
            getSelectedWorkTotalCountUrl={
              "/exhibitionSelectedWork/getNPublishedExhibitionSelectedWorksTotalCount"
            }
            Id={exhibition.Id}
          />
        )}
      </ScrollIntoViewIfNeeded>
      <ScrollIntoViewIfNeeded
        active={scrollActive.images ? true : false}
        options={scrollToViewOption}
      >
        {exhibitionImages?.length > 0 && exhibition.Id && (
          <ImageSliderComponent
            recordCount={mediasCount}
            images={exhibitionImages}
            totalCount={exhibitionImagesTotalCount}
            imagePerPage={mediasCount}
            nextPage={getImagesNextPage}
            Id={exhibition.Id}
            maxPageNumber={exhibitionImagesMaxPage}
            title={translation.Exhibition.images}
            style={{ backgroundColor: "white" }}
          />
        )}
      </ScrollIntoViewIfNeeded>
      <ScrollIntoViewIfNeeded
        active={scrollActive.news ? true : false}
        options={scrollToViewOption}
      >
        {exhibition.Id && (
          <NewsComponent
            recordCount={exhibitionsNewsCount}
            getNewsUrl={"/exhibitionNews/getNPublishedExhibitionNews"}
            getNewsTotalCountUrl={
              "/exhibitionNews/getNPublishedExhibitionNewsTotalCount"
            }
            showDefaultTitle={true}
            Id={exhibition.Id}
            allNewsTitle={translation.Exhibition.all}
          />
        )}
      </ScrollIntoViewIfNeeded>
    </div>
  );
};
