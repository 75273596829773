import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AppContext } from "..//../ContextAPI/AppProvider";
import { useParams, useNavigate } from "react-router-dom";
import { baseApiURL, mediasCount, lottieOption } from "../../Helpers/Constant";
import { Team } from "./Team";
import { Locations } from "./Locations";
import { ImageSliderComponent } from "../../components/ImageSliderComponent";
import Lottie from "react-lottie";

import BlockUI from "react-block-ui";
import parse from "html-react-parser";

import "react-block-ui/style.css";
import { AboutSkeleton } from "../../components/Skeletons/AboutSkeleton";
import { ImagesSkeleton } from "../../components/Skeletons/ImagesSkeleton";
import { Map } from "../../components/Map";

export const Index = () => {
  const params = useParams();
  const { dirimart, translation } = useContext(AppContext);
  const [staticWebInformation, setStaticWebInformation] = useState({});
  const [blockAbout, setBlockAbout] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [blockTeam, setBlockTeam] = useState(true);
  const [blockImagesUi, setBlockImagesUi] = useState(true);
  const [blockLocationsUi, setBlockLocationsUi] = useState(true);
  const [aboutImages, setAboutImages] = useState([]);
  const [aboutImagesMaxPage, setAboutImagesMaxPage] = useState(0);
  const [aboutImagesTotalCount, setAboutImagesTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isImagesLoading, setIsImagesLoading] = useState(true);
  const [gMapMarker, setGmapMarker] = useState(undefined);
  const [adressDetail, setAdressDetail] = useState("");

  const _getStaticWebInformation = async () => {
    const getDataUrl =
      baseApiURL + "/StaticWebInformation/getPublishedStaticWebInformation";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getImages = async (pageNumber) => {
    const getImagesUrl =
      baseApiURL +
      "/StaticWebInformationImage/getNPublishedStaticWebInformationImages";
    const data = {
      langId: dirimart.langId,
      Id: staticWebInformation.Id,
      recordCount: 1,
      pageNumber: pageNumber ? pageNumber : 1,
    };
    var config = {
      method: "post",
      url: getImagesUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getImagesTotalCount = async () => {
    const getTotalCountUrl =
      baseApiURL +
      "/StaticWebInformationImage/getNPublishedStaticWebInformationImagesTotalCount";
    const data = { Id: staticWebInformation.Id };
    var config = {
      method: "post",
      url: getTotalCountUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const getImagesNextPage = (pageNumber) => {
    setBlockImagesUi(true);

    _getImages(pageNumber).then((response) => {
      let images = [];
      response.images.forEach((element) => {
        let img = { imageUrl: element.imageUrl };
        images.push(img);
      });
      setAboutImages(aboutImages.concat(response.images));

      setIsImagesLoading(false);
    });
  };
  useEffect(() => {
    if (isInitialLoad) return;

    setAboutImagesMaxPage(Math.ceil(aboutImagesTotalCount / mediasCount));
  }, [aboutImagesTotalCount]);
  useEffect(() => {
    if (isInitialLoad) return;

    _getImages().then((response) => {
      let images = [];
      response.images.forEach((element) => {
        let img = { imageUrl: element.imageUrl };
        images.push(img);
      });
      setAboutImages(images);

      setIsImagesLoading(false);
    });
    _getImagesTotalCount().then((response) => {
      setAboutImagesTotalCount(response);
    });
  }, [staticWebInformation]);

  useEffect(() => {
    if (isInitialLoad) return;
    setBlockAbout(true);
    _getStaticWebInformation().then((response) => {
      setStaticWebInformation(response.staticWebInformation);
      setIsLoading(false);
    });
  }, [dirimart.langId]);
  useEffect(() => {
    _getStaticWebInformation().then((response) => {
      setStaticWebInformation(response.staticWebInformation);
      setGmapMarker({
        latitude: response.staticWebInformation.latitude,
        longitude: response.staticWebInformation.longitude,
      });
      setAdressDetail(response.staticWebInformation.adress);
      setIsLoading(false);
    });
    setIsInitialLoad(false);
  }, []);
  return (
    <div className=" aboutPage">
      {isLoading && <AboutSkeleton></AboutSkeleton>}
      {staticWebInformation.about && (
        <div className="about" style={{ minHeight: "200px" }}>
          <h2>{translation.About.Title}</h2>
          {staticWebInformation.about && parse(staticWebInformation.about)}
        </div>
      )}

      <Team />

      <div className="galery" id="galery">
        {isImagesLoading && (
          <div style={{ marginTop: "1rem" }}>
            <h1>{translation.About.images}</h1>
            <ImagesSkeleton></ImagesSkeleton>
          </div>
        )}
        {!isImagesLoading && (
          <ImageSliderComponent
            titleStyle={{ textAlign: "start" }}
            recordCount={mediasCount}
            images={aboutImages}
            totalCount={aboutImagesTotalCount}
            imagePerPage={mediasCount}
            nextPage={getImagesNextPage}
            Id={params.Id}
            maxPageNumber={aboutImagesMaxPage}
            title={translation.About.images}
          />
        )}
      </div>

      <div style={{ minHeight: "300px" }}>
        {staticWebInformation.Id && (
          <Locations
            setBlockLocationsUi={setBlockLocationsUi}
            Id={staticWebInformation.Id}
            setGmapMarker={setGmapMarker}
            setAdressDetail={setAdressDetail}
          />
        )}
      </div>

      <div className="contactDiv || <AboutSkeleton />">
        {gMapMarker && (
          <Map
            key={gMapMarker.latitude + gMapMarker.longitude}
            latitude={gMapMarker.latitude}
            longitude={gMapMarker.longitude}
          ></Map>
        )}

        <div className="contactUs">
          <h3>{translation.About.contactus}</h3>
          <p>
            Dirimart Dolapdere <br /> Hacıahmet Mahallesi Irmak Caddesi No: 1-9
            34440 Beyoğlu/İstanbul
          </p>
          <p>
            Dirimart Pera <br /> Meşrutiyet Caddesi No: 99 34430
            <br />
            Beyoğlu/İstanbul
          </p>
          <p>
            <a href={"tel:" + staticWebInformation.phone}>
              T: {staticWebInformation.phone}
            </a>
          </p>
          <p>
            <a href={"mailto:" + staticWebInformation.email}>
              {staticWebInformation.email}
            </a>
          </p>
          <p>
            <a href={"mailto:" + staticWebInformation.pressEmail}>
              {staticWebInformation.pressEmail}
            </a>
          </p>
          <p>
            <a href={"mailto:" + staticWebInformation.saleEmail}>
              {staticWebInformation.saleEmail}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
