import React,{useState,useEffect} from 'react'
import GoogleMaps from "simple-react-google-maps";

export const Map = (props) => {
   
 
    return (
        <>
      
            <GoogleMaps
            apiKey={"AIzaSyC5vedh9lkTG3PBZu2iTh6qKLgS94Ok-rw"}
            style={{ height: "687px", width: "687px" }}
            zoom={14}
            center={{
                lat: parseFloat(props.latitude),
                lng: parseFloat(props.longitude),
            }}
            markers={{
                lat: parseFloat(props.latitude),
                lng: parseFloat(props.longitude),
            }} //optional
        />
      
       
        </>
    )
}


