import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "./ContextAPI/AppProvider";
import { baseApiURL } from "./Helpers/Constant";
import axios from "axios";
import { validateEmail } from "./Helpers/Helper";
export const Footer = () => {
  let navigate = useNavigate();
  const { translation, dirimart } = useContext(AppContext);
  const [email, setEmail] = useState("");
  //const [firstName, setFirstName] = useState("")
  //const [lastName, setLastName] = useState("")
  const [isNewsletterBtnDisabled, setIsNewsletterBtnDisabled] = useState(false);
  const documents = [
    {
      title: translation.Footer.clarificationText,
      url: "https://dirimart.com/content/policies/aydinlatma_metni.pdf",
    },
    {
      title: translation.Footer.cookiePolicy,
      url: "https://dirimart.com/content/policies/cerez_politikasi.pdf",
    },
    {
      title: translation.Footer.applicationForm,
      url: "https://dirimart.com/content/policies/basvuru_formu.pdf",
    },
    {
      title: translation.Footer.privacypolicy,
      url: "https://dirimart.com/content/policies/kvkk_politikasi.pdf",
    },
  ];
  const _submitSubscribeNewsletter = () => {
    const subscribeurl = baseApiURL + "/contact/createContact";
    //const data = { firstName:firstName ,lastName: lastName, email: email, langId: dirimart.langId, sourceType: "web"};
    const data = { email: email, langId: dirimart.langId, sourceType: "web" };

    var config = {
      method: "post",
      url: subscribeurl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _submit = () => {
    setIsNewsletterBtnDisabled(true);
    if (!validateEmail(email)) {
      alert(translation.Newsletter.nullemail);
      setIsNewsletterBtnDisabled(false);

      return;
    }

    _submitSubscribeNewsletter().then((response) => {
      if (response.responseResult.exceptionDetail === "Exist") {
        alert(translation.Newsletter.exist);
        setIsNewsletterBtnDisabled(false);
        return;
      }
      if (!response.responseResult.result) {
        alert(translation.Newsletter.fail);
        setIsNewsletterBtnDisabled(false);
        return;
      }
      alert(translation.Newsletter.success);

      setEmail("");
      setIsNewsletterBtnDisabled(false);
    });
  };
  return (
    <div className="footer">
      <div className="footerCol div1">
        <svg
          width="108"
          height="88"
          viewBox="0 0 108 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1642 61.3881V73.1695H95.6512V61.3881H107.976V87.6974H0.5V61.3881H13.1642V61.3881ZM95.6512 49.5827C95.676 43.0424 94.1991 36.5825 91.3328 30.6941C85.2514 19.9005 72.9673 14.5038 54.4805 14.5038C32.7589 14.5038 19.6176 21.9163 15.0565 36.7413C13.9802 40.9393 13.4181 45.251 13.3825 49.5827H0.645559C0.609407 43.9209 1.15408 38.2701 2.27107 32.7179C4.13968 25.2643 8.17222 18.5202 13.8678 13.3232C23.4104 4.44108 36.9156 0 54.3835 0C73.2423 0 87.346 4.99522 96.6945 14.9857C101.713 20.4062 105.181 27.0607 106.738 34.2598C107.715 39.3326 108.17 44.4908 108.097 49.655H95.6755L95.6512 49.5827Z"
            fill="#fff200"
          />
        </svg>
        <svg
          className="logo"
          width="109"
          height="17"
          viewBox="0 0 109 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.332 16.2254H19.4183V7.92777H17.332V16.2254ZM17.332 6.21294H19.4183V0.720703H17.332V6.21294Z"
            fill="#25282E"
          />
          <path
            d="M40.084 16.2254H42.1702V7.92777H40.084V16.2254ZM40.084 6.21294H42.1702V0.720703H40.084V6.21294Z"
            fill="#25282E"
          />
          <path
            d="M75.2092 13.1911L76.2918 16.2178H78.465L77.3586 13.1911H75.2092ZM70.6731 0.720972L67.8836 8.00706H69.9856L71.7242 3.26557L73.9843 9.72192H67.196L64.7305 16.2019H66.9274L68.658 11.4604H76.7027L72.7515 0.697266H70.602L70.6731 0.720972Z"
            fill="#25282E"
          />
          <path
            d="M96.6661 2.52274H99.5979V0.697266H96.6582V2.52274H96.6661ZM101.321 0.697266V16.2256H103.407V2.52274H108.038V0.697266H101.321Z"
            fill="#25282E"
          />
          <path
            d="M83.9976 2.43669V6.21408H81.9746V0.721841H87.7434C88.6385 0.70078 89.5339 0.745693 90.4224 0.856194C91.4644 0.996209 92.4141 1.52731 93.0788 2.34179C93.7436 3.15628 94.0738 4.19315 94.0022 5.24206C94.0022 7.66022 92.7852 9.10639 90.3354 9.50152C89.5614 9.58949 88.7832 9.63433 88.0042 9.63584H84.0055V16.2186H81.9746V7.92102H88.099C88.7322 7.94242 89.3658 7.89205 89.9877 7.77087C90.561 7.66543 91.0753 7.35249 91.4325 6.89185C91.7896 6.4312 91.9645 5.85514 91.9238 5.27368C91.946 4.86488 91.8742 4.45642 91.7139 4.0797C91.5536 3.70297 91.3092 3.368 90.9992 3.1005C90.6079 2.78393 90.1383 2.57913 89.64 2.50781C89.0911 2.45584 88.5397 2.43472 87.9884 2.44458H83.9897L83.9976 2.43669ZM91.5129 11.3507L94.6739 16.2265H92.2162L89.0947 11.3507H91.5208H91.5129Z"
            fill="#25282E"
          />
          <path
            d="M4.31689 2.54813H2.60206V14.4493H4.31689V16.2273H0.5V0.722656H4.31689V2.54813ZM6.03174 14.4019C6.98047 14.4048 7.91768 14.1941 8.7739 13.7855C10.3544 12.9057 11.1446 11.1355 11.1446 8.47499C11.1446 5.34034 10.0699 3.44376 7.92042 2.78522C7.31073 2.6296 6.68464 2.54734 6.05545 2.54022V0.738453C6.87272 0.734578 7.68824 0.814028 8.4894 0.975542C9.57564 1.24165 10.5596 1.82154 11.3185 2.64295C12.6092 4.01799 13.2546 5.96464 13.2546 8.4829C13.2546 11.2014 12.5302 13.2349 11.0814 14.5836C10.2964 15.3047 9.33356 15.8038 8.29184 16.0298C7.55478 16.1697 6.80562 16.2359 6.05545 16.2273V14.4335L6.03174 14.4019Z"
            fill="#25282E"
          />
          <path
            d="M49.519 6.21489L48.468 3.42532L48.6892 16.2273H46.6504V0.722656H49.3056L51.3603 6.21489H49.4953H49.519ZM58.0063 7.92972L54.8453 16.2273H53.2648L50.1512 7.92972H52.0162L54.0787 13.4615L56.1571 7.92972H57.9983H58.0063ZM58.8755 0.730566H61.5308V16.2273H59.484L59.7685 3.22775L58.6464 6.21489H56.8051L58.8755 0.730566Z"
            fill="#25282E"
          />
          <path
            d="M36.1485 5.24939C36.2156 5.99241 36.0645 6.73887 35.7135 7.39723C35.3626 8.05558 34.8271 8.5972 34.1729 8.95566L33.1298 7.37516C33.4515 7.13753 33.7079 6.82234 33.8751 6.45895C34.0423 6.09556 34.1148 5.69577 34.0859 5.2968C34.1081 4.888 34.0363 4.47954 33.876 4.10282C33.7158 3.72609 33.4713 3.39112 33.1613 3.12362C32.7709 2.80568 32.3008 2.60071 31.8021 2.53093C31.2559 2.47769 30.7071 2.45656 30.1584 2.4677H26.1598V7.95994H30.2611C30.7116 7.95994 31.0988 7.95994 31.4307 7.91252L36.8281 16.2575H34.3704L30.1584 9.67476H24.1446V0.729166H29.8976C30.7927 0.709341 31.688 0.754226 32.5766 0.863494C33.2829 0.960045 33.9539 1.2317 34.5285 1.65374C35.0706 2.07299 35.5022 2.61818 35.7859 3.24201C36.0696 3.86583 36.1967 4.54946 36.1564 5.23357L36.1485 5.24939ZM26.1598 16.2259H24.1367V11.3501H26.1598V16.2259Z"
            fill="#25282E"
          />
        </svg>
        <div className="socialMediaIcons">
          <a href="https://twitter.com/DIRIMART" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"
                fill="black"
              />
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/di%CC%87ri%CC%87mart/mycompany/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#25282E"
              viewBox="0 0 256 256"
            >
              <path d="M216,24H40A16,16,0,0,0,24,40V216a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V40A16,16,0,0,0,216,24ZM96,176a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0ZM88,96a12,12,0,1,1,12-12A12,12,0,0,1,88,96Zm96,80a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.79-1.78A36,36,0,0,1,184,140Z"></path>
            </svg>
          </a>
          <a href="https://www.youtube.com/c/dirimartlive" target="_blank">
            <svg
              width="24"
              height="24"
              fill="#25282E"
              viewBox="0 0 256 256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M234.33,69.52a24,24,0,0,0-14.49-16.4C185.56,39.88,131,40,128,40s-57.56-.12-91.84,13.12a24,24,0,0,0-14.49,16.4C19.08,79.5,16,97.74,16,128s3.08,48.5,5.67,58.48a24,24,0,0,0,14.49,16.41C69,215.56,120.4,216,127.34,216h1.32c6.94,0,58.37-.44,91.18-13.11a24,24,0,0,0,14.49-16.41c2.59-10,5.67-28.22,5.67-58.48S236.92,79.5,234.33,69.52Zm-72.11,61.81-48,32A4,4,0,0,1,108,160V96a4,4,0,0,1,6.22-3.33l48,32a4,4,0,0,1,0,6.66Z"></path>
            </svg>
          </a>
          <a href="https://www.facebook.com/DIRIMART" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.9531 11.6836C23.9531 5.26172 18.75 0.0585938 12.3281 0.0585938C5.90625 0.0585938 0.703125 5.26172 0.703125 11.6836C0.703125 17.4961 4.92188 22.3242 10.5 23.168V15.0586H7.54688V11.6836H10.5V9.15234C10.5 6.24609 12.2344 4.60547 14.8594 4.60547C16.1719 4.60547 17.4844 4.83984 17.4844 4.83984V7.69922H16.0312C14.5781 7.69922 14.1094 8.58984 14.1094 9.52734V11.6836H17.3438L16.8281 15.0586H14.1094V23.168C19.6875 22.3242 23.9531 17.4961 23.9531 11.6836Z"
                fill="#25282E"
              />
            </svg>
          </a>
          <a href="https://www.instagram.com/dirimart" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24ZM128,176a48,48,0,1,1,48-48A48.05,48.05,0,0,1,128,176Zm60-96a12,12,0,1,1,12-12A12,12,0,0,1,188,80Zm-28,48a32,32,0,1,1-32-32A32,32,0,0,1,160,128Z"></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="footerCol div2">
        <h3>{translation.Footer.quicklinks}</h3>
        <ul>
          <li>
            {" "}
            <Link to={(dirimart.langId === 1033 ? "/en/" : "/") + "Artists"}>
              {translation.Menu.artists}
            </Link>
          </li>
          <li>
            <Link
              to={(dirimart.langId === 1033 ? "/en/" : "/") + "Exhibitions"}
            >
              {" "}
              {translation.Menu.exhibitions}
            </Link>
          </li>
          <li>
            <Link to={(dirimart.langId === 1033 ? "/en/" : "/") + "Fairs"}>
              {" "}
              {translation.Menu.fairs}
            </Link>
          </li>
          <li>
            <Link
              to={(dirimart.langId === 1033 ? "/en/" : "/") + "Publications"}
            >
              {" "}
              {translation.Menu.publications}
            </Link>
          </li>
          <li>
            <Link to={(dirimart.langId === 1033 ? "/en/" : "/") + "News"}>
              {translation.Menu.news}
            </Link>
          </li>
          <li>
            <Link to={(dirimart.langId === 1033 ? "/en/" : "/") + "About"}>
              {translation.Menu.aboutus}
            </Link>
          </li>
          <li>
            <Link
              to={
                (dirimart.langId === 1033 ? "/en/" : "/") + "Job-Opportunities"
              }
            >
              {translation.Menu.jobOpportunities}
            </Link>
          </li>
          {
            // Shop kısmı açılınca açılacak
            // <li>
            //   <Link to={(dirimart.langId === 1033 ? "/en/" : "/") + "Shop"}>
            //     {translation.Menu.shop}
            //   </Link>
            // </li>
          }
        </ul>
      </div>
      <div className="footerCol div4">
        <h3>{translation.About.contactus}</h3>
        <ul>
          <li>
            <p>
              Dirimart Dolapdere <br /> Hacıahmet Mahallesi Irmak Caddesi No:
              1-9 34440 Beyoğlu/İstanbul
            </p>
            <br />
            <p>
              Dirimart Pera <br /> Meşrutiyet Caddesi No: 99 34430
              <br /> Beyoğlu/İstanbul
            </p>
          </li>
          <li>
            <p>T: +90 212 232 66 66</p>
          </li>
          <li>
            <p>{translation.Footer.days1} 10:00 - 19:00</p>
          </li>
          <li>
            <p>{translation.Footer.days2} 12:00 - 19:00</p>
          </li>
        </ul>
      </div>
      <div className="footerCol div4">
        <h3>{translation.Footer.newsletter}</h3>
        <ul>
          <li>
            <p>{translation.Footer.newsletterslogan}</p>
          </li>
          <li>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={translation.Footer.newsletterplaceholder}
            ></input>
          </li>
          <li>
            <button
              disabled={isNewsletterBtnDisabled}
              className="btnYellow"
              onClick={() => _submit()}
            >
              {translation.Footer.newsletterbuttontext}
            </button>
          </li>
        </ul>
      </div>
      <div className="policies" style={{ marginTop: "10px" }}>
        <p style={{ whiteSpace: "nowrap" }}>
          Dirimart © {new Date().getFullYear()}. {translation.Footer.allrights}
        </p>
        <ul>
          {documents.map((document, index) => (
            <li key={index}>
              <a href={document.url} target="_blank" rel="noopener noreferrer">
                {document.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
