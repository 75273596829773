import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'

export const PublicationDetailParams = (props) => {
    const { dirimart, translation, setDirimartData } = useContext(AppContext);
    const [publicationDetailParams, setPublicationDetailParams] = useState(props.params)
    return (
        <div className="publicationDetail">
            <div>
                <h2>{translation.Publication?.details}</h2>
                {/* <button className="btnYellow">{translation.Publication.viewinshop}</button> */}
            </div>
            <div style={{display:"block"}}>
                <ul>
                    {
                        props.publication &&
                        <>
                            <li >
                                <p className="title">{translation.Publication.category}</p>
                                <p className="description">{props.publication?.category?.name}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.type}</p>
                                <p className="description">{props.publication?.type?.name}</p>
                            </li>
                            <li >
                                <p className="title">{translation.Publication.language}</p>
                                <p className="description">{props.publication?.language}</p>
                            </li>
                            <li >
                                <p className="title">{translation.Publication.booklayout}</p>
                                <p className="description">{props.publication?.bookLayout}</p>
                            </li>
                            <li >
                                <p className="title">{translation.Publication.design}</p>
                                <p className="description">{props.publication?.design}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.photographs}</p>
                                <p className="description">{props.publication?.photographs}</p>
                            </li>
                            <li >
                                <p className="title">{translation.Publication.editor}</p>
                                <p className="description">{props.publication?.editor}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.proofreading}</p>
                                <p className="description">{props.publication?.proofreading}</p>
                            </li>
                            <li >
                                <p className="title">{translation.Publication.texts}</p>
                                <p className="description">{props.publication?.texts}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.translations}</p>
                                <p className="description">{props.publication?.translations}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.page}</p>
                                <p className="description">{props.publication?.page}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.size}</p>
                                <p className="description">{props.publication?.size}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.isbn}</p>
                                <p className="description">{props.publication?.isbn}</p>
                            </li>
                            <li>
                                <p className="title">{translation.Publication.price}</p>
                                {/* <p className="description">{props.publication?.price}</p> */}
                                <p className="description">-</p>
                            </li>

                        </>
                    }
                    {
                        props.params[0] &&
                        props.params.map(detailParam => {
                            return (
                                <li key={`${detailParam.name}${detailParam.value}`}>
                                    <p className="title">{detailParam.name}</p>
                                    <p className="description">{detailParam.value}</p>
                                </li>
                            )
                        })
                    }


                </ul>
            </div>
        </div>
    )
}


