import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../ContextAPI/AppProvider'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper';

export const ExhibitionCard=(props)=> {
 
    let navigate=useNavigate();
const { translation,dirimart } = useContext(AppContext);
    return (
        <Link to={(dirimart.langId===1033?"/en":"/tr")+"/Exhibitions/"+removeSpecialCharactersAndWhiteSpace(props.exhibitionName)+"/"+props.seoId}>
        <div className="exhibitionCard" >
            <div className="image">
                <img src={props.image} alt="exhibition"/>
            </div>
            <p className='artistName'>{props.exhibitionName}</p>
            <p className="company">{props.location}</p>
            <span className="date">{`${props.startDate}-${props.endDate}`}</span>
        
            <span className="readMore">{translation.Common.readmore}</span>
        </div>
        </Link>
    )
}


