import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export const TeamSkeleton = () => {
  return (
   <>
   
            <div className="team">
                <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>
                <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>  <div className="personCard">
                    <div style={{height:"100%",width:"100%"}}>
                    <Skeleton width={306} height={306}></Skeleton>

                    </div>
                   
                    <h5 style={{height:"100%",width:"100%"}}><Skeleton width={"100%"} height={30}></Skeleton></h5>
                    <p style={{height:"100%",width:"100%"}}> <Skeleton width={"100%"} height={10}></Skeleton></p>
                </div>
              
            </div>
        
   </>
  )
}


