import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AppWrapper } from "./AppWrapper";
import { AppProvider } from "./ContextAPI/AppProvider";
import "./assets/custom.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CookieConsentCustom } from "./components/CookieConsentCustom";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AppProvider>
    <BrowserRouter>
      <App />
      <CookieConsentCustom />
    </BrowserRouter>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
