import React from "react";

export const TeamCard = (props) => {
  return (
    <div className="personCard">
      <img src={props.imageUrl} alt={props.fullName} />
      <h5>{props.fullName}</h5>
      <p>{props.title}</p>
    </div>
  );
};
