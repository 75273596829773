import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";


export const ImagesSkeleton = () => {
  return (
    <div style={{width:"100%",height:"100%",textAlign:"center",marginTop:"1rem"}}>
      <Skeleton width={"80%"} height={595}></Skeleton>
    </div>
  )
}

