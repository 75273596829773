import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ExhibitionsDetailSkeleton = () => {
  return (
    <>
      <div className="exhibitionOverview">
        <div className="leftSide">
          <h1>
            <Skeleton width={362} height={60}></Skeleton>
          </h1>
          <h2>
            <Skeleton
              width={380}
              height={47}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </h2>
          <p>
            <Skeleton
              width={120}
              height={20}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </p>
          <p className="date">
            <Skeleton
              width={362}
              height={20}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </p>
          <a>
            <Skeleton width={362} height={28}></Skeleton>
          </a>
        </div>
        <div className="rightSide">
          <div>
            <Skeleton
              width={747}
              height={503}
              style={{ marginBottom: "1rem" }}
            ></Skeleton>
          </div>
          <div>
            <Skeleton
              width={747}
              height={80}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </div>
          <a>
            <Skeleton
              width={112}
              height={20}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </a>
        </div>
      </div>
    </>
  );
};
