import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import { Biography } from "./Biography";
import { Exhibitions } from "./Exhibitions";
import { Publications } from "./Publications";
import { PressList } from "./PressList";
import { useParams } from "react-router-dom";
import { AppContext } from "../../ContextAPI/AppProvider";
import {
  baseApiURL,
  artistNewsCount,
  artistSelectedWorkCount,
  lottieOption,
} from "../../Helpers/Constant";
import { useNavigate } from "react-router-dom";
import { NewsComponent } from "../../components/NewsComponent";
import { SelectedWorksComponent } from "../../components/SelectedWorksComponent";
import { enableLangButton, disableLangButton } from "../../Helpers/Helper";
import Lottie from "react-lottie";
import BlockUI from "react-block-ui";
import axios from "axios";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import "react-block-ui/style.css";
import { ArtistDetailSkeleton } from "../../components/Skeletons/Artists/ArtistDetailSkeleton";
import ScrollIntoView from "react-scroll-into-view";

export const ArtistDetail = () => {
  let navigate = useNavigate();
  let router = useLocation();
  const params = useParams();
  let activeMenu = {};
  let scrollToViewOption = {
    behavior: "smooth",
    block: "start",
  };

  const [scrollActive, setScrollActive] = useState(activeMenu);
  const [artist, setArtist] = useState({});
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [blockUiBiography, setblockUiBiography] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const _getArtist = async () => {
    const getartistDetailUrl =
      baseApiURL + "/Artist/getPublishedArtistDetailBySeoId";
    const data = { langId: dirimart.langId, seoId: params.Id };
    var config = {
      method: "post",
      url: getartistDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _prepare = () => {
    setArtist({});
    setDirimartData(disableLangButton(dirimart));
    setblockUiBiography(true);
    _getArtist()
      .then((response) => {
        if (response.responseResult.result === false) {
          navigate(-1);
          return;
        }
        setArtist(response.artist);
        setDirimartData(enableLangButton(dirimart));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsInitialLoad(false);
    _prepare();
  }, [router]);

  useEffect(() => {
    if (isInitialLoad) return;
    _prepare();
  }, [dirimart.langId]);

  return (
    <div>
      <div className="subMenuDiv">
        <ul className="subMenu">
          <li key={"biography"}>
            <ScrollIntoView selector="#biography">
              <a>
                <a>{translation.ArtistDetail.biography}</a>
              </a>
            </ScrollIntoView>
          </li>
          <li key={"selectedWorks"}>
            <ScrollIntoView selector="#selectedWorks">
              <a>{translation.SelectedWork.Title}</a>
            </ScrollIntoView>
          </li>
          <li key={"exhibitions"}>
            <ScrollIntoView selector="#exhibitions">
              <a>{translation.Exhibition.Title}</a>
            </ScrollIntoView>
          </li>
          <li key={"news"}>
            <ScrollIntoView selector="#news">
              <a>{translation.News.Title}</a>
            </ScrollIntoView>
          </li>

          <li key={"publications"}>
            <ScrollIntoView selector="#publications">
              <a>{translation.Publication.Title}</a>
            </ScrollIntoView>
          </li>

          <li key={"press"}>
            <ScrollIntoView selector="#press">
              <a>{translation.Press.Title}</a>
            </ScrollIntoView>
          </li>
        </ul>
      </div>
      {isLoading && <ArtistDetailSkeleton></ArtistDetailSkeleton>}
      {!isLoading && (
        <div id="biography" style={{ paddingTop: "1px" }}>
          <Biography artist={artist} />
        </div>
      )}

      {artist.artistId && (
        <div id="selectedworks">
          {/* <SelectedWorks 
          artistId={params.Id}
         /> */}
          <SelectedWorksComponent
            recordCount={artistSelectedWorkCount}
            getSelectedWorkUrl={
              "/artistSelectedWork/getArtistSelectedWorkForArtistDetail"
            }
            getSelectedWorkTotalCountUrl={
              "/artistSelectedWork/getPublishedArtistSelectedWorksTotalCount"
            }
            Id={artist.artistId}
          />
        </div>
      )}

      {artist.artistId && (
        <div id="exhibitions">
          <Exhibitions
            artistFullName={artist.fullName}
            artistId={artist.artistId}
          />
        </div>
      )}
      {artist.artistId && (
        <div id="news">
          <NewsComponent
            recordCount={artistNewsCount}
            getNewsUrl={"/artistNews/getNPublishedArtistNews"}
            getNewsTotalCountUrl={
              "/artistNews/getNPublishedArtistNewsTotalCount"
            }
            Id={artist.artistId}
            allNewsTitle={artist.fullName}
            showDefaultTitle={true}
          />
        </div>
      )}
      {artist.artistId && (
        <div id="publications">
          <Publications
            artistName={artist.fullName}
            artistId={artist.artistId}
          />
        </div>
      )}
      {artist.artistId && (
        <div id="press">
          <PressList artistId={artist.artistId} />
        </div>
      )}
    </div>
  );
};
