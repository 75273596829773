
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from '..//../ContextAPI/AppProvider'
import { baseApiURL, highlightedPreviewCharacterCount, lottieOption } from "../../Helpers/Constant";
import { _htmlToPlaintext } from "../../Helpers/Helper";
import { HighlightedSkeleton } from "../../components/Skeletons/MainPage/HighlightedSkeleton";
import Lottie from 'react-lottie';
import BlockUI from "react-block-ui";
import axios from 'axios'
import 'react-block-ui/style.css';
export const MainPageHighlight = () => {

    const navigate = useNavigate();
    const [mainPageHighlight, setmainPageHighlightItem] = useState({});
    const { translation, dirimart } = useContext(AppContext);
    const [blockUi, setBlockUi] = useState(true);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [readMoreUrl, setReadMoreUrl] = useState("");
    const [isLoading,setIsLoading]=useState(true);

    const _getMainPageHighlightItem = async () => {
        const getFormDataUrl = baseApiURL + "/mainpagehighlighteditem/getFirstHighlightedItem";
        var requestData = {
            langId: dirimart.langId
        }
        var config = {
            method: "post",
            url: getFormDataUrl,
            data: requestData
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    };

    useEffect(() => {
        if (isInitialLoad)
            return

        setBlockUi(true);
        _getMainPageHighlightItem().then(response => {
            setmainPageHighlightItem(response);
            setBlockUi(false)
            setIsLoading(false)

        });
    }, [dirimart.langId])

    useEffect(() => {
        _getMainPageHighlightItem().then(response => {
            setmainPageHighlightItem(response);
            setBlockUi(false)
            setIsLoading(false)
            setIsInitialLoad(false);
            setReadMoreUrl(`/${response.type}/Detail/${response.regardingObjectId}`);
        });

    }, [])


    return (
       <>
       {isLoading&&
       <HighlightedSkeleton></HighlightedSkeleton>}
       {
        (!isLoading && mainPageHighlight.title)&&
        <div className="bannerContainer">

        {
            mainPageHighlight.title &&
            <>

                <img src={mainPageHighlight.imageURL} alt="banner" />
                <div className="rightSide">
                    <p className="title">{mainPageHighlight.title}</p>
                    <p className="description">
                        {_htmlToPlaintext(mainPageHighlight.detail)?.substring(0, highlightedPreviewCharacterCount)}...
                    </p>
                    <Link to={readMoreUrl}  className="btnYellow">{translation.Common.readmore}</Link>
                </div>
            </>
        }
    </div>
       }
           
     
            </>

    )

}