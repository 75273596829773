import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'
import { useNavigate,useLocation } from 'react-router-dom'
import { ExhibitionsDetailSkeleton } from '../../components/Skeletons/Exhibitions/ExhibitionsDetailSkeleton';
import {
    baseApiURL
  } from "../../Helpers/Constant";
  import axios from "axios";


export const Index = () => {

    let Navigate=useNavigate();
    const location=useLocation();
    
    const {dirimart, translation } = useContext(AppContext);
    const _getExhibition = async () => {
        
        const getExhibitionDetailUrl =
          baseApiURL + "/Exhibition/getQrId";
       let data={
        langId:dirimart.langId,route:location.pathname.replace("/","")
       }
        var config = {
          method: "post",
          url: getExhibitionDetailUrl,
          data:data
         
        };
        return new Promise(async (resolve, reject) => {
          await axios(config)
            .then((e) => resolve(e.data))
            .catch((e) => reject(e.response.data.ExceptionMessage));
        });
      };

      useEffect(()=>{

       
        _getExhibition().then((response)=>{
        var lang=dirimart.langId==1055?"tr":"en";
          if(location.pathname==="/qrfair"){
            
            Navigate("/"+lang+"/Fairs/"+response)
return
          }
            Navigate("/"+lang+"/Exhibitions/"+response)
        })


      },[])
  return (
    <ExhibitionsDetailSkeleton/>
  )
}


