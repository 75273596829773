import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const NewsSliderSkeleton = () => {
    return (
        <div id="newsContainer" className="newsContainer artistDetail" style={{ height: "720px" }}>
            <div className="news artistsNews" style={{ height: "100%" }}>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
                <div className="newsCard yellowBg" style={{ height: "100%" }}>
                    <div style={{ height: "100%",width:"100%" }}>
                        <Skeleton  width={"100%"} height={"100%"}></Skeleton>
                    </div>
                    <p className="title" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={30}></Skeleton>

                    </p>
                    <p className="description" style={{ width: "100%" }}>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>
                        <Skeleton width={"100%"} height={20}></Skeleton>

                    </p>
                </div>
            </div>
        </div>
    )
}


