import { useState } from "react";
import { useParams } from "react-router-dom";

export const LangId = () => {
    const lang = window.location.pathname.split("/")[1];
    try {
        const value = lang && lang === "en" ? 1033 : 1055;
        return value;

    } catch (e) {
        return 1055;
    }

};