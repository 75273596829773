import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../ContextAPI/AppProvider";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Manipulation } from "swiper";
import { HiArrowRight, HiArrowLeft, HiArrowUpRight } from "react-icons/hi2";
import { checkSwiperPrevNextArrows } from "../Helpers/Helper";
import { GlassMagnifier } from "react-image-magnifiers";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-block-ui/style.css";

export const ImageSliderComponent = (props) => {
  const [swiper, setSwiper] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [swiperArrows, setSwiperArrows] = useState({
    prev: false,
    next: showMore,
  });

  SwiperCore.use([Pagination, Navigation, Manipulation]);
  const _nextSlide = async () => {
    let nextPageNumber = currentPage + 1;
    if (nextPageNumber <= props.maxPageNumber) {
      await setcurrentPage(nextPageNumber);
    }
    swiper.slideNext();
    _setSliderArrows();
  };
  const _prevSlide = () => {
    swiper.slidePrev();
    _setSliderArrows();
  };

  const _setSliderArrows = () => {
    if (swiper !== undefined && swiper !== null) {
      var arrows = checkSwiperPrevNextArrows(swiper);
      if (showMore) {
        arrows.next = true;
      }
      setSwiperArrows(arrows);
    }
  };
  useEffect(() => {
    setIsInitialLoad(false);
  }, []);
  useEffect(() => {
    _setSliderArrows();
  }, [showMore]);
  useEffect(() => {
    if (currentPage < props.maxPageNumber) {
      setShowMore(true);
    }
  }, [props.maxPageNumber]);
  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    props.nextPage(currentPage);
    if (currentPage === props.maxPageNumber) {
      setShowMore(false);
    }
  }, [currentPage]);
  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    if (swiper !== null) {
      swiper.slideTo(swiper.activeIndex + props.imagePerPage);
      var arrows = checkSwiperPrevNextArrows(swiper);
      if (showMore) {
        arrows.next = true;
      }
      setSwiperArrows(arrows);
    }
  }, [props.images]);
  return (
    <div className="media-container">
      {props.htmlTitle}
      <h1 style={props.titleStyle}>{props.title}</h1>

      <Swiper
        spaceBetween={50}
        onSlideChange={() => _setSliderArrows()}
        modules={[Autoplay, Pagination, Navigation]}
        navigation={false}
        pagination={false}
        allowTouchMove={false}
        slidesPerView={1}
        onSwiper={setSwiper}
      >
        {props.images &&
          props.images.map((element, index) => {
            return (
              <SwiperSlide key={element.imageUrl + index}>
                <div className="magni">
                  <div className="magni-element">
                    <GlassMagnifier
                      className={"mainImg"}
                      imageSrc={element.imageUrl}
                      imageAlt="Example"
                      largeImageSrc={element.imageUrl} // Optional
                    />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        <div className="magniButtons-container">
          <div className="magniButtons">
            <div
              id="back"
              className={
                " firstSvg galleryNavButton " +
                (swiperArrows.prev == false ? "disabled" : "")
              }
              onClick={() => _prevSlide()}
            >
              <HiArrowLeft />
            </div>
            <div
              id="next"
              className={
                "lastSvg galleryNavButton " +
                (swiperArrows.next == false ? "disabled" : "")
              }
              style={{ fontSize: "larger" }}
              onClick={() => _nextSlide()}
            >
              <HiArrowRight />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  );
};
