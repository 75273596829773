import React, { useContext, useEffect, useState } from "react";
import { ExhibitionCard } from "./ExhibitionCard";
import { AppContext } from "../../ContextAPI/AppProvider";
import { useParams } from "react-router-dom";
import {
  baseApiURL,
  artistExhibitionCount,
  lottieOption,
} from "../../Helpers/Constant";
import { getBase64Image } from "../../Helpers/Helper";
import { ExhibitionSkeleton } from "../../components/Skeletons/SearchResult/ExhibitionSkeleton";
import axios from "axios";
import Lottie from "react-lottie";
import HashLoader from "react-spinners/HashLoader";
import BlockUI from "react-block-ui";
import "react-block-ui/style.css";

export const Exhibitions = (props) => {
  const params = useParams();

  const [artistId, setArtisId] = useState(
    props.artistId !== undefined ? props.artistId : params.id
  );
  const { dirimart, translation } = useContext(AppContext);
  const [artistExhibitions, setArtistExhibitions] = useState([]);
  const [blockUiExhibitions, setblockUiExhibitions] = useState(true);
  const [artistExhibitionMaxPageCount, setartistExhibitionMaxPageCount] =
    useState(0);
  const [artistexhibitionsTotalCount, setartistexhibitionsTotalCount] =
    useState(0);
  const [artistExhibitionCurrentPage, setartistExhibitionCurrentPage] =
    useState(1);
  const [artistExhibitionPerPage, setartistExhibitionPerPage] = useState(
    artistExhibitionCount
  );
  const [ShowMore, setShowMore] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const _getArtistExhibitions = async () => {
    const getartistDetailUrl =
      baseApiURL +
      "/artistExhibition/getPublishedArtistExhibitionsForArtistDetail";
    const data = {
      langId: dirimart.langId,
      artistId: artistId,
      recordCount: artistExhibitionCount,
      pageNumber: artistExhibitionCurrentPage,
    };
    var config = {
      method: "post",
      url: getartistDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getArtistExhibitionsTotalCount = async () => {
    const getartistDetailUrl =
      baseApiURL + "/artistExhibition/getPublishedArtistExhibitionsCount";
    const data = { artistId: props.artistId };
    var config = {
      method: "post",
      url: getartistDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _nextPage = () => {
    let nextPage = artistExhibitionCurrentPage + 1;
    if (nextPage <= artistExhibitionMaxPageCount) {
      setartistExhibitionCurrentPage(nextPage);
    }
    if (nextPage === artistExhibitionMaxPageCount) {
      setShowMore(false);
    }
  };

  useEffect(() => {
    setIsInitialLoad(false);
    _getArtistExhibitions()
      .then((response) => {
        setArtistExhibitions(response.artistExhibitions);
      })
      .catch((error) => console.log(error));

    _getArtistExhibitionsTotalCount().then((response) => {
      setartistexhibitionsTotalCount(response);
      let maxPageCount = Math.ceil(response / artistExhibitionPerPage);
      setartistExhibitionMaxPageCount(maxPageCount);
      if (artistExhibitionCurrentPage < maxPageCount) {
        setShowMore(true);
        setblockUiExhibitions(false);
        setIsLoading(false);
      }
    });
  }, []);
  useEffect(() => {
    setblockUiExhibitions(true);
    setIsLoading(true);
    _getArtistExhibitions()
      .then((response) => {
        if (artistExhibitionCurrentPage !== 1)
          setArtistExhibitions(
            artistExhibitions.concat(response.artistExhibitions)
          );
        else setArtistExhibitions(response.artistExhibitions);
        setblockUiExhibitions(false);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [artistExhibitionCurrentPage]);

  useEffect(() => {
    if (isInitialLoad) return;

    if (artistExhibitionCurrentPage !== 1) {
      setartistExhibitionCurrentPage(1);
      return;
    }
    setblockUiExhibitions(true);
    setIsLoading(true);

    _getArtistExhibitions()
      .then((response) => {
        setArtistExhibitions(response.artistExhibitions);
        setblockUiExhibitions(false);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [dirimart.langId]);
  return (
    <>
      {isLoading && <ExhibitionSkeleton></ExhibitionSkeleton>}
      {isLoading == false && artistExhibitions?.length > 0 && (
        <div
          id="exhibitionsContainer"
          className="exhibitionsContainer artistDetail"
        >
          <h1 className="detailExhibition">{translation.Exhibition.Title}</h1>
          <div className="exhibitions">
            {artistExhibitions.map((exhibition) => {
              return (
                <ExhibitionCard
                  key={exhibition.exhibitionId}
                  seoId={exhibition.exhibitionSeoId}
                  exhibitionId={exhibition.exhibitionId}
                  exhibitionName={exhibition.exhibitionName}
                  image={exhibition.imageUrl}
                  artist={props.artistFullName}
                  startDate={exhibition.startDate}
                  endDate={exhibition.endDate}
                  location={exhibition.location}
                />
              );
            })}
          </div>
          {ShowMore &&
          <button
            className="btnWhite smallBtn"
            // disabled={!ShowMore}
            onClick={() => _nextPage()}
          >
            {translation.Common.seemoreExhibitions}
          </button>
          }
        </div>
      )}
    </>
  );
};
