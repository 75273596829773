import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'
import { baseApiURL, artistPublicationsCount, lottieOption } from "../../Helpers/Constant";
import { PublicationCard } from './PublicationCard';
import { PublicationComponentSkeleton } from '../../components/Skeletons/Publications/PublicationComponentSkeleton';
import axios from 'axios'
import Lottie from 'react-lottie';
import BlockUI from "react-block-ui";
import 'react-block-ui/style.css';



export const Publications = (props) => {
  const { dirimart, translation } = useContext(AppContext);
  const [artistPublications, setArtistPublications] = useState([]);

  const [publicationsCurrentPage, setPublicationsCurrentPage] = useState(1);
  const [publicationsMaxPageCount, setPublicationsMaxPageCount] = useState(0);
  const [publicationsTotalCount, setPublicationsTotalCount] = useState(0);
  const [publicationsPerPage, setPublicationsPerPage] = useState(artistPublicationsCount);
  const [ShowMore, SetShowMore] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const _getArtistPublications = async () => {

    const getartistNewsUrl = baseApiURL + "/artistPublication/getPublishedArtistPublications";
    const data = { langId: dirimart.langId, artistId: props.artistId, pageNumber: publicationsCurrentPage, recordCount: publicationsPerPage };
    var config = {
      method: "post",
      url: getartistNewsUrl,
      data: data
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _getPublishedArtistPublicationsTotalCount = async () => {
    const getPublishedArtistPublicationsTotalCountUrl = baseApiURL + "/artistPublication/getPublishedArtistPublicationsCount";
    const data = { artistId: props.artistId };
    var config = {
      method: "post",
      url: getPublishedArtistPublicationsTotalCountUrl,
      data: data
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _nextPage = () => {
    let nextPage = publicationsCurrentPage + 1;
    if (nextPage <= publicationsMaxPageCount) {
      setPublicationsCurrentPage(nextPage);
    }
    if (nextPage === publicationsMaxPageCount) {
      SetShowMore(false)
    }

  }


  useEffect(() => {
    setIsInitialLoad(false);
    _getArtistPublications()
      .then((response) => {
       
        setArtistPublications(response.artistPublications);
      })
      .catch(error => {
        console.log(error)
      })
    _getPublishedArtistPublicationsTotalCount().then((response) => {
      let maxPageCount = Math.ceil(response / publicationsPerPage);
      setPublicationsMaxPageCount(maxPageCount);
      setPublicationsTotalCount(response);
      if (publicationsCurrentPage < maxPageCount) {
        SetShowMore(true)
      }
     
      setIsLoading(false);
    })

  }, [])


  useEffect(() => {
    if (isInitialLoad)
      return;
  
    _getArtistPublications().then((response) => {
      if (publicationsCurrentPage !== 1)
        setArtistPublications(artistPublications.concat(response.artistPublications));
      else
        setArtistPublications(response.artistPublications);
 
      setIsLoading(false);

    }).catch(error => console.log(error))
  }, [publicationsCurrentPage])


  useEffect(() => {
    if (isInitialLoad)
      return;

    if (publicationsCurrentPage !== 1) {
      setPublicationsCurrentPage(1);
      return
    }

    _getArtistPublications().then((response) => {
      setArtistPublications(response.artistPublications);
   
      setIsLoading(false);

    }).catch(error => console.log(error))

  }, [dirimart.langId])

  return (
    <>
    {
      isLoading&&
<PublicationComponentSkeleton></PublicationComponentSkeleton>
    }
      {
        !isLoading &&artistPublications?.length>0 &&
        
        <div id="publicationsContainer" className={"publicationsContainer "+(artistPublications.length<artistPublicationsCount?"publicationsContainer-few":"")} style={props.style}>
          <h1>{translation.Publication.Title}</h1>
          <div className="publications">
            {
              artistPublications.map((publication) => {
                return <PublicationCard
                  key={publication.publicationId}
                  imageUrl={publication.imageUrl}
                  publicationName={publication.publicationName}
                  publicationSummary={publication.publicationSummary}
                  seoId={publication.publicationSeoId}
                />
              })
            }

          </div>
          <button
            className={"btnWhite smallBtn"}
            disabled={!ShowMore}
            onClick={() => _nextPage}
          >{translation.Common.seemorePublications}</button>
        </div>
      }

    </>
  )
}


