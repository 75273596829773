    
import React from 'react'

export const SelectedWorkCardComponent = (props) => {
  return (
    <div className="workCard">
      <div>
        <img src={props.image} alt="work" />
      </div>

      <p>{props.overlay}</p>
    </div>
  )
}
