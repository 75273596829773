import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ExhibitionsListSkeleton = () => {
  return (
    <>
      <div className="exhibitionCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={320}
          height={280}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
        <Skeleton
          width={320}
          height={32}
          style={{ marginBottom: "0.5rem" }}
          count={2}
        ></Skeleton>
        <Skeleton
          width={247}
          height={20}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
      </div>
      <div className="exhibitionCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={320}
          height={280}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
        <Skeleton
          width={320}
          height={32}
          style={{ marginBottom: "0.5rem" }}
          count={2}
        ></Skeleton>
        <Skeleton
          width={247}
          height={20}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
      </div>
      <div className="exhibitionCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={320}
          height={280}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
        <Skeleton
          width={320}
          height={32}
          style={{ marginBottom: "0.5rem" }}
          count={2}
        ></Skeleton>
        <Skeleton
          width={247}
          height={20}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
      </div>
      <div className="exhibitionCard" style={{ backgroundColor: "unset" }}>
        <Skeleton
          width={320}
          height={280}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
        <Skeleton
          width={320}
          height={32}
          style={{ marginBottom: "0.5rem" }}
          count={2}
        ></Skeleton>
        <Skeleton
          width={247}
          height={20}
          style={{ marginBottom: "0.5rem" }}
        ></Skeleton>
      </div>
    </>
  );
};
