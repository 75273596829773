import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { isMobile } from 'react-device-detect'

export const FairDetailSkeleton = () => {
    return (
        <div>
            <div style={{ width: "100%", height: "306px" }}>
                    <Skeleton width={"100%"} height={"100%"}></Skeleton>
                </div>
            <div className="introduce" style={{ height: "100%", width: "100%" }}>
                
                <h4 style={{ height: "100%", width: "50%", alignSelf: "center" }}>
                    <div style={{ width: "100%" }}>
                        <Skeleton width={"60%"} height={"100%"} count={3}></Skeleton>

                    </div>
                </h4>
                <p><Skeleton width={"100%"} height={"364px"}></Skeleton></p>
            </div>
        </div>
    )
}

