import { isMobile } from "react-device-detect";
import * as animationData from "../assets/dirimart-loader-3.json";

export const baseApiURL = "https://webapi.dirimart.com/api";
// export const baseApiURL = "http://localhost:53956/api";
//  export const baseApiURL = "http://192.168.164.164:5675/api";

export const bookCategoryId = "3faba2ce-7a71-ed11-b8af-000c29eb5559";
export const paintCategoryId = "0bc9ee43-8871-ed11-b8af-000c29eb5559";
export const photographCategoryId = "154ac4ff-8b71-ed11-b8af-000c29eb5559";

export const publicationsTypes = {
  ExhibitionCatalogs: "172650000",
  ArtistCatalogs: "172650001",
  Monographs: "172650002",
  Biographies: "172650003",
  ArtistBookss: "172650004",
  Booklets: "172650005",
  Magazines: "172650006",
};

export const artistExhibitionCount = isMobile ? 1 : 6;
export const exhibitionCount = isMobile ? 1 : 6;
export const artistSelectedWorkCount = isMobile ? 1 : 4;
export const artistPublicationsCount = isMobile ? 1 : 6;
export const artistNewsCount = isMobile ? 1 : 3;
export const exhibitionsNewsCount = isMobile ? 1 : 3;

export const newsCount = isMobile ? 1 : 3;
export const artistPressCount = isMobile ? 1 : 5;

export const newDetailPreviewCharacterCount = 200;
export const highlightedPreviewCharacterCount = 200;
export const newsListCount = isMobile ? 1 : 6;
export const fairCount = 3;
export const selectedWorksCount = isMobile ? 1 : 8;
export const mediasCount = 1;
export const exhibitionStatus = {
  all: 0,
  past: 1,
  current: 2,
  upcoming: 3,
  online: 4,
};
export const newsTypes = {
  news: 172650000,
  announcements: 172650001,
  museumexhibitions: 172650002,
};
export const cultures = {
  1055: "tr",
  1033: "en",
};
export const newsLocation = {
  mainpage: 1,
  indetail: 2,
};
export const lottieOption = {
  loop: true,
  autoplay: true,
  speed: 0.5,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
