import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../ContextAPI/AppProvider'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper'

export const PublicationCard = (props) => {
    const { translation,dirimart } = useContext(AppContext);
    let navigate=useNavigate();
    return (
        <Link to={(dirimart.langId===1033?"/en":"/tr")+"/publications/"+removeSpecialCharactersAndWhiteSpace(props.publicationName)+"/"+props.seoId}>
        <div className="publicationCard" >
            <div className="imgDiv">
                <img src={props.imageUrl} alt={props.publicationName} />
            </div>
            <h3>{props.publicationName}</h3>
            <p>{props.publicationSummary}</p>
        </div>
        </Link>
    )
}

