
import React, { useEffect, useState, useContext } from "react";
import axios from 'axios'
import { AppContext } from '..//../ContextAPI/AppProvider'
import { useParams, useNavigate } from 'react-router-dom';
import { baseApiURL } from "../../Helpers/Constant";
import { TeamCard } from './TeamCard';
import { TeamSkeleton } from "../../components/Skeletons/Teams/TeamSkeleton";




export const Team = (props) => {
    const params = useParams();
    const { dirimart, translation } = useContext(AppContext);
    const [teamMembers, setTeamMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isInitialLoading, setIsInitialLoading] = useState(true);


    const _getPublishedTeamMembers = async () => {
        const getDataUrl = baseApiURL + "/TeamMember/getNPublishedTeamMembers";
        const data = { langId: dirimart.langId };
        var config = {
            method: "post",
            url: getDataUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    }
    useEffect(() => {
       
        if (isInitialLoading)
            return
  
        setTeamMembers([])
        setIsLoading(true)

        _getPublishedTeamMembers().then((response) => {
            setTeamMembers(response.teamMembers);
            setIsLoading(false);
        })

    }, [dirimart.langId])

    useEffect(() => {
     
        _getPublishedTeamMembers().then((response) => {
            setTeamMembers(response.teamMembers);
            setIsLoading(false);
        })
        setIsLoading(false);
        setIsInitialLoading(false)
    }, [])


    return (
        <div className="teamDiv">
            <h4>{translation.About.team}</h4>
            <div className="team">

                {
                    isLoading &&
                    <TeamSkeleton></TeamSkeleton>

                }
                {
                    !isLoading &&
                    teamMembers.map((teamMember,index) => {
                        return <TeamCard
                            key={teamMember.fullName+index}
                            fullName={teamMember.fullName}
                            title={teamMember.title}
                            imageUrl={teamMember.imageUrl} />
                    })
                }
            </div>
        </div>
    )
}


