import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'
import { baseApiURL, artistPressCount, lottieOption } from "../../Helpers/Constant";
import { useNavigate } from 'react-router-dom'
import { openInNewTab } from '../../Helpers/Helper';
import { PressListSkeleton } from '../../components/Skeletons/Press/PressListSkeleton';
import HashLoader from "react-spinners/HashLoader";
import axios from 'axios'
import BlockUI from "react-block-ui";
import Moment from 'react-moment';
import Lottie from 'react-lottie';
import 'react-block-ui/style.css';
import 'moment/locale/tr';


export const PressList = (props) => {
    let navigate = useNavigate();
    const { dirimart, translation } = useContext(AppContext);
    const [artistPress, setArtistPress] = useState([]);
    const [blockUiPress, setblockUiPress] = useState(true);
    const [artistPressMaxPageCount, setartistPressMaxPageCount] = useState(0);
    const [artistPressTotalCount, setartistPressTotalCount] = useState(0);
    const [artistPressCurrentPage, setartistPressCurrentPage] = useState(1);
    const [artistPressPerPage, setartistPressPerPage] = useState(artistPressCount);
    const [ShowMore, setShowMore] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(true)
    const [isPressLoaded, setIsPressLoaded] = useState(false)


    const _getArtistPress = async () => {

        const getartistDetailUrl = baseApiURL + "/artistPress/getNPublishedArtistPress";
        const data = { langId: dirimart.langId, artistId: props.artistId, recordCount: artistPressCount, pageNumber: artistPressCurrentPage };
        var config = {
            method: "post",
            url: getartistDetailUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    };
    const _getArtistPressTotalCount = async () => {
        const getartistDetailUrl = baseApiURL + "/artistPress/getNPublishedArtistPressTotalCount";
        const data = { Id: props.artistId, langId: dirimart.langId };
        var config = {
            method: "post",
            url: getartistDetailUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    };

    const _nextPage = () => {
        let nextPage = artistPressCurrentPage + 1;
        if (nextPage > artistPressMaxPageCount) {
            return
           
        }
         if (nextPage === artistPressMaxPageCount) {
            setShowMore(false)
        }
        setartistPressCurrentPage(nextPage);
       

    }
    useEffect(() => {
        setIsInitialLoad(false);
        _getArtistPress().then((response) => {
            setArtistPress(response.artistPress);
            setIsLoading(false);
            setIsPressLoaded(true)

        }).catch(error => console.log(error))
        _getArtistPressTotalCount().then((response) => {

            setartistPressTotalCount(response);
            let maxPageCount = Math.ceil(response / artistPressPerPage);
            setartistPressMaxPageCount(maxPageCount)
            if (artistPressCurrentPage < maxPageCount) {
                setShowMore(true);
                setblockUiPress(false);
               
            }
        });
    }, [])

    useEffect(() => {
        setblockUiPress(true);
        setIsLoading(true);

        _getArtistPress().then((response) => {
            if (artistPressCurrentPage !== 1)
                setArtistPress(artistPress.concat(response.artistPress));
            else
                setArtistPress(response.artistPress);
            setblockUiPress(false);
            setIsLoading(false);
            setIsPressLoaded(true)

        }).catch(error => console.log(error))
        if (artistPressCurrentPage < artistPressMaxPageCount) {
            setShowMore(true);

        }
    }, [artistPressCurrentPage])

    useEffect(() => {
        if (isInitialLoad)
            return;
        setblockUiPress(true);
        setIsLoading(true);

        if (artistPressCurrentPage !== 1)
            setartistPressCurrentPage(1);

        else {
            _getArtistPress().then((response) => {
                setArtistPress(response.artistPress);
                setblockUiPress(false);
                setIsLoading(false);


            }).catch(error => console.log(error))
        }


    }, [dirimart.langId])
    return (
        <>
            {
                isLoading  &&
                <PressListSkeleton></PressListSkeleton>
            }

            {
                isLoading == false && isPressLoaded&&artistPress?.length>0 &&
                <div className="pressContainer">
                    <h2>{translation.Press.Title}</h2>
                    <ul>
                        {

                            artistPress.map((press) => {
                                return (
                                    <li key={press.Id}>
                                        <div className="leftSide">
                                            <p className="title">{press.title}</p>
                                            <div><span>{press.publisherName}</span> <Moment element="span" format="D MMM YYYY" locale={dirimart.culture} withTitle>{press.publishDate}</Moment></div>
                                        </div>
                                        <div className="rightSide">
                                            {
                                                press.websiteUrl &&
                                                <a href={press.websiteUrl}>Website <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.5 12.5L12.5 4.5" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M6 4.5H12.5V11" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                                </a>
                                            }
                                            {
                                                press.pdfUrl &&
                                                <a href={press.pdfUrl} >PDF <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.5 12.5L12.5 4.5" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M6 4.5H12.5V11" stroke="#888888" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                                </a>
                                            }

                                        </div>
                                    </li>
                                )
                            })
                        }


                    </ul>
                    <div className="divButton"><button className="btnWhite smallBtn" onClick={() => _nextPage()} disabled={!ShowMore}>{translation.Common.seemore}</button></div>
                </div>
            }

        </>
    )
}


