import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "./ContextAPI/AppProvider";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { baseApiURL, cultures } from "./Helpers/Constant";
import { useLocalStorage } from "./Helpers/useLocalStorage";
import { openInNewTab } from "./Helpers/Helper";
import { removeSpecialCharactersAndWhiteSpace } from "./Helpers/Helper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/custom.css";
import axios from "axios";
import { isMobile, mobileModel } from "react-device-detect";

export const Header = () => {
  let navigate = useNavigate();
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const location = useLocation();
  const [langId, setLangId] = useLocalStorage("langId", null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [displaySearch, setDisplaySearch] = useState("none");
  const [searchResult, setSearchResult] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchResultTotalCount, setSearchResultTotalCount] = useState(0);
  const [searchResultShowingCount, setSearchResultShowingCount] = useState(5);
  const [logoDisplay, setLogoDisplay] = useState("visible");
  const [displaySearchResult, setDisplaySearchResult] = useState("none");
  const [showSkeletonForSearch, setShowSkeletonForSearch] = useState(true);
  const [displayResultInfo, setDisplayResultInfo] = useState("none");
  const [staticWebInformation, setStaticWebInformation] = useState(null);
  const [showMenu, setShowMenu] = useState(isMobile);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentUrl, setCurrentUrl] = useState(window.location.pathname);

  const searchRef = useRef();

  const [searchTextForLocalStorage, setSearchTextForLocalStorage] =
    useLocalStorage("searchText", null);

  var params = useParams();
  const _getStaticWebInformation = () => {
    const getDataUrl =
      baseApiURL + "/StaticWebInformation/getPublishedStaticWebInformation";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _changeLang = (langId) => {
    if (langId === 1033) {
      if (window.location.pathname.includes("/tr/")) {
        window.history.replaceState(
          null,
          "",
          window.location.pathname.replace("/tr/", "/en/")
        );
      } else {
        var newUrl = "/en" + window.location.pathname;
        window.history.replaceState(null, "", newUrl);
      }
    } else {
      window.history.replaceState(
        null,
        "",
        window.location.pathname.replace("/en/", "/tr/")
      );
    }
    setLangId(langId);
    let dirimartData = { ...dirimart };
    dirimartData.langId = langId;
    dirimart.culture = cultures[langId];
    setDirimartData(dirimartData);
    setSearchResult(null);
    setDisplaySearchResult("none");
    setSearchResultTotalCount(0);
  };
  const _openSearchBar = () => {
    searchResult && setDisplaySearchResult("block");
    setDisplaySearch("block");
  };
  const _quickSearch = () => {
    const getNewsDetailUrl = baseApiURL + "/search/quickSearch";
    const data = { langId: dirimart.langId, searchText: searchText };
    var config = {
      method: "post",
      url: getNewsDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const onClickHamburgerButton = (param) => {
    if (isMobile) setShowMenu(false);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const _search = () => {
    setDisplaySearchResult("block");
    setSearchResult(null);
    setDisplayResultInfo("none");
    setShowSkeletonForSearch(true);
    setSearchTextForLocalStorage(searchText);
    _quickSearch().then((response) => {
      setSearchResult(response.quickFounds);
      setLogoDisplay(response.totalFoundsCount);
      setSearchResultTotalCount(response.totalFoundsCount);
      let counter = 0;
      for (const key in response.quickFounds) {
        if (Object.hasOwnProperty.call(response.quickFounds, key)) {
          const element = response.quickFounds[key];
          element[0] && counter++;
        }
      }

      setSearchResultShowingCount(counter);

      setShowSkeletonForSearch(false);
      setDisplayResultInfo("flex");
    });
  };
  const _navigate = (route, params) => {
    setDisplaySearchResult("none");
    setDisplaySearch("none");
    if (params !== undefined) {
      navigate(route, { state: searchResult });
      return;
    }
    navigate(route);
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    _getStaticWebInformation().then((response) => {
      setStaticWebInformation(response.staticWebInformation);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    if (windowWidth < 1100) {
      isMobile = true;
      setShowMenu(false);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1100) {
      setShowMenu(false);

      isMobile = true;
    } else {
      setShowMenu(true);

      isMobile = false;
    }
  }, [windowWidth]);

  useEffect(() => {
    if (displaySearch === "block" && searchRef.current) {
      searchRef.current.focus();
    }
  }, [displaySearch]);

  return (
    <>
      <div className="header header-web">
        {/* <input value={""} id="navToggle" type="checkbox" /> */}
        <div className="headerGrid">
          <div className="headerGrid2">
            <Link
              id="logo1"
              className="logohref"
              to={dirimart.langId === 1033 ? "/en" : "/"}
            >
              <svg
                id="logo1"
                width="237"
                height="36"
                viewBox="0 0 237 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ visibility: logoDisplay, cursor: "pointer" }}
              >
                <g clipPath="url(#clip0_390_782)">
                  <path
                    d="M37.0938 35.5003H41.6913V16.798H37.0938V35.5003ZM37.0938 12.9329H41.6913V0.553711H37.0938V12.9329Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M87.2324 35.5003H91.8299V16.798H87.2324V35.5003ZM87.2324 12.9329H91.8299V0.553711H87.2324V12.9329Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M164.641 28.6603L167.027 35.4822H171.816L169.378 28.6603H164.641ZM154.645 0.553433L148.497 16.9758H153.13L156.961 6.2888L161.942 20.841H146.982L141.549 35.4466H146.39L150.204 24.7595H167.932L159.225 0.5H154.488L154.645 0.553433Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M211.922 4.6145H218.383V0.5H211.904V4.6145H211.922ZM222.179 0.5V35.5H226.777V4.6145H236.982V0.5H222.179Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M184.007 4.41852V12.9325H179.549V0.553361H192.262C194.234 0.505891 196.207 0.607122 198.165 0.856184C200.462 1.17177 202.555 2.36883 204.02 4.20463C205.485 6.04043 206.212 8.37748 206.054 10.7417C206.054 16.192 203.372 19.4516 197.974 20.3422C196.268 20.5405 194.553 20.6415 192.836 20.645H184.024V35.4821H179.549V16.7799H193.045C194.441 16.8281 195.837 16.7146 197.208 16.4414C198.471 16.2038 199.604 15.4984 200.391 14.4602C201.178 13.4219 201.564 12.1235 201.474 10.8129C201.523 9.89151 201.365 8.97087 201.012 8.12176C200.658 7.27264 200.12 6.51764 199.437 5.9147C198.574 5.20118 197.539 4.73958 196.441 4.57882C195.232 4.46169 194.016 4.41409 192.802 4.4363H183.99L184.007 4.41852ZM200.569 24.5101L207.535 35.4999H202.119L195.24 24.5101H200.586H200.569Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M8.4114 4.66821H4.63238V31.4926H8.4114V35.5003H0V0.553711H8.4114V4.66821ZM12.1905 31.3858C14.2812 31.3923 16.3466 30.9175 18.2334 29.9965C21.7164 28.0134 23.4579 24.0236 23.4579 18.027C23.4579 10.9617 21.0895 6.6869 16.3526 5.20259C15.009 4.85184 13.6293 4.66642 12.2427 4.65038V0.589315C14.0437 0.580581 15.8409 0.759656 17.6065 1.1237C20.0003 1.7235 22.1685 3.03053 23.8411 4.88194C26.6855 7.98118 28.1077 12.3688 28.1077 18.0448C28.1077 24.172 26.5113 28.7556 23.3186 31.7955C21.5885 33.4207 19.4668 34.5457 17.1711 35.055C15.5468 35.3704 13.8959 35.5195 12.2427 35.5003V31.457L12.1905 31.3858Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M108.025 12.9329L105.709 6.64534L106.196 35.5003H101.703V0.553711H107.555L112.082 12.9329H107.973H108.025ZM126.728 16.798L119.762 35.5003H116.279L109.418 16.798H113.528L118.073 29.2662L122.653 16.798H126.711H126.728ZM128.644 0.57154H134.495V35.5003H129.985L130.612 6.20005L128.139 12.9329H124.081L128.644 0.57154Z"
                    fill="#25282E"
                  ></path>
                  <path
                    d="M78.5586 10.7591C78.7065 12.4338 78.3734 14.1163 77.6001 15.6002C76.8267 17.0841 75.6467 18.3049 74.2048 19.1128L71.9061 15.5505C72.6152 15.0149 73.1803 14.3045 73.5487 13.4854C73.9171 12.6663 74.077 11.7652 74.0133 10.866C74.0622 9.94456 73.9039 9.02393 73.5507 8.17482C73.1975 7.3257 72.6587 6.5707 71.9757 5.96776C71.1152 5.25114 70.0793 4.78915 68.9804 4.63188C67.7767 4.51187 66.5672 4.46426 65.3581 4.48936H56.5461V16.8685H65.5845C66.5771 16.8685 67.4304 16.8685 68.1618 16.7616L80.0563 35.5708H74.6402L65.3581 20.7336H52.1053V0.570816H64.7833C66.7558 0.526134 68.7289 0.6273 70.687 0.873585C72.2436 1.0912 73.7223 1.7035 74.9885 2.65475C76.1832 3.59972 77.1343 4.82853 77.7595 6.2346C78.3846 7.64067 78.6649 9.18151 78.576 10.7235L78.5586 10.7591ZM56.5461 35.4995H52.0879V24.5098H56.5461V35.4995Z"
                    fill="#25282E"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_390_782">
                    <rect
                      width="237"
                      height="35"
                      fill="white"
                      transform="translate(0 0.5)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>
            </Link>
            <ul
              style={{ display: showMenu ? "flex" : "none" }}
              className="fixedMenu"
            >
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/Artists")}
                  to={"/Artists"}
                  className={
                    location.pathname.toLowerCase().includes("artists")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.artists}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/Exhibitions")}
                  to={"/Exhibitions"}
                  className={
                    location.pathname.toLowerCase().includes("exhibitions")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.exhibitions}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/Fairs")}
                  to={"/Fairs"}
                  className={
                    location.pathname.toLowerCase().includes("fairs")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.fairs}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/Publications")}
                  to={"/Publications"}
                  className={
                    location.pathname.toLowerCase().includes("publications")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.publications}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/News")}
                  to={"/News"}
                  className={
                    location.pathname.toLowerCase().includes("news")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.news}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => onClickHamburgerButton("/About")}
                  to={"/About"}
                  className={
                    location.pathname.toLowerCase().includes("about")
                      ? "active"
                      : ""
                  }
                >
                  {translation.Menu.aboutus}
                </Link>
              </li>
              <li>
                <div className="socialMediaIcons">
                  <a
                    onClick={() =>
                      openInNewTab(staticWebInformation.youtubeUrl)
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 12L10.5 9V15L15 12Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M2.25 11.9997C2.25 14.7934 2.54062 16.4247 2.75625 17.2684C2.81571 17.4974 2.92813 17.7091 3.08447 17.8866C3.2408 18.0641 3.43666 18.2024 3.65625 18.2903C6.79688 19.4903 12 19.4622 12 19.4622C12 19.4622 17.2031 19.4903 20.3438 18.2903C20.5633 18.2024 20.7592 18.0641 20.9155 17.8866C21.0719 17.7091 21.1843 17.4974 21.2438 17.2684C21.4594 16.4247 21.75 14.7934 21.75 11.9997C21.75 9.20592 21.4594 7.57467 21.2438 6.73092C21.1843 6.50198 21.0719 6.29022 20.9155 6.11272C20.7592 5.93522 20.5633 5.79694 20.3438 5.70904C17.2031 4.50904 12 4.53717 12 4.53717C12 4.53717 6.79688 4.50904 3.65625 5.70904C3.43666 5.79694 3.2408 5.93522 3.08447 6.11272C2.92813 6.29022 2.81571 6.50198 2.75625 6.73092C2.54062 7.57467 2.25 9.20592 2.25 11.9997Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </a>
                  <a
                    onClick={() =>
                      openInNewTab(staticWebInformation.facebookUrl)
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M15.75 8.25002H14.25C13.9542 8.24878 13.6611 8.30613 13.3875 8.41876C13.114 8.5314 12.8654 8.69708 12.6563 8.90626C12.4471 9.11544 12.2814 9.36397 12.1688 9.63752C12.0561 9.91106 11.9988 10.2042 12 10.5V21"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9 13.5H15"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </a>
                  <a
                    onClick={() =>
                      openInNewTab(staticWebInformation.twitterUrl)
                    }
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2303_8373)">
                        <path
                          d="M4 4L15.73 20H20L8.27 4H4Z"
                          stroke="#888888"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4 20L10.77 13.23M13.23 10.77L20 4"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2303_8373">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                  <a
                    onClick={() =>
                      openInNewTab(staticWebInformation.instagramUrl)
                    }
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                      ></path>
                      <path
                        d="M16.125 3.375H7.875C5.38972 3.375 3.375 5.38972 3.375 7.875V16.125C3.375 18.6103 5.38972 20.625 7.875 20.625H16.125C18.6103 20.625 20.625 18.6103 20.625 16.125V7.875C20.625 5.38972 18.6103 3.375 16.125 3.375Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
                        fill="black"
                      ></path>
                    </svg>
                  </a>
                  <a
                    onClick={() => openInNewTab(staticWebInformation.artsyUrl)}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3 21H21V3H3V21ZM19.7052 19.705H16.9811V16.1568H15.6852V19.705H4.29539V4.29439H19.7052V19.705Z"
                        fill="black"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.2586 6.15521H8.8299L6.1849 12.966H7.55258L8.26691 11.056H10.8312L11.5353 12.966H12.9041L10.2586 6.15521ZM8.66614 9.90283L9.54423 7.53262L10.4122 9.90283H8.66614Z"
                        fill="black"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="navbarRight">
          <ul>
            <li className="dropdown">
              <div className="followDropdown">{translation.Common.follow}</div>
              <div className="dropdownContent">
                <a
                  onClick={() => openInNewTab(staticWebInformation.youtubeUrl)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 12L10.5 9V15L15 12Z"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M2.25 11.9997C2.25 14.7934 2.54062 16.4247 2.75625 17.2684C2.81571 17.4974 2.92813 17.7091 3.08447 17.8866C3.2408 18.0641 3.43666 18.2024 3.65625 18.2903C6.79688 19.4903 12 19.4622 12 19.4622C12 19.4622 17.2031 19.4903 20.3438 18.2903C20.5633 18.2024 20.7592 18.0641 20.9155 17.8866C21.0719 17.7091 21.1843 17.4974 21.2438 17.2684C21.4594 16.4247 21.75 14.7934 21.75 11.9997C21.75 9.20592 21.4594 7.57467 21.2438 6.73092C21.1843 6.50198 21.0719 6.29022 20.9155 6.11272C20.7592 5.93522 20.5633 5.79694 20.3438 5.70904C17.2031 4.50904 12 4.53717 12 4.53717C12 4.53717 6.79688 4.50904 3.65625 5.70904C3.43666 5.79694 3.2408 5.93522 3.08447 6.11272C2.92813 6.29022 2.81571 6.50198 2.75625 6.73092C2.54062 7.57467 2.25 9.20592 2.25 11.9997Z"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 12L10.5 9V15L15 12Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M2.25 11.9997C2.25 14.7934 2.54062 16.4247 2.75625 17.2684C2.81571 17.4974 2.92813 17.7091 3.08447 17.8866C3.2408 18.0641 3.43666 18.2024 3.65625 18.2903C6.79688 19.4903 12 19.4622 12 19.4622C12 19.4622 17.2031 19.4903 20.3438 18.2903C20.5633 18.2024 20.7592 18.0641 20.9155 17.8866C21.0719 17.7091 21.1843 17.4974 21.2438 17.2684C21.4594 16.4247 21.75 14.7934 21.75 11.9997C21.75 9.20592 21.4594 7.57467 21.2438 6.73092C21.1843 6.50198 21.0719 6.29022 20.9155 6.11272C20.7592 5.93522 20.5633 5.79694 20.3438 5.70904C17.2031 4.50904 12 4.53717 12 4.53717C12 4.53717 6.79688 4.50904 3.65625 5.70904C3.43666 5.79694 3.2408 5.93522 3.08447 6.11272C2.92813 6.29022 2.81571 6.50198 2.75625 6.73092C2.54062 7.57467 2.25 9.20592 2.25 11.9997Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Youtube
                </a>
                <a
                  onClick={() => openInNewTab(staticWebInformation.facebookUrl)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M15.75 8.25002H14.25C13.9542 8.24878 13.6611 8.30613 13.3875 8.41876C13.114 8.5314 12.8654 8.69708 12.6563 8.90626C12.4471 9.11544 12.2814 9.36397 12.1688 9.63752C12.0561 9.91106 11.9988 10.2042 12 10.5V21"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9 13.5H15"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M15.75 8.25002H14.25C13.9542 8.24878 13.6611 8.30613 13.3875 8.41876C13.114 8.5314 12.8654 8.69708 12.6563 8.90626C12.4471 9.11544 12.2814 9.36397 12.1688 9.63752C12.0561 9.91106 11.9988 10.2042 12 10.5V21"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M9 13.5H15"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Facebook
                </a>
                <a
                  onClick={() => openInNewTab(staticWebInformation.twitterUrl)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2303_8373)">
                      <path
                        d="M4 4L15.73 20H20L8.27 4H4Z"
                        stroke="#888888"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 20L10.77 13.23M13.23 10.77L20 4"
                        stroke="#888888"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2303_8373">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2303_8373)">
                      <path
                        d="M4 4L15.73 20H20L8.27 4H4Z"
                        stroke="#888888"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4 20L10.77 13.23M13.23 10.77L20 4"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2303_8373">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Twitter
                </a>
                <a
                  onClick={() =>
                    openInNewTab(staticWebInformation.instagramUrl)
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    ></path>
                    <path
                      d="M16.125 3.375H7.875C5.38972 3.375 3.375 5.38972 3.375 7.875V16.125C3.375 18.6103 5.38972 20.625 7.875 20.625H16.125C18.6103 20.625 20.625 18.6103 20.625 16.125V7.875C20.625 5.38972 18.6103 3.375 16.125 3.375Z"
                      stroke="#888888"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
                      fill="#888888"
                    ></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    ></path>
                    <path
                      d="M16.125 3.375H7.875C5.38972 3.375 3.375 5.38972 3.375 7.875V16.125C3.375 18.6103 5.38972 20.625 7.875 20.625H16.125C18.6103 20.625 20.625 18.6103 20.625 16.125V7.875C20.625 5.38972 18.6103 3.375 16.125 3.375Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z"
                      fill="black"
                    ></path>
                  </svg>
                  Instagram
                </a>
                <a onClick={() => openInNewTab(staticWebInformation.artsyUrl)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 21H21V3H3V21ZM19.7052 19.705H16.9811V16.1568H15.6852V19.705H4.29539V4.29439H19.7052V19.705Z"
                      fill="#888888"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2586 6.15521H8.8299L6.1849 12.966H7.55258L8.26691 11.056H10.8312L11.5353 12.966H12.9041L10.2586 6.15521ZM8.66614 9.90283L9.54423 7.53262L10.4122 9.90283H8.66614Z"
                      fill="#888888"
                    ></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 21H21V3H3V21ZM19.7052 19.705H16.9811V16.1568H15.6852V19.705H4.29539V4.29439H19.7052V19.705Z"
                      fill="black"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2586 6.15521H8.8299L6.1849 12.966H7.55258L8.26691 11.056H10.8312L11.5353 12.966H12.9041L10.2586 6.15521ZM8.66614 9.90283L9.54423 7.53262L10.4122 9.90283H8.66614Z"
                      fill="black"
                    ></path>
                  </svg>
                  Artsy
                </a>
              </div>
            </li>
          </ul>
          <div>
            <a
              onClick={() =>
                !dirimart.langButtonDisabled
                  ? _changeLang(dirimart.langId == 1055 ? 1033 : 1055)
                  : null
              }
              disabled={dirimart.langButtonDisabled}
              className={dirimart.langButtonDisabled ? "disabled" : ""}
            >
              {dirimart.langId == 1055 ? "EN" : "TR"}
            </a>
          </div>
          <div>
            <svg
              onClick={() => _openSearchBar()}
              id="searchBtnOfHeader"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M16.4453 16.4434L21.0016 20.9996"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
          <div className="iconBurger">
            <svg
              htmlFor="navToggle"
              onClick={() => toggleMenu()}
              //   className="iconBurger"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#000000"
              viewBox="0 0 256 256"
            >
              <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path>
            </svg>
            {/* <label
              htmlFor="navToggle"
              onClick={() => toggleMenu()}
              className="iconBurger"
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </label> */}
          </div>
        </div>
      </div>

      {/* Search Component */}
      <div
        className="searchPopup"
        id="searchPopup"
        style={{ display: displaySearch }}
      >
        <div className="searchField">
          <input
            ref={searchRef}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                _search();
              }
            }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            type="text"
            placeholder={translation.Common.searchtext}
          />
          <div className="searchPopupIcons">
            <svg
              onClick={() => _search()}
              id="searchBtnOfPopup"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.875 19.25C15.2242 19.25 18.75 15.7242 18.75 11.375C18.75 7.02576 15.2242 3.5 10.875 3.5C6.52576 3.5 3 7.02576 3 11.375C3 15.7242 6.52576 19.25 10.875 19.25Z"
                stroke="#25282E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M16.4443 16.9434L21.0006 21.4996"
                stroke="#25282E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <svg
              onClick={() => setDisplaySearch("none")}
              id="closeSearchPopup"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 15.5L15 25.5"
                stroke="#25282E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M25 25.5L15 15.5"
                stroke="#25282E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M32.5 6.75H7.5C6.80964 6.75 6.25 7.30964 6.25 8V33C6.25 33.6904 6.80964 34.25 7.5 34.25H32.5C33.1904 34.25 33.75 33.6904 33.75 33V8C33.75 7.30964 33.1904 6.75 32.5 6.75Z"
                stroke="#25282E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </div>
        </div>
        <div
          className="searchResultField"
          id="searchResultField"
          style={{ display: displaySearchResult }}
        >
          <div className="resultInfo" style={{ display: displayResultInfo }}>
            {dirimart.langId == 1033 ? (
              <p>
                {searchResultShowingCount} of {searchResultTotalCount}
              </p>
            ) : (
              <p>
                Gösterilen {searchResultTotalCount}/{searchResultShowingCount}{" "}
              </p>
            )}
            <a onClick={() => _navigate("/SearchResult", searchResult)}>
              {translation.Common.viewmore}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12L12 4"
                  stroke="#888888"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M5.5 4H12V10.5"
                  stroke="#888888"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </a>
          </div>
          {showSkeletonForSearch && (
            <div className="searchSkeleton">
              <div style={{ width: "20%" }}>
                <Skeleton height="100%" />
              </div>
              <div style={{ width: "50%" }}>
                <Skeleton height="100%" />
              </div>
            </div>
          )}
          {searchResult?.artists[0] && (
            <div className="result">
              <p className="resultTitle">{translation.Artist.Title}</p>
              <p
                onClick={() =>
                  _navigate(
                    (dirimart.langId === 1033 ? "/en" : "/tr") +
                      `/Artists/${removeSpecialCharactersAndWhiteSpace(
                        searchResult?.artists[0]?.name
                      )}/${searchResult?.artists[0]?.seoId}`
                  )
                }
                className="resultItem"
              >
                {searchResult?.artists[0]?.name}
              </p>
            </div>
          )}
          {showSkeletonForSearch && (
            <div className="searchSkeleton">
              <div style={{ width: "20%" }}>
                <Skeleton height="100%" />
              </div>
              <div style={{ width: "50%" }}>
                <Skeleton height="100%" />
              </div>
            </div>
          )}
          {searchResult?.exhibitions[0] && (
            <div className="result">
              <p className="resultTitle">{translation.Exhibition.Title}</p>
              <p
                onClick={() =>
                  _navigate(
                    (dirimart.langId === 1033 ? "/en" : "/tr") +
                      `/Exhibitions/${removeSpecialCharactersAndWhiteSpace(
                        searchResult?.exhibitions[0]?.name
                      )}/${searchResult?.exhibitions[0]?.seoId}`
                  )
                }
                className="resultItem"
              >
                {searchResult?.exhibitions[0]?.name}
              </p>
            </div>
          )}
          {showSkeletonForSearch && (
            <div className="searchSkeleton">
              <div style={{ width: "20%" }}>
                <Skeleton height="100%" />
              </div>
              <div style={{ width: "50%" }}>
                <Skeleton height="100%" />
              </div>
            </div>
          )}
          {searchResult?.fairs[0] && (
            <div className="result">
              <p className="resultTitle">{translation.Fair.Title}</p>
              <p
                onClick={() =>
                  _navigate(
                    (dirimart.langId === 1033 ? "/en" : "/tr") +
                      `/Fairs/${removeSpecialCharactersAndWhiteSpace(
                        searchResult?.fairs[0]?.name
                      )}/${searchResult?.fairs[0]?.seoId}`
                  )
                }
                className="resultItem"
              >
                {searchResult?.fairs[0]?.name}
              </p>
            </div>
          )}
          {showSkeletonForSearch && (
            <div className="searchSkeleton">
              <div style={{ width: "20%" }}>
                <Skeleton height="100%" />
              </div>
              <div style={{ width: "50%" }}>
                <Skeleton height="100%" />
              </div>
            </div>
          )}
          {searchResult?.news[0] && (
            <div className="result">
              <p className="resultTitle">{translation.News.Title}</p>
              <p
                onClick={() =>
                  _navigate(
                    (dirimart.langId === 1033 ? "/en" : "/tr") +
                      `/News/${removeSpecialCharactersAndWhiteSpace(
                        searchResult?.news[0]?.name
                      )}/${searchResult?.news[0]?.seoId}`
                  )
                }
                className="resultItem"
              >
                {searchResult?.news[0]?.name}
              </p>
            </div>
          )}
          {showSkeletonForSearch && (
            <div className="searchSkeleton">
              <div style={{ width: "20%" }}>
                <Skeleton height="100%" />
              </div>
              <div style={{ width: "50%" }}>
                <Skeleton height="100%" />
              </div>
            </div>
          )}
          {searchResult?.publications[0] && (
            <div className="result">
              <p className="resultTitle">{translation.Publication.Title}</p>
              <p
                onClick={() =>
                  _navigate(
                    (dirimart.langId === 1033 ? "/en" : "/tr") +
                      `/Publications/${removeSpecialCharactersAndWhiteSpace(
                        searchResult?.publications[0]?.name
                      )}/${searchResult?.publications[0]?.seoId}`
                  )
                }
                className="resultItem"
              >
                {searchResult?.publications[0]?.name}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
