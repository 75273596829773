import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'
import { isMobile } from "react-device-detect";
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { baseApiURL } from "../../Helpers/Constant";
import { HighlightedPublicationCard } from './HighlightedPublicationCard';
import { HiArrowRight, HiArrowLeft, HiArrowUpRight } from "react-icons/hi2";
import { checkSwiperPrevNextArrows, enableLangButton, disableLangButton } from '../../Helpers/Helper';
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Pagination, Navigation, Manipulation } from 'swiper';
import BlockUI from "react-block-ui";
import axios from 'axios'
import Lottie from 'react-lottie';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-block-ui/style.css';


export const HighlightedPublicationSlider = (props) => {
  const navigate = useNavigate();
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [swiper, setSwiper] = useState(null);
  const [publications, setPublications] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [publicationsMaxPageCount, setPublicationsMaxPageCount] = useState(0);
  const [showMore, setShowMore] = useState(currentPage >= publicationsMaxPageCount+1 ? false : true);
  const [swiperArrows, setSwiperArrows] = useState({ prev: false, next: showMore });
  const [isInitialpublicationsLoad, setIsIntialpublicationsLoad] = useState(true);
  const [loadedPage, setLoadedPage] = useState(1);

  SwiperCore.use([Pagination, Navigation, Manipulation]);

  const _nextSlide = async () => {

    let nextPageNumber = currentPage + 1;
    if (nextPageNumber > loadedPage) {
      if (nextPageNumber <= publicationsMaxPageCount) {
        setLoadedPage(nextPageNumber);
        setcurrentPage(nextPageNumber);

      } else {
        swiper.slideNext();
        _setSliderArrows();
      }

    }
    else {
      setcurrentPage(nextPageNumber);
      swiper.slideNext();
      _setSliderArrows();
    }
  };
  const _prevSlide = () => {
    setcurrentPage(currentPage - 1)
    swiper.slidePrev();
    _setSliderArrows();
  };

  const _setSliderArrows = () => {
    if (swiper === null || swiper === undefined)
      return;
    var arrows = checkSwiperPrevNextArrows(swiper);
    if (showMore) {
      arrows.next = true;
    }
    setSwiperArrows(arrows);

  }

  const _getPublications = async () => {
    const getPublicationsUrl = baseApiURL + props.getPublicationsUrl;
    const data = { langId: dirimart.langId,  recordCount: props.recordCount + 1, pageNumber: currentPage };
    var config = {
      method: "post",
      url: getPublicationsUrl,
      data: data
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getPublicationsTotalCount = async () => {
    const getPublicationsTotalCountUrl = baseApiURL + props.getPublicationsTotalCountUrl;
    
    var config = {
      method: "get",
      url: getPublicationsTotalCountUrl
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  useEffect(() => {
    _getPublications().then((response) => {
      setPublications(response.publications);
    }).catch(error => {
      console.log(error)
    })
    _getPublicationsTotalCount().then((response) => {

      let maxPageCount = Math.ceil(response / (props.recordCount + 1));
      setPublicationsMaxPageCount(maxPageCount);
      setTotalCount(response);

      if (currentPage <= maxPageCount - 1) {
        setShowMore(true);
      }


    })
    setIsInitialLoad(false);
  }, [])
  useEffect(() => {
    if (!isInitialLoad)
      if (swiper !== undefined)
        _setSliderArrows()
  }, [publicationsMaxPageCount])

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }

    _getPublications().then((response) => {
      if (loadedPage !== 1)
        setPublications(publications.concat(response.publications));
      else
        setPublications(response.publications)
      if (props.recordCount === publicationsMaxPageCount) {
        setShowMore(true);
      }
      if (currentPage === publicationsMaxPageCount) {
        setShowMore(false)
      }
      if (swiper !== undefined) {
        swiper.slideNext();
        _setSliderArrows();
      }

    }).catch(error => console.log(error))


  }, [loadedPage])
  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    if (isInitialpublicationsLoad) {
      setIsIntialpublicationsLoad(false)
      return;
    }
    if (swiper !== null && swiper.destroyed === false) {

      if (publications.length > props.recordCount)

        swiper.slideTo(swiper.activeIndex + 1)
      var arrows = checkSwiperPrevNextArrows(swiper);
      if (showMore) {
        arrows.next = true;
      }
      setSwiperArrows(arrows)
    }
  }, [publications])
  useEffect(() => {

    setcurrentPage(1);
    if (isInitialLoad) {
      return;
    }
    if (loadedPage !== 1) {
      setLoadedPage(1);

      return;
    }

    setDirimartData(disableLangButton(dirimart));
 
    _getPublications().then((response) => {
      setPublications(response.publications);
      if (props.recordCount === publicationsMaxPageCount) {
        setShowMore(true);
      }
      if (currentPage === publicationsMaxPageCount) {
        setShowMore(false)
      }
      if (swiper !== undefined) {

        _setSliderArrows();
      }
  

      setDirimartData(enableLangButton(dirimart))

    }).catch(error => console.log(error))
    _getPublicationsTotalCount().then(response => {
      let maxPageCount = Math.ceil(response / (props.recordCount + 1));
      setPublicationsMaxPageCount(maxPageCount);
      setTotalCount(response);
      if (currentPage < maxPageCount) {
        setShowMore(true);
      }
    })
  }, [dirimart.langId])
  return (
      <div  className="newsContainer shop" style={props.style}>
        <div className="newsHeader">
          {
            props.showDefaultTitle == true &&
            <h2>{translation.Publications.Title}</h2>
          }{
            props.title &&
            <h2>{props.title}</h2>
          }
          <div className="newsRightSide">
            {
              props.customRedirectButton&&
              <button onClick={() => navigate(`${props.redirectUrl}`)} className="btnWhite">{props.redirectText}<HiArrowUpRight />
              </button>
            }
            <div className={"iconDiv whiteIconDiv " + (swiperArrows.prev === false ? "disabled" : "")} style={props.arrowStyles} onClick={() => _prevSlide()}>
              <HiArrowLeft />
            </div>
            <div className={"iconDiv whiteIconDiv " + (swiperArrows.next === false ? "disabled" : "")} style={props.arrowStyles} onClick={() => _nextSlide()}>
              <HiArrowRight />
            </div>
          </div>
        </div>
        {
          publications[0] &&

          <div className="news">
            <Swiper

              style={{minHeight:"310px"}}
              onSlideChange={() => _setSliderArrows()}
              modules={[Autoplay, Pagination, Navigation]}
              navigation={false}
              pagination={false}
              // slidesPerView={isMobile ? 1 : publications.length <= props.recordCount ? publications.length : props.recordCount + 0.4}
              breakpoints={{
                640: {
                  // width: 640,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  // width: 768,
                  slidesPerView: 1.4,
                },
                1366:{
                  slidesPerView: 2.4,
                },
                1920:{
                  slidesPerView:  isMobile ? 1 : publications.length <= props.recordCount ? publications.length : props.recordCount + 0.4,
                }
              }}
              onSwiper={setSwiper}>
              {
                publications.map((element, index) => {

                  return (

                    <SwiperSlide key={index}>
                     
                   
                        <HighlightedPublicationCard
                          publication={element}
                        />
                    

                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        }
      </div>


    
  )
}


