import React, { Fragment, useContext } from "react";
import { MainPageBanner } from "./MainPageBanner";
import { Exhibitions } from "./Exhibitions";
import { Fair } from "./Fair";
import { MainPageHighlight } from "./MainPageHighlight";
import { NewsComponent } from "../components/NewsComponent";
import { AppContext } from "../ContextAPI/AppProvider";
import { newsLocation } from "../Helpers/Constant";

export const MainPage = () => {
  // const { dirimart, translation } = useContext(AppContext);

  return (
    <Fragment>
      <div>
        <MainPageBanner></MainPageBanner>
        <Exhibitions></Exhibitions>
        <NewsComponent
          location={newsLocation.mainpage}
          recordCount={3}
          getNewsUrl={"/news/getPublishedNews"}
          getNewsTotalCountUrl={"/news/getTotalCount"}
          showDefaultTitle={true}
        ></NewsComponent>
        <MainPageHighlight></MainPageHighlight>
        <Fair></Fair>
      </div>
    </Fragment>
  );
};
