import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const NewsDetailSkeleton = () => {
  return (
    <>
      <div className="newsDetail">
        <div className="leftSide">
          <Skeleton
            width={636}
            height={530}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </div>
        <div className="rightSide">
          <h2>
            <Skeleton
              width={300}
              height={43}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </h2>

          <p>
            <Skeleton
              width={700}
              height={96}
              style={{ marginBottom: "0.5rem" }}
            ></Skeleton>
          </p>
        </div>
      </div>
    </>
  );
};
