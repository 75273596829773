import React, { Component } from 'react'
import { Index } from './containers/Shop/Index';
import ScrollToTop from './Helpers/scrolltoTop';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import  ShopHeader  from './containers/Shop/ShopHeader';
import { ShopFooter } from './containers/Shop/ShopFooter';
export default class ShopWarpper extends Component {
    render() {
        return (<>
           
                <ShopHeader></ShopHeader>
                <Outlet></Outlet>
                <ShopFooter></ShopFooter>
           
        </>
        )
    }
}
