import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import {
  baseApiURL,
  newsListCount,
  newsTypes,
  lottieOption,
} from "../../Helpers/Constant";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie";
import axios from "axios";
import BlockUI from "react-block-ui";
import Masonry from "react-masonry-css";
import { NewsListSkeleton } from "../../components/Skeletons/News/NewsListSkeleton";
import { isMobile } from "react-device-detect";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";

export const NewsList = () => {
  let navigate = useNavigate();

  const { dirimart, translation } = useContext(AppContext);
  const [news, setNews] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [selectedNewsType, setSelectedNewsType] = useState(newsTypes.news);
  const [previusNewsType, setPreviusNewsType] = useState(newsTypes.news);
  const [showMore, setShowMore] = useState(false);
  const [type, setType] = useState(1);
  const [blockUi, setBlockUi] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabledButtons, setIsDisableButtons] = useState(true);

  const _showMore = () => {
    var nextPage = pageNumber + 1;
    if (nextPage > maxPageNumber) return;
    setIsLoading(true);
    setPageNumber(nextPage);
  };

  const _getNews = async () => {
    const getFormDataUrl = baseApiURL + "/news/getPublishedNews";
    const data = {
      langId: dirimart.langId,
      recordCount: newsListCount,
      pageNumber: pageNumber,
      type: selectedNewsType,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.news))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getNewsCount = async () => {
    const getFormDataUrl = baseApiURL + "/news/getTotalCount";
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: { type: selectedNewsType, langId: dirimart.langId },
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  useEffect(() => {
    _getNews().then((response) => {
      setNews(response);
      setIsLoading(false);
    });
    _getNewsCount().then((response) => {
      var maxPage = Math.ceil(response / newsListCount);
      if (pageNumber < maxPage) setShowMore(true);
      else setShowMore(false);
      setMaxPageNumber(maxPage);

      setTotalCount(response);
      setIsDisableButtons(false);
    });
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (isInitialLoad) return;
    _getNews().then((response) => {
      if (pageNumber !== 1) setNews(news.concat(response));
      else setNews(response);
      setIsLoading(false);
    });
    if (pageNumber === maxPageNumber) {
      setShowMore(false);
    }
  }, [pageNumber]);
  useEffect(() => {
    if (isInitialLoad) return;
    setIsDisableButtons(true);
    setIsLoading(true);
    setNews([]);
    _getNews().then((response) => {
      setNews(response);
      setIsLoading(false);
    });
    _getNewsCount().then((response) => {
      var maxPage = Math.ceil(response / newsListCount);
      if (pageNumber < maxPage) setShowMore(true);
      else setShowMore(false);
      setMaxPageNumber(maxPage);
      setTotalCount(response);
      setIsDisableButtons(false);
    });
  }, [selectedNewsType]);

  useEffect(() => {
    setBlockUi(true);
    if (isInitialLoad) return;
    if (pageNumber !== 1) {
      setPageNumber(1);
      return;
    }
    _getNews().then((response) => {
      setNews(response);
      setIsLoading(false);
    });
  }, [dirimart.langId]);

  return (
    <div className=" news-list-container">
      <div
        className={
          (totalCount % 2 == 0 ? "news-even" : "news-odd") + " news news-list"
        }
        style={{ width: "100%" }}
      >
        <div>
          <div className="newsHead">
            <h2>{translation.News.Title}</h2>
            <div className="buttons">
              <a>
                <button
                  disabled={isDisabledButtons}
                  className={
                    selectedNewsType === newsTypes.news
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => {
                    setSelectedNewsType(newsTypes.news);
                  }}
                >
                  {translation.News.newsButtonText}
                </button>
              </a>
              <a>
                <button
                  disabled={isDisabledButtons}
                  className={
                    selectedNewsType === newsTypes.announcements
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => {
                    setSelectedNewsType(newsTypes.announcements);
                  }}
                >
                  {translation.News.announcements}
                </button>
              </a>
              <a>
                <button
                  disabled={isDisabledButtons}
                  className={
                    selectedNewsType === newsTypes.museumexhibitions
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => {
                    setSelectedNewsType(newsTypes.museumexhibitions);
                  }}
                >
                  {translation.News.museumexhibitions}
                </button>
              </a>
            </div>
          </div>

          <Masonry
            breakpointCols={isMobile ? 1 : 3}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {news &&
              news.map((element) => {
                return (
                  <Link
                    className="cardlink"
                    to={
                      (dirimart.langId === 1033 ? "/en" : "/tr") +
                      "/News/" +
                      removeSpecialCharactersAndWhiteSpace(element.title) +
                      "/" +
                      element.newsSeoId
                    }
                  >
                    <div className="news-card">
                      <img src={element.imageURL} alt="news" />
                      <h4>{element.title}</h4>
                      <p>{element.summary}</p>
                    </div>
                  </Link>
                );
              })}
          </Masonry>
          {isLoading && <NewsListSkeleton></NewsListSkeleton>}
        </div>
      </div>
      <div className="grid place-content-center" style={{ marginTop: "2rem" }}>
        <button
          className="btnWhite"
          onClick={() => _showMore()}
          disabled={!showMore}
        >
          {translation.Common.seemoreNews}
        </button>
      </div>
    </div>
  );
};
