import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


export const HighlightedSkeleton = () => {
    return (
        <div className="bannerContainer">
            <div style={{width:"30%"}}>
            <Skeleton width={"100%"} height={"450px"}></Skeleton>

            </div>
            
                <div className="rightSide">
                    <p className="title"><Skeleton width={"100%"} height={"40"}></Skeleton></p>
                    <p className="description">
                    <Skeleton width={"100%"} height={"20"} style={{marginBottom:"1rem"}}></Skeleton>
                    <Skeleton width={"100%"} height={"20"} style={{marginBottom:"1rem"}}></Skeleton>
                    <Skeleton width={"100%"} height={"20"} style={{marginBottom:"1rem"}}></Skeleton>
                    <Skeleton width={"100%"} height={"20"} style={{marginBottom:"1rem"}}></Skeleton>
                    <Skeleton width={"100%"} height={"20"} style={{marginBottom:"1rem"}}></Skeleton>
                    </p>
                </div>
        </div>
    )
}
