import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { AppContext } from "../../ContextAPI/AppProvider";
import {
  baseApiURL,
  fairCount,
  selectedWorksCount,
  mediasCount,
} from "../../Helpers/Constant";
import { useNavigate } from "react-router-dom";
import { NewsComponent } from "../../components/NewsComponent";
import { ImageSliderComponent } from "../../components/ImageSliderComponent";
import { SelectedWorksComponent } from "../../components/SelectedWorksComponent";
import {
  disableLangButton,
  enableLangButton,
  splitArray,
  openInNewTab,
} from "../../Helpers/Helper";
import { NewsSliderSkeleton } from "../../components/Skeletons/News/NewsSliderSkeleton";
import { FairDetailSkeleton } from "../../components/Skeletons/Fairs/FairDetailSkeleton";
import { ImagesSkeleton } from "../../components/Skeletons/ImagesSkeleton";
import { isMobile } from "react-device-detect";
import HashLoader from "react-spinners/HashLoader";
import BlockUI from "react-block-ui";
import axios from "axios";
import parse from "html-react-parser";
import "react-block-ui/style.css";

export const FairDetail = () => {
  const params = useParams();
  let navigate = useNavigate();
  let router = useLocation();

  const { dirimart, translation } = useContext(AppContext);
  const [fair, setFair] = useState({});
  const [artists, setArtists] = useState([]);
  const [blockUi, setBlockUi] = useState(true);
  const [blockUiImages, setBlockUiImages] = useState(true);
  const [blockUiArtists, setBlockUiArtists] = useState(true);
  const [fairImages, setFairImages] = useState([]);
  const [fairImagesMaxPage, setFairImagesMaxPage] = useState(0);
  const [fairImagesTotalCount, setFairImagesTotalCount] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isNewsLoading, setIsNewsLoading] = useState(true);
  const [isFairDetailLoading, setIsFairDetailLoading] = useState(true);
  const [isFairImagesLoading, setIsFairImagesLoading] = useState(true);

  const getImagesNextPage = (pageNumber) => {
    setBlockUiImages(true);
    _getImages(pageNumber).then((response) => {
      let images = [];
      response.fairImages.forEach((element) => {
        let img = { imageUrl: element.imageUrl };
        images.push(img);
      });
      setFairImages(fairImages.concat(images));
      setBlockUiImages(false);
    });
  };
  const _getFairDetail = async () => {
    const getFairDetailUrl = baseApiURL + "/fairs/getFairDetailBySeoId";
    const data = {
      langId: dirimart.langId,
      seoId: params.Id,
    };
    var config = {
      method: "post",
      url: getFairDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _getImages = async (pageNumber) => {
    const getImagesUrl = baseApiURL + "/fairImage/getNPublishedFairImages";
    const data = {
      langId: dirimart.langId,
      Id: fair.Id,
      recordCount: 1,
      pageNumber: pageNumber ? pageNumber : 1,
    };
    var config = {
      method: "post",
      url: getImagesUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getImagesTotalCount = async () => {
    const getTotalCountUrl =
      baseApiURL + "/fairImage/getNPublishedFairImagesTotalCount";
    const data = { Id: params.Id };
    var config = {
      method: "post",
      url: getTotalCountUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getArtists = async () => {
    const getArtistsUrl = baseApiURL + "/fairArtist/getPublishedFairArtists";
    const data = { langId: dirimart.langId, Id: fair.Id };
    var config = {
      method: "post",
      url: getArtistsUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _prepare = () => {
    setBlockUi(true);
    setIsFairDetailLoading(true);
    _getFairDetail().then((response) => {
      setBlockUi(false);
      setFair(response);

      setIsFairDetailLoading(false);
    });
    _getImages().then((response) => {
      let images = [];
      response.fairImages.forEach((element) => {
        let img = { imageUrl: element.imageUrl };
        images.push(img);
      });
      setFairImages(images);
      setIsFairImagesLoading(false);
    });
    _getImagesTotalCount().then((response) => {
      setFairImagesTotalCount(response);
    });
  };

  useEffect(() => {
    setIsInitialLoad(false);
    _prepare();
  }, [router]);
  useEffect(() => {
    if (isInitialLoad) return;
    setFairImagesMaxPage(Math.ceil(fairImagesTotalCount / mediasCount));
  }, [fairImagesTotalCount]);
  useEffect(() => {
    if (isInitialLoad) return;

    _prepare();
  }, [dirimart.langId]);
  useEffect(() => {
    _getArtists().then((response) => {
      if (isMobile) {
        setArtists(splitArray(response.artistList, 2));
      } else {
        setArtists(splitArray(response.artistList, 3));
      }

      setBlockUiArtists(false);
    });
  }, [fair.Id]);
  return (
    <div className="fairsDetailContainer">
      {isFairDetailLoading && <FairDetailSkeleton></FairDetailSkeleton>}
      {fair.imageURL && (
        <img src={fair.imageURL} alt="fair" className="introduceImage" />
      )}
      <div className="introduce" style={{ minHeight: "200px" }}>
        <h4>{fair.title}</h4>
        {fair.detail && <p>{parse(fair.detail)} </p>}
      </div>

      {fair.Id && (
        <SelectedWorksComponent
          recordCount={selectedWorksCount}
          getSelectedWorkUrl={"/artistSelectedWork/getFairSelectedWork"}
          getSelectedWorkTotalCountUrl={
            "/artistSelectedWork/getPublishedFairSelectedWorksTotalCount"
          }
          Id={fair.Id}
        />
      )}

      {isFairImagesLoading && <ImagesSkeleton></ImagesSkeleton>}
      {fairImages[0] && fair.Id && (
        <ImageSliderComponent
          recordCount={mediasCount}
          images={fairImages}
          totalCount={fairImagesTotalCount}
          imagePerPage={mediasCount}
          nextPage={getImagesNextPage}
          Id={fair.Id}
          maxPageNumber={fairImagesMaxPage}
          title={translation.Fair.images}
          style={{ backgroundColor: "white" }}
        />
      )}

      {artists[0] && artists[0][0] && fair.Id && (
        <div>
          <h1 className="artistsHeading">{translation.Fair.artists}</h1>
          <ul className="namesDiv">
            {artists.map((artistArrays, index) => {
              return (
                <div
                  className={
                    isMobile ? "namesColFor2Columns" : "namesColFor3Columns"
                  }
                  key={index}
                >
                  {artistArrays.map((artist) => {
                    return (
                      <li key={artist.name}>
                        <Link
                          className="nameOfArtist"
                          to={
                            (dirimart.langId === 1033 ? "/en" : "/tr") +
                            `/Artists/${artist.name}_${artist.lastName}/${artist.seoId}`
                          }
                        >
                          {artist.name} {artist.lastName}
                        </Link>
                        <img src={artist.imageUrl} alt={artist.name} />
                      </li>
                    );
                  })}
                </div>
              );
            })}
          </ul>
        </div>
      )}

      {fair.Id && (
        <NewsComponent
          recordCount={fairCount}
          getNewsUrl={"/fairNews/getNPublishedfairNews"}
          getNewsTotalCountUrl={"/fairNews/getNPublishedfairNewsTotalCount"}
          Id={fair.Id}
          allNewsTitle={translation.Fair.all}
          title={translation.News.Title}
          titleStyle={{
            fontFamily: "Apercu Pro",
            fontWeight: 700,
            fontSize: "27px",
            lineHeight: "140%",
            color: "white",
            marginBottom: "10px",
          }}
        />
      )}
    </div>
  );
};
