import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../ContextAPI/AppProvider";
import { removeSpecialCharactersAndWhiteSpace } from "../Helpers/Helper";

export const NewsCardComponent = (props) => {
  const { translation, dirimart } = useContext(AppContext);
  return (
    <div>
      <Link
        to={
          (dirimart.langId === 1033 ? "/en" : "/tr") +
          "/News/" +
          removeSpecialCharactersAndWhiteSpace(props.news.title) +
          "/" +
          props.news.newsSeoId
        }
      >
        <div className="newsCard  yellowBg" style={{ maxWidth: "100%" }}>
          <img
            src={props.news.imageUrl || props.news.imageURL}
            alt={props.news.title}
          />

          <p className="title">{props.news.title}</p>
          <p className="description">{props.news.summary}</p>
        </div>
      </Link>
    </div>
  );
};
