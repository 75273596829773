import React, { useContext } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import { Link } from "react-router-dom";

const JobOpportunities = () => {
  const { translation } = useContext(AppContext);
  return (
    <div className="jobOpportunitiesPage" style={{ minHeight: "300px" }}>
      <h2>{translation.Menu.jobOpportunities}</h2>
      <p>
        {translation.jobOpportunities?.paragraph1}
        <a
          href="https://www.linkedin.com/company/di%CC%87ri%CC%87mart/jobs/"
          target="_blank"
        >
          LinkedIn
        </a>
        {translation.jobOpportunities?.paragraph2}
        <b>info@dirimart.com</b>
        {translation.jobOpportunities?.paragraph3}
      </p>
    </div>
  );
};

export default JobOpportunities;
