import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const FairListSkeleton = () => {
    return (
        <>
            <div className="fairCard" style={{backgroundColor:"unset"}}>
                <Skeleton width={416} height={330}></Skeleton>

            </div>
            <div className="fairCard" style={{backgroundColor:"unset"}}>
                <Skeleton width={416} height={316}></Skeleton>

            </div>
            <div className="fairCard" style={{backgroundColor:"unset"}}>
                <Skeleton width={416} height={316}></Skeleton>

            </div>
        </>
    )
}


