import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const PressListSkeleton = () => {
  return (
    <div className="pressContainer">
      <h2 style={{width:"30%"}}><Skeleton width={"100%"} height={"100%"}></Skeleton></h2>
      <ul>
        <li>
          <div className="leftSide">
            <p className="title">
                <Skeleton width={"100%"}></Skeleton></p>
            <div style={{textAlign:"start",display:"flex"}}>
                <span style={{width:"10%"}}><Skeleton width={"80%"} style></Skeleton></span>
                 <span style={{width:"10%"}}><Skeleton width={"80%"} ></Skeleton></span></div>
          </div>
          <div className="rightSide">
            <a href="#" style={{width:"40%"}}> <Skeleton width={"100%"} ></Skeleton>
            </a>
            <a href="#" style={{width:"40%"}}><Skeleton width={"100%"}></Skeleton>
            </a>
          </div>
        </li>
        <li>
          <div className="leftSide">
            <p className="title">
                <Skeleton width={"100%"}></Skeleton></p>
            <div style={{textAlign:"start",display:"flex"}}>
                <span style={{width:"10%"}}><Skeleton width={"80%"} style></Skeleton></span>
                 <span style={{width:"10%"}}><Skeleton width={"80%"} ></Skeleton></span></div>
          </div>
          <div className="rightSide">
            <a href="#" style={{width:"40%"}}> <Skeleton width={"100%"} ></Skeleton>
            </a>
            <a href="#" style={{width:"40%"}}><Skeleton width={"100%"}></Skeleton>
            </a>
          </div>
        </li>
        <li>
          <div className="leftSide">
            <p className="title">
                <Skeleton width={"100%"}></Skeleton></p>
            <div style={{textAlign:"start",display:"flex"}}>
                <span style={{width:"10%"}}><Skeleton width={"80%"} style></Skeleton></span>
                 <span style={{width:"10%"}}><Skeleton width={"80%"} ></Skeleton></span></div>
          </div>
          <div className="rightSide">
            <a href="#" style={{width:"40%"}}> <Skeleton width={"100%"} ></Skeleton>
            </a>
            <a href="#" style={{width:"40%"}}><Skeleton width={"100%"}></Skeleton>
            </a>
          </div>
        </li>
        <li>
          <div className="leftSide">
            <p className="title">
                <Skeleton width={"100%"}></Skeleton></p>
            <div style={{textAlign:"start",display:"flex"}}>
                <span style={{width:"10%"}}><Skeleton width={"80%"} style></Skeleton></span>
                 <span style={{width:"10%"}}><Skeleton width={"80%"} ></Skeleton></span></div>
          </div>
          <div className="rightSide">
            <a href="#" style={{width:"40%"}}> <Skeleton width={"100%"} ></Skeleton>
            </a>
            <a href="#" style={{width:"40%"}}><Skeleton width={"100%"}></Skeleton>
            </a>
          </div>
        </li>
        <li>
          <div className="leftSide">
            <p className="title">
                <Skeleton width={"100%"}></Skeleton></p>
            <div style={{textAlign:"start",display:"flex"}}>
                <span style={{width:"10%"}}><Skeleton width={"80%"} style></Skeleton></span>
                 <span style={{width:"10%"}}><Skeleton width={"80%"} ></Skeleton></span></div>
          </div>
          <div className="rightSide">
            <a href="#" style={{width:"40%"}}> <Skeleton width={"100%"} ></Skeleton>
            </a>
            <a href="#" style={{width:"40%"}}><Skeleton width={"100%"}></Skeleton>
            </a>
          </div>
        </li>
      </ul>
     
    </div>
  )
}
