import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../ContextAPI/AppProvider'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper'

export const PublicationCard = (props) => {
  let navigate = useNavigate();
  const { translation,dirimart } = useContext(AppContext);
  return (
    <Link to={(dirimart.langId===1033?"/en":"/tr")+`/publications/${removeSpecialCharactersAndWhiteSpace(props.publication.title)}/${props.publication.seoId}`}>
    <div className="publicationCard " >
      <div className="imgDiv">
        <img src={props.publication.imageUrl} alt="publication" />
      </div>
      <h3>{props.publication.title}</h3>
      <p>{props.publication.summary}</p>
    </div>
    </Link>
  )
}


