import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'



export const FairSliderSkeleton = () => {
  return (
    <div className="fairs">
        <div className="fairCard" style={{width:"635px",height:"240px"}}>
            <div style={{width:"100%"}}>
            <Skeleton  width={"100%"} height={"100%"} style={{borderRadius:0,lineHeight:"unset"}}></Skeleton>

            </div>
          <div className="rightSide">
            <p className="title"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <p className="date"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <a href="#" className="details"> <Skeleton width={"100%"} height={"100%"}></Skeleton></a>
          </div>
        </div>
        <div className="fairCard" style={{width:"635px",height:"240px"}}>
            <div style={{width:"100%"}}>
            <Skeleton  width={"100%"} height={"100%"} style={{borderRadius:0,lineHeight:"unset"}}></Skeleton>

            </div>
          <div className="rightSide">
            <p className="title"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <p className="date"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <a href="#" className="details"> <Skeleton width={"100%"} height={"100%"}></Skeleton></a>
          </div>
        </div>
        <div className="fairCard" style={{width:"635px",height:"240px"}}>
            <div style={{width:"100%"}}>
            <Skeleton  width={"100%"} height={"100%"} style={{borderRadius:0,lineHeight:"unset"}}></Skeleton>

            </div>
          <div className="rightSide">
            <p className="title"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <p className="date"> <Skeleton width={"100%"} height={"100%"}></Skeleton></p>
            <a href="#" className="details"> <Skeleton width={"100%"} height={"100%"}></Skeleton></a>
          </div>
        </div>
        
      </div>
  )
}

