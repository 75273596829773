import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const ArtistSkeleton = () => {
  return (
    <div className="artistResults">
                        <div className="artistsList">
                            <div className="artistsCard">
                                <Skeleton height={389} width={389} />
                                <Skeleton height={19} width={389} />
                            </div>
                            <div className="artistsCard">
                                <Skeleton height={389} width={389} />
                                <Skeleton height={19} width={389} />
                            </div>
                            <div className="artistsCard">
                                <Skeleton height={389} width={389} />
                                <Skeleton height={19} width={389} />
                            </div>
                        </div>
                    </div>
  )
}

