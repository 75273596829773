import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import App from "./App";

import { ArtistDetail } from "./containers/Artist/ArtistDetail";
import { ArtistList } from "./containers/Artist/ArtistList";
import { Exhibitions } from "./containers/Exhibitions";
import { ExhibitionDetail } from "./containers/Exhibitions/ExhibitionDetail";
import { MainPage } from "./containers/MainPage";
import { Index as About } from "./containers/About/Index";
import { FairList } from "./containers/Fair/FairList";
import { FairDetail } from "./containers/Fair/FairDetail";
import { NewsList } from "./containers/News/NewsList";
import { NewsDetail } from "./containers/News/NewsDetail";
import { RelatedNewsList } from "./containers/News/RelatedNewsList";
import { SearchResult } from "./containers/Search/SearchResult";
import { PublicationList } from "./containers/Publication/PublicationList";
import { PublicationDetail } from "./containers/Publication/PublicationDetail";
import ScrollToTop from "./Helpers/scrolltoTop";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const AppWrapper = () => {
  return (
    <div className="appFlex">
      <Header></Header>
      <ScrollToTop />
      <Outlet />
      <Footer></Footer>
    </div>
  );
};
