import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
export const ExhibitionSkeleton = () => {
  return (
    <div className="exhibitionsContainer artistDetail">
    <div className="exhibitions exhibitionsList">
        <div className="exhibitionCard searchCard">
            <div className="image">
                <Skeleton width={513} height={280}></Skeleton>
            </div>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }} ></Skeleton>
            <Skeleton width={120} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={18}></Skeleton>
        </div>
        <div className="exhibitionCard searchCard">
            <div className="image">
                <Skeleton width={513} height={280}></Skeleton>
            </div>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }} ></Skeleton>
            <Skeleton width={120} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={18}></Skeleton>
        </div>
        <div className="exhibitionCard searchCard">
            <div className="image">
                <Skeleton width={513} height={280}></Skeleton>
            </div>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={28} style={{ marginBottom: "0.5rem" }} ></Skeleton>
            <Skeleton width={120} height={20} style={{ marginBottom: "0.5rem" }}></Skeleton>
            <Skeleton width={280} height={18}></Skeleton>
        </div>
    </div>
</div>
  )
}

