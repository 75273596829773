import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const NewsListSkeleton = () => {
  return (
    <div className="artistlistskeleton">
      <div className="news-card" style={{ backgroundColor: "unset" }}>
        <div>
          <Skeleton
            width={"100%"}
            height={300}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </div>
        <h4>
          <Skeleton
            width={"50%"}
            height={20}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </h4>
        <p>
          <Skeleton
            width={"100%"}
            height={12}
            style={{ marginBottom: "0.1rem" }}
            count={3}
          ></Skeleton>
        </p>
      </div>
      <div className="news-card" style={{ backgroundColor: "unset" }}>
        <div>
          <Skeleton
            width={"100%"}
            height={300}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </div>
        <h4>
          <Skeleton
            width={"50%"}
            height={20}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </h4>
        <p>
          <Skeleton
            width={"100%"}
            height={12}
            style={{ marginBottom: "0.1rem" }}
            count={3}
          ></Skeleton>
        </p>
      </div>
      <div className="news-card" style={{ backgroundColor: "unset" }}>
        <div>
          <Skeleton
            width={"100%"}
            height={300}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </div>
        <h4>
          <Skeleton
            width={"50%"}
            height={20}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </h4>
        <p>
          <Skeleton
            width={"100%"}
            height={12}
            style={{ marginBottom: "0.1rem" }}
            count={3}
          ></Skeleton>
        </p>
      </div>
    </div>
  );
};
