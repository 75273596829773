import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../ContextAPI/AppProvider'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper'

export const HighlightedPublicationCard = (props) => {
  let navigate=useNavigate();
  const { translation,dirimart } = useContext(AppContext);
  return (
    <Link to={(dirimart.langId===1033?"/en":"/tr")+`/publications/${removeSpecialCharactersAndWhiteSpace(props.publication.title)}/${props.publication.seoId}`}>
     <div className="newsCard shopCard yellowBg">
    <img src={props.publication.imageUrl}  alt="shopping" style={{height:"100%",objectFit:"cover"}}/>
    <div className="rightSide">
      <p className="title">
        {props.publication.title}
      </p>
      <p className="description">
       {props.publication.summary}
      </p>
    </div>
  </div>
    </Link>
   
  )
}

