import React, { useEffect } from "react";
import { Summary } from "./Summary";
import { PhotoAndSocial } from "./PhotoAndSocial";

export const Biography = (props) => {
  useEffect(() => {}, []);
  return (
    <div id="biography" className="biography" style={props.style}>
      <div style={{ overflow: "hidden" }}>
        <Summary
          fullName={props.artist.fullName}
          summary={props.artist.summary}
          cvUrl={props.artist.cvUrl}
        />
        <PhotoAndSocial
          imageUri={props.artist.imageUrl}
          facebookUrl={props.artist.facebookUrl}
          twitterUrl={props.artist.twitterUrl}
          instagramUrl={props.artist.instagramUrl}
        />
      </div>
    </div>
  );
};
