import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import { HighlightedPublicationSlider } from "./HighlightedPublicationSlider";
import { PublicationCard } from "./PublicationCard";
import { baseApiURL } from "../../Helpers/Constant";
import { splitArray, splitArrayByArrayLength } from "../../Helpers/Helper";
import { PromotedPublicationCard } from "./PromotedPublicationCard";

import axios from "axios";

export const PublicationList = () => {
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [publications, setPublications] = useState([]);
  const [promotedPublication, setPromotedPublication] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [publicationsTotalCount, setPublicationsTotalCount] = useState(0);
  const [publicationsMaxPage, setPublicationsMaxPage] = useState(1);
  const [publicationRecordCount, setPublicationRecordCount] = useState(16);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const _getPublications = () => {
    const getPublicationsUrl =
      baseApiURL + "/publications/getNPublishedPublication";
    const data = {
      langId: dirimart.langId,
      recordCount: publicationRecordCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getPublicationsUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _getPublicationsTotalCount = () => {
    const getPublicationsUrl =
      baseApiURL + "/publications/getNPublishedPublicationTotalCount";

    var config = {
      method: "get",
      url: getPublicationsUrl,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getPromotedPublication = () => {
    const getPublicationsUrl =
      baseApiURL + "/publications/getNPublishedPromotedPublication";
    const data = { langId: dirimart.langId, pageNumber: 1 };
    var config = {
      method: "post",
      url: getPublicationsUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getPromotedPublicationsTotalCount = () => {
    const getPublicationsUrl =
      baseApiURL + "/publications/getNPublishedPromotedPublicationTotalCount";

    var config = {
      method: "get",
      url: getPublicationsUrl,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _showMore = () => {
    let nextPage = currentPage + 1;
    if (nextPage <= publicationsMaxPage) {
      setCurrentPage(nextPage);
    }
    return;
  };
  const _prepare = () => {
    _getPublications().then((response) => {
      var splitted = splitArrayByArrayLength(response.publications, 8);

      if (currentPage !== 1) setPublications(publications.concat(splitted));
      else {
        setPublications(splitted);
      }
    });
    _getPublicationsTotalCount().then((response) => {
      setPublicationsTotalCount(response);
      var maxPage = Math.ceil(response / 16);

      if (currentPage < maxPage) {
        setShowMore(true);
      }
      setPublicationsMaxPage(maxPage);
    });
    _getPromotedPublication().then((response) => {
      setPromotedPublication(response.publication);
    });

    // _getPromotedPublicationsTotalCount().then(response => {
    //   setPromotedPublicationTotalCount(response);
    //
    //   var maxPage = Math.ceil(response / counter+1);
    //   if (maxPage > counter) {
    //     setShowMore(true);
    //   }
    //   setPromotedPublicationMaxPage(maxPage);
    // })
    if (currentPage >= publicationsMaxPage) setShowMore(false);
  };
  useEffect(() => {
    setIsInitialLoad(false);
    _prepare();
  }, []);
  useEffect(() => {
    if (isInitialLoad) return;
    _prepare();
  }, [currentPage]);
  useEffect(() => {
    if (isInitialLoad) return;

    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    _prepare();
  }, [dirimart.langId]);
  return (
    <div>
      <HighlightedPublicationSlider
        recordCount={2}
        getPublicationsUrl={"/publications/getNPublishedHighlightedPublication"}
        getPublicationsTotalCountUrl={
          "/publications/getNPublishedHighlightedPublicationTotalCount"
        }
        // customRedirectButton={true}
        // redirectText={translation.Publication.GoShop}
      />
      <div id="publicationsContainer" className="publicationsContainer">
        {publications[0] &&
          publications.map((publicationArray, index) => {
            return (
              <>
                <div className="publications">
                  {publicationArray.map((publication, index) => {
                    return (
                      <>
                        <PublicationCard
                          key={index}
                          publication={publication}
                        />
                      </>
                    );
                  })}
                </div>
                {promotedPublication && index === 0 && (
                  <PromotedPublicationCard publication={promotedPublication} />
                )}
              </>
            );
          })}
        <button
          className="btnWhite smallBtn"
          disabled={!showMore}
          onClick={() => {
            _showMore();
          }}
        >
          {translation.Common.loadmore}
        </button>
      </div>
    </div>
  );
};
