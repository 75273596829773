import Compress from "react-image-file-resizer";
export const _htmlToPlaintext = (html) => {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}
export const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  export const checkSwiperPrevNextArrows=(swiper)=>{
    let obj={};
    if(swiper.isEnd && swiper.isBeginning==false){
            obj.prev=true;
            obj.next=false;
    }
    else if(swiper.isBeginning && swiper.isEnd==false){
        obj.prev=false;
        obj.next=true;
    }
    else if(swiper.isBeginning==false&&swiper.isEnd==false)
    {
        obj.prev=true;
        obj.next=true;
    }else if(swiper.isBeginning&&swiper.isEnd)
    {
        obj.prev=false;
        obj.next=false;
    }
    
    return obj;
  }


  export const splitArray=(flatArray, numCols)=>{

    const newArray = []
    for (let c = 0; c < numCols; c++) {
      newArray.push([])
    }
    // if(flatArray.length/numCols<1)
    // {
    //   newArray.push([])
    // }
    // else{
      
    // }
  
    for (let i = 0; i < flatArray.length; i++) {
      const mod = i % numCols
      newArray[mod].push(flatArray[i])
    }
    return newArray
  }

  export const splitArrayByArrayLength=(flatArray,numMembers)=>{
    var dvided=Math.ceil(flatArray.length/numMembers);
    let arr=[];

    for (let index = 0; index <dvided; index++) {
  
      arr.push(flatArray.slice(index*numMembers,(numMembers)*(index+1)))
    }
 
  return arr;
    // let arr2 = [];
    // let counter = 0;
    // for (let index = 0; index < flatArray.length; index++) {
    //   counter++;
    //   if (counter <= numMembers) {
    //     arr.push(flatArray[index]);
    //     if (counter == numMembers) {
    //       arr2.push(arr);
    //       arr = [];
    //       counter = 0;
    //     }
    //   }

    //   if ((index + 1) === flatArray.length) {
    //     arr2.push(arr);
    //     arr = [];
    //     counter = 0;
        
    //   }
    // }
    // return arr2;
  }

  export const disableLangButton=(dirimart)=>{
 
    const dirimartdata={...dirimart};
    dirimartdata.langButtonDisabled=true;
    return(dirimartdata);
  }
  export const enableLangButton=(dirimart)=>{
   
    const dirimartdata={...dirimart};
    dirimartdata.langButtonDisabled=false;
    return(dirimartdata);
  }
export const validateEmail =(email)=>{
  
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
 
}
//TODO: url den base 64 üretip onu render zamanında küçültücez bu kod çalışmıyor ama.
export const getBase64Image=(img)=> {
  
  var url;
  var image=new Image();
  image.src=img;
  var canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0);
  var dataURL = canvas.toDataURL("image/jpg");
  Compress.imageFileResizer(
    dataURL.replace(/^data:image\/?[A-z]*;base64,/), // the file from input
    480, // width
    480, // height
    "JPEG", // compress format WEBP, JPEG, PNG
    70, // quality
    0, // rotation
    (uri) => {
     url=uri;
    },
    "base64" // blob or base64 default base64
  );
  return url;
}

export const removeSpecialCharactersAndWhiteSpace=(text)=>{
  text=text.replace("ğ","g");
  text=text.replace("ü","u");
  text=text.replace("ş","s");
  text=text.replace("ö","o");
  text=text.replace("ç","c");
  text=text.replace("Ğ","G");
  text=text.replace("Ü","U");
  text=text.replace("Ş","S");
  text=text.replace("İ","I");
  text=text.replace("Ö","O");
  text=text.replace("Ç","C");
  text=text.replace("ı","i");
  var newText= text.replace(/[^\w ]/g, '');
  return newText.replace(/ /g,'-');
}
