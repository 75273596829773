import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, Manipulation } from "swiper";
import { FairCard } from "./FairCard";
import { AppContext } from "..//../ContextAPI/AppProvider";
import { baseApiURL, fairCount, lottieOption } from "../../Helpers/Constant";
import { FairSliderSkeleton } from "../../components/Skeletons/Fairs/FairSliderSkeleton";
import Lottie from "react-lottie";
import axios from "axios";
import BlockUI from "react-block-ui";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-block-ui/style.css";

export const Fair = () => {
  let navigate = useNavigate();
  const [fairs, setFairs] = useState([]);
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [maxActiveIndex, setMaxActiveIndex] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [totalFairCount, setTotalFairCount] = useState(1);
  const [currentPage, setcurrentPage] = useState(1);
  const [blockUi, setBlockUi] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  SwiperCore.use([Pagination, Navigation, Manipulation]);

  const _getFairs = async (count) => {
    const getFormDataUrl = baseApiURL + "/fairs/getPublishedFairs";
    const data = {
      langId: dirimart.langId,
      recordCount: count + 2,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedFairs))
        .catch((e) => reject(e.data.ExceptionMessage));
    });
  };
  const _getFairMaxPage = () => {
    const getFormDataUrl = baseApiURL + "/fairs/getFairTotalCount";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.data.ExceptionMessage));
    });
  };
  const _slideChange = (e) => {
    let prevPage = currentPage;
    let nextPage = currentPage + 1;
    if (e.swipeDirection == "next") {
      if (prevPage < nextPage) {
        if (nextPage > maxPageNumber) {
          return;
        }
        swiperRef.slideTo(e.activeIndex);
        setcurrentPage(nextPage);
        return;
      }
    } else {
      swiperRef.slideTo(e.activeIndex);
    }
    //sliderda ilk sayfada 2 kayıt sonraki sayfalarda bir kayıt aldığımızdan aynı kaydı bir kez daha almamaktan
    // if (isMobile) {
    //     setActiveIndex(e.activeIndex + 1)
    //     return;
    // }
    // if (e.activeIndex > maxActiveIndex && maxActiveIndex !== 0) {
    //     swiperRef.slideTo(maxActiveIndex)
    //     return
    // }
    // setActiveIndex(swiperRef.activeIndex)
  };
  useEffect(() => {
    if (isInitialLoad) return;
    // if (swiperRef?.activeIndex === 0 || swiperRef?.activeIndex === undefined) { return }
    // if (swiperRef?.activeIndex >= maxActiveIndex && maxActiveIndex !== 0) {
    //     swiperRef.slideTo(maxActiveIndex)
    //     return
    // }
    // if (maxActiveIndex > swiperRef?.activeIndex) {
    //     return;
    // }

    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      setFairs(fairs.concat(response));
      setBlockUi(false);
      setIsLoading(false);

      // response.forEach((element, index) => {
      //     let h = renderToString(<FairCard item={element} translation={translation}></FairCard>)
      //     swiperRef.appendSlide(
      //         '<div className="swiper-slide">' + h + '</div>'
      //     )
      // })
    });
  }, [currentPage]);
  useEffect(() => {}, [swiperRef]);
  useEffect(() => {
    setBlockUi(true);
    let dirimartcopy = { ...dirimart };
    dirimartcopy.langButtonDisabled = true;
    setDirimartData(dirimartcopy);

    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      setFairs(response);

      dirimartcopy.langButtonDisabled = false;
      setDirimartData(dirimartcopy);
    });
    _getFairMaxPage().then((response) => {
      setTotalFairCount(response);
      let maxPage = Math.ceil(response / fairCount);
      setMaxPageNumber(maxPage);
      setBlockUi(false);
      setIsLoading(false);
    });
  }, [dirimart.langId]);
  useEffect(() => {
    setIsInitialLoad(false);
    _getFairs(isMobile ? 1 : fairCount).then((response) => {
      setFairs(response);
    });
    _getFairMaxPage().then((response) => {
      setTotalFairCount(response);
      let maxPage = Math.ceil(response / fairCount);
      setMaxPageNumber(maxPage);
      setIsLoading(false);
      setBlockUi(false);
    });
  }, []);

  return (
    <>
      <div className="fairsContainer">
        <h2>{translation.Fair.Title}</h2>
        {isLoading && <FairSliderSkeleton></FairSliderSkeleton>}
        {!isLoading && (
          <>
            <div className="fairs">
              <Swiper
                // slidesPerView={isMobile ? 1 : totalFairCount <= 3 ? totalFairCount : 2.5}
                spaceBetween={10}
                onSwiper={setSwiperRef}
                onSlideChange={(e) => _slideChange(e)}
                breakpoints={{
                  640: {
                    // width: 640,
                    slidesPerView: 1,
                  },
                  // when window width is >= 768px
                  768: {
                    // width: 768,
                    slidesPerView: totalFairCount > 1 ? 1.4 : 1,
                  },
                  1366: {
                    slidesPerView: totalFairCount > 1 ? 2.4 : 1,
                  },
                  1920: {
                    slidesPerView: totalFairCount > 1 ? 2.4 : 1,
                  },
                }}
                // rebuildOnUpdate={true}
                // slidesPerView={isMobile ? 1 : "auto"}
              >
                {fairs.map(function (item, index) {
                  return (
                    <SwiperSlide key={item.fairId}>
                      <FairCard
                        item={item}
                        translation={translation}
                      ></FairCard>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <button
              className="btnWhite"
              style={{ marginTop: "40px" }}
              onClick={() => navigate("/Fairs")}
            >
              {translation.Common?.seemoreFairs}
            </button>
          </>
        )}
      </div>
    </>
  );
};
