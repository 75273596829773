import React, { useState, useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

export const CookieConsentCustom = () => {
  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("DirimartCookieAccepted") === "true"
  );

  const handleAcceptCookie = () => {
    localStorage.setItem("DirimartCookieAccepted", "true");
    setCookieAccepted(true);
  };

  useEffect(() => {
    if (!cookieAccepted) {
    }
  }, [cookieAccepted]);
  return (
    <div>
      {!cookieAccepted && (
        <CookieConsent
          onAccept={handleAcceptCookie}
          location="bottom"
          buttonText="Tüm Çerezleri Kabul Et"
          buttonClasses="acceptButton"
          style={{
            background: "#2B373B",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <div>Çerez Politikası </div>
            <div>
              Bu internet sitesi, ziyaretçilere daha iyi hizmet sunmak ve
              deneyimlerini kişiselleştirmek amacıyla çerezleri kullanır.
              Çerezlerin kullanımını kabul ederek bütün çerezleri kabul etmiş
              olacaksınız.
            </div>
          </div>
        </CookieConsent>
      )}
    </div>
  );
};
