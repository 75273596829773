import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const AboutSkeleton = () => {
  return (
    <>
      <div className="about">
        <h2>
          <Skeleton
            width={250}
            height={43}
            style={{ marginBottom: "0.5rem" }}
          ></Skeleton>
        </h2>
        <p>
          <Skeleton
            width={"100%"}
            height={15}
            style={{ marginBottom: "0.2rem" }}
            count={12}
          ></Skeleton>
        </p>
      </div>
    </>
  );
};
