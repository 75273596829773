import { MainPage } from "./containers/MainPage";
import { Route, Routes, useLocation } from "react-router-dom";
import { ArtistDetail } from "./containers/Artist/ArtistDetail";
import { ArtistList } from "./containers/Artist/ArtistList";
import { Exhibitions } from "./containers/Exhibitions";
import { ExhibitionDetail } from "./containers/Exhibitions/ExhibitionDetail";
import { Index as About } from "./containers/About/Index";
import { FairList } from "./containers/Fair/FairList";
import { FairDetail } from "./containers/Fair/FairDetail";
import { NewsList } from "./containers/News/NewsList";
import { NewsDetail } from "./containers/News/NewsDetail";
import { RelatedNewsList } from "./containers/News/RelatedNewsList";
import { SearchResult } from "./containers/Search/SearchResult";
import { PublicationList } from "./containers/Publication/PublicationList";
import { PublicationDetail } from "./containers/Publication/PublicationDetail";
import { Index as QrIndex } from "./containers/Qr/Index";
import { AppWrapper } from "./AppWrapper";
import { Index } from "./containers/Shop/Index";
import { Index as ShopDetail } from "./containers/Shop/ShopDetail/Index";
import { Index as ShopNew } from "./containers/Shop/ShopNews/Index";
import { Index as ShopOurPick } from "./containers/Shop/ShopOurPicks/Index";
import { Index as ShoppingCart } from "./containers/Shop/ShoppingCart/Index";
import { Index as PublicationsByTypeAndCategory } from "./containers/Shop/ShopList/Index";
import JobOpportunities from "./containers/JobOpportunities";
import ReactGA from "react-ga4";
import ScrollToTop from "./Helpers/scrolltoTop";
import ShopWarpper from "./ShopWarpper";
import "./assets/style.css";
import { useEffect } from "react";
import SubscribeNewsletter from "./containers/SubscribeNewsletter/SubscribeNewsletter";

function App() {
  const location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("GT-NNZWFN5");
      window.GA_INITIALIZED = true;
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
  return (
    <>
      <ScrollToTop />

      <Routes>
        {/* <Route path='/Shop' element={<ShopWarpper></ShopWarpper>}>
            <Route index element={<Index></Index>}></Route>
            <Route path='/Shop/Detail/:Id' element={<ShopDetail />} />
            <Route path='/Shop/Cart' element={<ShoppingCart />} />
            <Route path='/Shop/News' element={<ShopNew />} />
            <Route path='/Shop/OurPicks' element={<ShopOurPick />} />
            <Route path='/Shop/:CategoryName/:Category' element={<PublicationsByTypeAndCategory />} />
            <Route path='/Shop/:CategoryName/:Category/:TypeName/:Type' element={<PublicationsByTypeAndCategory />} />
          </Route> */}
        <Route
          path="/Subscribe-Newsletter"
          element={<SubscribeNewsletter />}
        ></Route>
        <Route
          path="/:lang/Subscribe-Newsletter"
          element={<SubscribeNewsletter />}
        ></Route>
        <Route path="" element={<AppWrapper></AppWrapper>}>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/:lang" element={<MainPage />}></Route>
          <Route path="/Artists" element={<ArtistList />} />
          <Route path="/:lang/Artists" element={<ArtistList />} />
          <Route path="/qrpera" element={<QrIndex />}></Route>
          <Route path="/qrdolapdere" element={<QrIndex />}></Route>
          <Route path="/qrfair" element={<QrIndex />}></Route>
          <Route path="/Artists/:ArtistName/:Id" element={<ArtistDetail />} />
          <Route
            path="/:lang/Artists/:ArtistName/:Id"
            element={<ArtistDetail />}
          />
          <Route path="/Exhibitions" element={<Exhibitions />} />
          <Route path="/:lang/Exhibitions" element={<Exhibitions />} />
          <Route path="/Exhibitions/:status" element={<Exhibitions />} />
          <Route path="/:lang/Exhibitions/:status" element={<Exhibitions />} />
          <Route
            path="/Exhibitions/:ExhibitionName/:Id"
            element={<ExhibitionDetail />}
          />
          <Route
            path="/:lang/Exhibitions/:ExhibitionName/:Id"
            element={<ExhibitionDetail />}
          />
          <Route path="/About" element={<About />} />
          <Route path="/:lang/About" element={<About />} />
          <Route path="/Fairs" element={<FairList />} />
          <Route path="/:lang/Fairs" element={<FairList />} />
          <Route path="/Fairs/:FairName/:Id" element={<FairDetail />} />
          <Route path="/:lang/Fairs/:FairName/:Id" element={<FairDetail />} />
          <Route path="/News" element={<NewsList />} />
          <Route path="/:lang/News" element={<NewsList />} />
          <Route path="/News/:NewsName/:Id" element={<NewsDetail />} />
          <Route path="/:lang/News/:NewsName/:Id" element={<NewsDetail />} />
          <Route path="/News/:Id" element={<RelatedNewsList />} />
          <Route path="/:lang/News/:Id" element={<RelatedNewsList />} />
          <Route path="/SearchResult" element={<SearchResult />} />
          <Route path="/Publications" element={<PublicationList />} />
          <Route path="/:lang/Publications" element={<PublicationList />} />
          <Route
            path="/Publications/:PulicationName/:Id"
            element={<PublicationDetail />}
          />
          <Route
            path="/:lang/Publications/:PulicationName/:Id"
            element={<PublicationDetail />}
          />
          <Route
            path="/:lang/Job-Opportunities"
            element={<JobOpportunities />}
          />
          <Route path="/Job-Opportunities" element={<JobOpportunities />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
