import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ArtistDetailSkeleton = () => {
  return (
    <>
      <div className="biography">
        <div className="bioText" style={{justifyContent:"space-between"}}>
          <div className="skeletonArtisDetailLeft">
            <h1>
              <Skeleton
                width={"100%"}
                height={112}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </h1>
            <p>
              <Skeleton
                width={"100%"}
                height={120}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </p>
            <a className="downloadCV">
              <Skeleton
                width={115}
                height={20}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </a>
          </div>
          <div className="skeletonArtisDetailRight">
            <div className="pictureField" style={{width:"100%"}}>
              <Skeleton
                width={"100%"}
                height={444}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </div>
            <a>
              <Skeleton
                width={68}
                height={16}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </a>
            <a>
              <Skeleton
                width={68}
                height={16}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </a>
            <a>
              <Skeleton
                width={68}
                height={16}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </a>
            <a>
              <Skeleton
                width={68}
                height={16}
                style={{ marginBottom: "0.5rem" }}
              ></Skeleton>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
