import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { AppContext } from "..//../ContextAPI/AppProvider";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { ExhibitionsListSkeleton } from "../../components/Skeletons/Exhibitions/ExhibitionsListSkeleton";
import {
  baseApiURL,
  exhibitionCount,
  exhibitionStatus,
} from "../../Helpers/Constant";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";

export const Exhibitions = () => {
  const params = useParams();
  const location = useLocation();
  const [isExhibitionPage, setIsExhibitionPage] = useState(false);
  const [exhibitions, setExhibitions] = useState([]);
  const { dirimart, translation } = useContext(AppContext);
  const [selectedExhibitionStatus, setSelectedExhibitionStatus] = useState(
    exhibitionStatus.current
  );
  const [previusExhibitionStatus, setPreviusExhibitionStatus] = useState(
    exhibitionStatus.all
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageCount, setMaxPageCount] = useState(1);
  const [totalCount, settotalCount] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate();
  const _getExhibitions = async () => {
    const getFormDataUrl = baseApiURL + "/Exhibition/getPublishedExhibitions";
    const data = {
      langId: dirimart.langId,
      recordCount: exhibitionCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedExhibitions))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getUpcomingExhibitions = async () => {
    const getFormDataUrl =
      baseApiURL + "/Exhibition/getPublishedUpcomingExhibitions";
    const data = {
      langId: dirimart.langId,
      recordCount: exhibitionCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedExhibitions))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getCurrentExhibitions = async () => {
    const getFormDataUrl =
      baseApiURL + "/Exhibition/getPublishedCurrentExhibitions";
    const data = {
      langId: dirimart.langId,
      recordCount: exhibitionCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedExhibitions))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getPastExhibitions = async () => {
    const getFormDataUrl =
      baseApiURL + "/Exhibition/getPublishedPastExhibitions";
    const data = {
      langId: dirimart.langId,
      recordCount: exhibitionCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedExhibitions))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getOnlineExhibitions = async () => {
    const getFormDataUrl =
      baseApiURL + "/Exhibition/getPublishedOnlineExhibitions";
    const data = {
      langId: dirimart.langId,
      recordCount: exhibitionCount,
      pageNumber: currentPage,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.publishedExhibitions))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getTotalCount = async (param) => {
    const getFormDataUrl =
      baseApiURL + "/Exhibition/getPublishedExhibitionsTotalCountByType";
    const data = {
      langId: dirimart.langId,
      type:
        param !== undefined
          ? exhibitionStatus[param]
          : selectedExhibitionStatus,
    };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _getExhibitionData = (previus) => {
    switch (selectedExhibitionStatus) {
      case exhibitionStatus.upcoming:
        _getUpcomingExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      case exhibitionStatus.current:
        _getCurrentExhibitions().then((response) => {
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      case exhibitionStatus.past:
        _getPastExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      case exhibitionStatus.current:
        _getCurrentExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      case exhibitionStatus.online:
        _getOnlineExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      case exhibitionStatus.all:
        _getExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }
          setExhibitions(response);
          setIsLoading(false);
        });

        break;
      default:
        _getExhibitions().then((response) => {
          if (selectedExhibitionStatus === previus) {
            setExhibitions(exhibitions.concat(response));
            setIsLoading(false);
            return;
          }

          setExhibitions(response);
          setIsLoading(false);
        });

        break;
    }
  };
  const _changeExhibition = (status) => {
    setCurrentPage(1);
    setShowMore(false);
    if (status == selectedExhibitionStatus) {
      return;
    }
    setPreviusExhibitionStatus(status);
    switch (status) {
      case exhibitionStatus.all:
        setSelectedExhibitionStatus(exhibitionStatus.all);
        break;
      case exhibitionStatus.current:
        setSelectedExhibitionStatus(exhibitionStatus.current);
        break;
      case exhibitionStatus.past:
        setSelectedExhibitionStatus(exhibitionStatus.past);
        break;
      case exhibitionStatus.upcoming:
        setSelectedExhibitionStatus(exhibitionStatus.upcoming);
        break;
      case exhibitionStatus.online:
        setSelectedExhibitionStatus(exhibitionStatus.online);
        break;
      default:
        setSelectedExhibitionStatus(exhibitionStatus.all);
        break;
    }
  };
  const _showMore = () => {
    let nextPage = currentPage + 1;

    if (nextPage <= maxPageCount) {
      setIsLoading(true);
      setCurrentPage(nextPage);
    }
  };

  useEffect(() => {
    let statusParam = params.status;

    setSelectedExhibitionStatus(
      statusParam == undefined
        ? exhibitionStatus.current
        : exhibitionStatus[statusParam]
    );

    if (
      location.pathname === "/Exhibitions" ||
      location.pathname === "/en/Exhibitions" ||
      location.pathname === "/tr/Exhibitions"
    ) {
      setIsExhibitionPage(true);
    }
    setInitialRender(false);
    _getExhibitionData();
    _getTotalCount(statusParam).then((response) => {
      settotalCount(response);
      var maxPage = Math.ceil(response / exhibitionCount);
      setMaxPageCount(maxPage);
      if (currentPage < maxPage) setShowMore(true);
    });
  }, []);

  useEffect(() => {
    if (initialRender) {
      return;
    }
    setExhibitions([]);
    setIsLoading(true);
    let statusParam = params.status;
    if (currentPage !== 1) {
      setCurrentPage(1);

      return;
    }
    _getExhibitionData();
    _getTotalCount(statusParam).then((response) => {
      settotalCount(response);
      var maxPage = Math.ceil(response / exhibitionCount);
      setMaxPageCount(maxPage);
      if (currentPage < maxPage) setShowMore(true);
      else setShowMore(false);
    });
  }, [dirimart.langId]);
  useEffect(() => {
    if (initialRender) return;
    setExhibitions([]);
    setIsLoading(true);
    _getExhibitionData();
    _getTotalCount().then((response) => {
      settotalCount(response);
      var maxPage = Math.ceil(response / exhibitionCount);
      setMaxPageCount(maxPage);
      if (maxPage > currentPage) setShowMore(true);
    });
  }, [selectedExhibitionStatus]);
  useEffect(() => {
    if (initialRender) {
      return;
    }
    if (currentPage < maxPageCount) {
      setShowMore(true);
    }
    if (currentPage !== 1) {
      _getExhibitionData(previusExhibitionStatus);
      if (currentPage >= maxPageCount) {
        setShowMore(false);
      }
      return;
    }
    _getExhibitionData();
  }, [currentPage]);

  return (
    <>
      <div className="exhibitionsContainer">
        <>
          <div>
            <h2 className={isExhibitionPage ? "" : "main-exhibition"}>
              {translation.Exhibition.Title}
            </h2>
            {isExhibitionPage && (
              <div className="buttons exhibitionNavButton">
                <a
                  className={
                    selectedExhibitionStatus === exhibitionStatus.current
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => _changeExhibition(exhibitionStatus.current)}
                >
                  {translation.Exhibition.current}
                </a>
                <a
                  className={
                    selectedExhibitionStatus === exhibitionStatus.upcoming
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => _changeExhibition(exhibitionStatus.upcoming)}
                >
                  {translation.Exhibition.upcoming}
                </a>
                <a
                  className={
                    selectedExhibitionStatus === exhibitionStatus.past
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => _changeExhibition(exhibitionStatus.past)}
                >
                  {translation.Exhibition.past}
                </a>
                <a
                  className={
                    selectedExhibitionStatus === exhibitionStatus.online
                      ? "btnYellow"
                      : "btnWhite"
                  }
                  onClick={() => _changeExhibition(exhibitionStatus.online)}
                >
                  {translation.Exhibition.online}
                </a>
              </div>
            )}
            {isLoading && (
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ExhibitionsListSkeleton></ExhibitionsListSkeleton>
              </div>
            )}
            <div className="exhibitions">
              {exhibitions &&
                exhibitions.map(function (item, index) {
                  return (
                    <Link
                      to={
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                        "/Exhibitions/" +
                        removeSpecialCharactersAndWhiteSpace(item.title1) +
                        "/" +
                        item.exhibitionSeoId
                      }
                    >
                      <div key={item.exhibitionId} className="exhibitionCard">
                        <div className="image">
                          <img src={item.imageUrl} alt="exhibition"></img>
                        </div>
                        <p>
                          {item.mainTitle}
                          <br />
                          {item.title1}
                        </p>
                        <span className="date">
                          {item.exhibitionDatesTitle}
                        </span>
                        <span className="readMore">
                          {translation.Exhibition.readmore}
                        </span>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          {showMore && (
            <button
              className="btnWhite"
              onClick={() => _showMore()}
              disabled={!showMore}
            >
              {translation.Common.seemoreExhibitions}
            </button>
          )}
        </>
      </div>
    </>
  );
};
