import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../ContextAPI/AppProvider";
import { baseApiURL, lottieOption } from "../../Helpers/Constant";
import {
  splitArray,
  disableLangButton,
  enableLangButton,
} from "../../Helpers/Helper";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Lottie from "react-lottie";
import HashLoader from "react-spinners/HashLoader";
import BlockUI from "react-block-ui";
import Masonry from "react-masonry-css";
import "react-block-ui/style.css";
import { ArtistListSkeleton } from "../../components/Skeletons/Artists/ArtistListSkeleton";

export const ArtistList = () => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };
  const [artists, setArtists] = useState([]);
  const [highlightedArtists, setHighlightedArtists] = useState([]);
  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [blockUi, setBlockUi] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const count = 4;
  const _getArtists = async () => {
    const getFormDataUrl = baseApiURL + "/Artist/getPublishedArtists";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _prepare = async () => {
    setBlockUi(true);

    setDirimartData(disableLangButton(dirimart));
    _getArtists().then((response) => {
      const sortedArtist = response.artists.sort((a, b) => {
        if (
          a.lastName !== null &&
          a.lastName !== "" &&
          b.lastName !== null &&
          b.lastName !== ""
        ) {
          return a.lastName.localeCompare(b.lastName);
        }
        if (!a.lastName || a.lastName === "") {
          if (!b.lastName || b.lastName === "") {
            return a.name.localeCompare(b.name);
          }
          return a.name.localeCompare(b.lastName);
        }
        return a.lastName.localeCompare(b.name);
      });
      if (isMobile) {
        setArtists(splitArray(sortedArtist, 2));
      } else {
        setArtists(splitArray(sortedArtist, 4));
      }
      setDirimartData(enableLangButton(dirimart));
      const sortedList = response.highlightedArtists.sort(
        () => Math.random() - 0.5
      );
      setHighlightedArtists(sortedList);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    _prepare();
  }, []);
  useEffect(() => {
    _prepare();
  }, [dirimart.langId]);

  return (
    <div className="artistsContainer">
      <h1>{translation.Artist.Title}</h1>
      <ul className="namesDiv">
        {artists.map((artistArrays, index) => {
          return (
            <div
              key={index}
              className={
                isMobile ? "namesColFor2Columns" : "namesColFor3Columns"
              }
            >
              {artistArrays.map((artist, index) => {
                let artistName = artist.name + "_" + artist.lastName;
                return (
                  <li key={artist.name + index}>
                    <Link
                      className="nameOfArtist"
                      to={
                        (dirimart.langId === 1033 ? "/en" : "") +
                        `/Artists/${removeSpecialCharactersAndWhiteSpace(
                          artistName
                        )}/${artist.seoId}`
                      }
                    >
                      {artist.name} {artist.lastName}
                    </Link>

                    <img src={artist.imageUrl} alt={artist.name} />
                  </li>
                );
              })}
            </div>
          );
        })}
      </ul>
      <div className="artists" style={{ minHeight: "300px" }}>
        {isLoading && <ArtistListSkeleton></ArtistListSkeleton>}
        <Masonry
          breakpointCols={
            highlightedArtists.length > 1 ? breakpointColumnsObj : 1
          }
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {highlightedArtists &&
            highlightedArtists.map((artist, index) => {
              let artistName = artist.name + "_" + artist.lastName;
              return (
                <Link
                  key={artist.name + index}
                  to={
                    (dirimart.langId === 1033 ? "/en" : "") +
                    `/Artists/${removeSpecialCharactersAndWhiteSpace(
                      artistName
                    )}/${artist.seoId}`
                  }
                >
                  <div className="artistCard">
                    <img src={artist.imageUrl} alt="artist" />
                    <span className="artistName">
                      {artist.name} {artist.lastName}
                    </span>
                  </div>
                </Link>
              );
            })}
        </Masonry>
      </div>
    </div>
  );
};
