import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../ContextAPI/AppProvider'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper'
export const PromotedPublicationCard = (props) => {
 
    let navigate=useNavigate();
    const { translation,dirimart } = useContext(AppContext);
  return (
    <div className="bannerContainer publicationBanner">
    <img src={props.publication.imageUrl} alt="publication"/>
    <div className="rightSide">
      <p className="title">{props.publication.title}</p>
      <p className="description">
       {props.publication.summary}
      </p>
      {/* <button className="btnYellow" onClick={()=>navigate(`/Shop/Publication/${props.publication.Id}`)}>{translation.Publication.GoShop}</button> */}
    </div>
  </div>
  )
}


