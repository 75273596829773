import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../ContextAPI/AppProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { baseApiURL, cultures } from "../../Helpers/Constant";
import { useLocalStorage } from "../../Helpers/useLocalStorage";
import { ArtistSkeleton } from "../../components/Skeletons/SearchResult/ArtistSkeleton";
import { ExhibitionSkeleton } from "../../components/Skeletons/SearchResult/ExhibitionSkeleton";
import { NewsSkeleton } from "../../components/Skeletons/SearchResult/NewsSkeleton";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";
import axios from "axios";

export const SearchResult = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const { dirimart, translation, setDirimartData } = useContext(AppContext);
  const [searchResults, setSearchResults] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [searchTextForLocalStorage, setSearchTextForLocalStorage] =
    useLocalStorage("searchText", null);
  const [isLoading, setIsLoading] = useState(true);

  const _quickSearch = () => {
    const getNewsDetailUrl = baseApiURL + "/search/quickSearch";
    const data = {
      langId: dirimart.langId,
      searchText: searchTextForLocalStorage,
    };
    var config = {
      method: "post",
      url: getNewsDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  useEffect(() => {
    setIsInitialLoad(false);

    setSearchResults(location.state);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    if (isInitialLoad) return;
    setSearchResults(null);
    setIsLoading(true);
    _quickSearch().then((response) => {
      setSearchResults(response.quickFounds);
      setIsLoading(false);
    });
  }, [dirimart.langId]);
  return (
    <div className="container">
      <div className="searchPage">
        <h2 className="pageTitle">Search Results</h2>
        {isLoading && <ArtistSkeleton />}
        {searchResults?.artists[0] && (
          <div className="artistResults">
            <div className="titles">
              <p>{translation.Artist.Title} </p>
              <p>({searchResults?.artists.length})</p>
            </div>
            <div className="artistsList">
              {searchResults?.artists.map((artist) => {
                return (
                  <div
                    key={artist.Id}
                    onClick={() =>
                      navigate(
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                          `/Artists/${removeSpecialCharactersAndWhiteSpace(
                            artist.name
                          )}/${artist.seoId}`
                      )
                    }
                    className="artistsCard"
                  >
                    <img src={artist.imageUrl} alt={artist.name} />
                    <p>{artist.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isLoading && <ExhibitionSkeleton />}
        {searchResults?.exhibitions[0] && (
          <div className="exhibitionsContainer artistDetail">
            <div className="titles">
              <p>{translation.Exhibition.Title} </p>
              <p>({searchResults?.exhibitions.length})</p>
            </div>
            <div className="exhibitions exhibitionsList">
              {searchResults?.exhibitions.map((exhibition) => {
                return (
                  <div
                    key={exhibition.Id}
                    onClick={() =>
                      navigate(
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                          `/Exhibitions/${removeSpecialCharactersAndWhiteSpace(
                            exhibition.name
                          )}/${exhibition.seoId}`
                      )
                    }
                    className="exhibitionCard searchCard"
                  >
                    <div className="image">
                      <img src={exhibition.imageUrl} alt="exhibition" />
                    </div>
                    <p className="artistName">{exhibition.mainTitle}</p>
                    <p className="workName">{exhibition.name}</p>
                    <p className="company">{exhibition.locationName}</p>
                    <span className="date">
                      {exhibition.exhibitionDatesTitle}
                    </span>
                    <span className="readMore">
                      {translation.Common.readmore}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {searchResults?.fairs[0] && (
          <div className="fairsResults fairsPageContainer">
            <div className="titles">
              <p>{translation.Fair.Title} </p>
              <p>({searchResults?.fairs.length})</p>
            </div>
            <div className="fairs">
              {searchResults?.fairs.map((fair, index) => {
                return (
                  <div
                    key={fair.name + index}
                    onClick={() =>
                      navigate(
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                          `/Fairs/${removeSpecialCharactersAndWhiteSpace(
                            fair.name
                          )}/${fair.seoId}`
                      )
                    }
                    className="fairCard"
                  >
                    <img src={fair.imageUrl} alt={fair.name} />
                    <div className="info">
                      <p className="title">{fair.name}</p>
                      <p className="description">{fair.summary}</p>
                      <p className="date">{fair.fairDatesTitle}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {searchResults?.publications[0] && (
          <div className="publicationsResults publicationsContainer">
            <div className="titles">
              <p>{translation.Publication.Title} </p>
              <p>({searchResults?.publications.length})</p>
            </div>
            <div className="publications">
              {searchResults?.publications.map((publication) => {
                return (
                  <div
                    key={publication.Id}
                    onClick={() =>
                      navigate(
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                          `/Publications/${removeSpecialCharactersAndWhiteSpace(
                            publication.name
                          )}/${publication.seoId}`
                      )
                    }
                    className="publicationCard"
                  >
                    <div className="imgDiv">
                      <img src={publication.imageUrl} alt="publication" />
                    </div>
                    <h3>{publication.name}</h3>
                    <p>{publication.summary}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isLoading && <NewsSkeleton></NewsSkeleton>}
        {searchResults?.news[0] && (
          <div className="newsResults newsPageContainer">
            <div className="titles">
              <p>{translation.News.Title} </p>
              <p>({searchResults?.news.length})</p>
            </div>
            <div className="news">
              {searchResults?.news.map((news) => {
                return (
                  <div
                    key={news.Id}
                    onClick={() =>
                      navigate(
                        (dirimart.langId === 1033 ? "/en" : "/tr") +
                          `/News/${removeSpecialCharactersAndWhiteSpace(
                            news.name
                          )}/${news.seoId}`
                      )
                    }
                    className="news-card"
                  >
                    <img src={news.imageUrl} alt="news" />
                    <h4>{news.name}</h4>
                    <p>{news.summary}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
