import React from 'react';
import { openInNewTab } from '../../Helpers/Helper';
import { useNavigate } from 'react-router-dom';

export const PhotoAndSocial = (props) => {


  return (
    <>
      <div className="pictureField">
        <div>
          <img src={props.imageUri} alt="artist" width="335px" />
<div style={{display:"flex",gap:"1rem"}}>
        
       

        
          {
            props.facebookUrl !== undefined && props.facebookUrl !== "" &&
            <a href={props.facebookUrl} target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.9531 11.6836C23.9531 5.26172 18.75 0.0585938 12.3281 0.0585938C5.90625 0.0585938 0.703125 5.26172 0.703125 11.6836C0.703125 17.4961 4.92188 22.3242 10.5 23.168V15.0586H7.54688V11.6836H10.5V9.15234C10.5 6.24609 12.2344 4.60547 14.8594 4.60547C16.1719 4.60547 17.4844 4.83984 17.4844 4.83984V7.69922H16.0312C14.5781 7.69922 14.1094 8.58984 14.1094 9.52734V11.6836H17.3438L16.8281 15.0586H14.1094V23.168C19.6875 22.3242 23.9531 17.4961 23.9531 11.6836Z" fill="#25282E" />
              </svg>
            </a>


          }
          {
          props.twitterUrl !== undefined && props.twitterUrl !== "" && 
          <a href={props.twitterUrl} target="_blank">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"
              fill="#25282E"
            />
          </svg>
        </a>
        
          
          }
          {
          props.instagramUrl !== undefined && props.instagramUrl !== "" && 

          <a href={props.instagramUrl} target="_blank">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4766 5.19922C8.47656 5.19922 6.08594 7.63672 6.08594 10.5898C6.08594 13.5898 8.47656 15.9805 11.4766 15.9805C14.4297 15.9805 16.8672 13.5898 16.8672 10.5898C16.8672 7.63672 14.4297 5.19922 11.4766 5.19922ZM11.4766 14.1055C9.55469 14.1055 7.96094 12.5586 7.96094 10.5898C7.96094 8.66797 9.50781 7.12109 11.4766 7.12109C13.3984 7.12109 14.9453 8.66797 14.9453 10.5898C14.9453 12.5586 13.3984 14.1055 11.4766 14.1055ZM18.3203 5.01172C18.3203 4.30859 17.7578 3.74609 17.0547 3.74609C16.3516 3.74609 15.7891 4.30859 15.7891 5.01172C15.7891 5.71484 16.3516 6.27734 17.0547 6.27734C17.7578 6.27734 18.3203 5.71484 18.3203 5.01172ZM21.8828 6.27734C21.7891 4.58984 21.4141 3.08984 20.1953 1.87109C18.9766 0.652344 17.4766 0.277344 15.7891 0.183594C14.0547 0.0898438 8.85156 0.0898438 7.11719 0.183594C5.42969 0.277344 3.97656 0.652344 2.71094 1.87109C1.49219 3.08984 1.11719 4.58984 1.02344 6.27734C0.929688 8.01172 0.929688 13.2148 1.02344 14.9492C1.11719 16.6367 1.49219 18.0898 2.71094 19.3555C3.97656 20.5742 5.42969 20.9492 7.11719 21.043C8.85156 21.1367 14.0547 21.1367 15.7891 21.043C17.4766 20.9492 18.9766 20.5742 20.1953 19.3555C21.4141 18.0898 21.7891 16.6367 21.8828 14.9492C21.9766 13.2148 21.9766 8.01172 21.8828 6.27734ZM19.6328 16.7773C19.3047 17.7148 18.5547 18.418 17.6641 18.793C16.2578 19.3555 12.9766 19.2148 11.4766 19.2148C9.92969 19.2148 6.64844 19.3555 5.28906 18.793C4.35156 18.418 3.64844 17.7148 3.27344 16.7773C2.71094 15.418 2.85156 12.1367 2.85156 10.5898C2.85156 9.08984 2.71094 5.80859 3.27344 4.40234C3.64844 3.51172 4.35156 2.80859 5.28906 2.43359C6.64844 1.87109 9.92969 2.01172 11.4766 2.01172C12.9766 2.01172 16.2578 1.87109 17.6641 2.43359C18.5547 2.76172 19.2578 3.51172 19.6328 4.40234C20.1953 5.80859 20.0547 9.08984 20.0547 10.5898C20.0547 12.1367 20.1953 15.418 19.6328 16.7773Z" fill="#25282E" />
          </svg>
        </a>
         
          }
          </div>
        </div>
      </div>

    </>
  )
}
