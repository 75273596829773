import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../ContextAPI/AppProvider'
import { baseApiURL } from "../../Helpers/Constant";
import { PublicationDetailParams } from './PublicationDetailParams';
import { Link, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from 'axios'
import { removeSpecialCharactersAndWhiteSpace } from '../../Helpers/Helper';

export const PublicationDetail = () => {
    const { dirimart, translation, setDirimartData } = useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [publication, setPublication] = useState(null);
    const [publicationDetailParams, setPublicationDetailParams] = useState([]);
    const [publicationsByTypeAndCategory, setPublicationsByTypeAndCategory] = useState([]);
    const [publicationsByTypeAndCategoryCurrentPage, setPublicationsByTypeAndCategoryCurrentPage] = useState(1);
    const [publicationsByTypeAndCategoryMaxPage, setPublicationsByTypeAndCategoryMaxPage] = useState(1);
    const [showMore, setShowMore] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const _getPublication = () => {

        const dataUrl = baseApiURL + "/publications/getPublicationDetailBySeoId";
        const data = { langId: dirimart.langId, seoId: params.Id };
        var config = {
            method: "post",
            url: dataUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    }

    const _getPublicationsByTypeAndCategory = () => {

        const dataUrl = baseApiURL + "/publications/getNPublisheddPublicationByTypeAndCategory";
        const data = {
            langId: dirimart.langId,
            publicationId: publication.Id,
            categoryId: publication.category.Id,
            typeCode: publication.type.typeCode,
            pageNumber: publicationsByTypeAndCategoryCurrentPage,
            recordCount: 4
        };
        var config = {
            method: "post",
            url: dataUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    }
    const _getPublicationsByTypeAndCategoryCount = () => {
        const dataUrl = baseApiURL + "/publications/getNPublisheddPublicationByTypeAndCategoryTotalCount";
        const data = { langId: dirimart.langId, currentPublicationId: publication.Id, categoryId: publication.category.Id, typeCode: publication.type.typeCode };
        var config = {
            method: "post",
            url: dataUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    }


    const _showMore = () => {

        var nextPage = publicationsByTypeAndCategoryCurrentPage + 1;
        if (nextPage > publicationsByTypeAndCategoryMaxPage)
            return;
        setPublicationsByTypeAndCategoryCurrentPage(nextPage);
    }

    useEffect(() => {

        setIsInitialLoad(false);
        _getPublication().then((response) => {
            if (response.responseResult) {
                setPublication(response.publication);
                setPublicationDetailParams(response.publicationDetails);
            }
        }).catch(error => console.log(error));
    }, [])

    useEffect(() => {
        if (isInitialLoad)
            return
        
        _getPublicationsByTypeAndCategory().then((response) => {
            setPublicationsByTypeAndCategory(response.publications)
        });
       
        _getPublicationsByTypeAndCategoryCount().then(response => {

            var maxPage = Math.ceil(response / 4);
            if (publicationsByTypeAndCategoryCurrentPage < maxPage) {
                setShowMore(true);
            } else {
                setShowMore(false);
            }
            setPublicationsByTypeAndCategoryMaxPage(maxPage);
        });
    }, [publication])


    useEffect(() => {
        if (isInitialLoad)
            return
        if (publicationsByTypeAndCategoryCurrentPage === publicationsByTypeAndCategoryMaxPage) {
            setShowMore(false);
        }
   

        _getPublicationsByTypeAndCategory().then((response) => {
            if (publicationsByTypeAndCategoryCurrentPage !== 1) {
                setPublicationsByTypeAndCategory(publicationsByTypeAndCategory.concat(response.publications));
                return
            }
            setPublicationsByTypeAndCategory(response.publications);
        })

    }, [publicationsByTypeAndCategoryCurrentPage])

    useEffect(() => {
        if (isInitialLoad)
            return
        _getPublication().then((response) => {
            setPublication(response.publication);
            setPublicationDetailParams(response.publicationDetails);;
        })
      

        _getPublicationsByTypeAndCategory().then((response) => {
            setPublicationsByTypeAndCategory(response.publications)
        });
       
        _getPublicationsByTypeAndCategoryCount().then(response => {

            var maxPage = Math.ceil(response / 4);
            if (publicationsByTypeAndCategoryCurrentPage < maxPage) {
                setShowMore(true);
            } else {
                setShowMore(false);
            }
            setPublicationsByTypeAndCategoryMaxPage(maxPage);
        });

        if (publicationsByTypeAndCategoryCurrentPage != 1)
            setPublicationsByTypeAndCategoryCurrentPage(1);
    }, [dirimart.langId])

    useEffect(() => {
        if (isInitialLoad)
            return

        if (publicationsByTypeAndCategoryCurrentPage !== 1){
          
            setPublicationsByTypeAndCategoryCurrentPage(1);

        }
        else {
            _getPublicationsByTypeAndCategory().then((response) => {
                setPublicationsByTypeAndCategory(response.publications)
            });
            _getPublicationsByTypeAndCategoryCount().then(response => {
                var maxPage = Math.ceil(response / 4);
                if (publicationsByTypeAndCategoryCurrentPage < maxPage) {
                    setShowMore(true);
                } else {
                    setShowMore(false);
                }
                setPublicationsByTypeAndCategoryMaxPage(maxPage);
            });
        }

        _getPublication().then((response) => {
            if (response.responseResult) {
                setPublication(response.publication);
                setPublicationDetailParams(response.publicationDetails);
            }

        }).catch(error => console.log(error));


    }, [params.Id])
    return (
        <div className='container'>
            {publication &&
                <>

                    <div className="detailContainer">
                        <img src={publication.imageUrl} alt="publication" />
                        <div>
                            <h2>{publication.title}</h2>
                            {
                                publication.detail &&
                                parse(publication.detail)
                            }
                        </div>
                    </div>
                    <PublicationDetailParams
                        params={publicationDetailParams}
                        publication={publication}
                    />
                </>
            }

            <div id="publicationsContainer" className="publicationsContainer publicationsDetailContainer">
                <h2>{translation.Publication.related}</h2>
                <div className="publications noneClass ">
                    {
                        publicationsByTypeAndCategory[0] &&
                        publicationsByTypeAndCategory.map(publicationByTypeAndCategory => {
                            return (
                                <Link to={(dirimart.langId === 1033 ? "/en" : "/tr") + `/publications/${removeSpecialCharactersAndWhiteSpace(publicationByTypeAndCategory.title)}/${publicationByTypeAndCategory.seoId}`}>
                                    <div
                                        className="publicationCard" key={publicationByTypeAndCategory.Id}>
                                        <div className="imgDiv">
                                            <img src={publicationByTypeAndCategory.imageUrl} alt="publication" />
                                        </div>
                                        <h3>{publicationByTypeAndCategory.title}</h3>
                                        <p>{publicationByTypeAndCategory.summary}</p>
                                    </div>
                                </Link>
                            )
                        })

                    }


                </div>
                <button className="btnWhite smallBtn" disabled={!showMore} onClick={() => { _showMore() }}>{translation.Common.seemore}</button>
            </div>


        </div>
    )
}

