import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "..//../ContextAPI/AppProvider";
import { baseApiURL, lottieOption } from "../../Helpers/Constant";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Pagination,
  Navigation,
  EffectCreative,
  EffectFade,
} from "swiper";
import BlockUI from "react-block-ui";
import Lottie from "react-lottie";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "react-block-ui/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const MainPageBanner = () => {
  let navigate = useNavigate();
  let carousel = useRef(null);
  const [banners, setBanners] = useState([]);
  const [selectedBannerInformation, setSelectedBannerInformation] = useState(
    {}
  );
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedBannerIndex, setSelectedBannerIndex] = useState(0);
  const { dirimart } = useContext(AppContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [blockUiBanner, setBlockUiBanner] = useState(true);
  const [logoDisplay, setLogoDisplay] = useState("visible");
  const [bannerColor, setBannerColor] = useState("");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setLogoDisplay(position >= 60 ? "hidden" : "visible");
    setScrollPosition(position);
  };

  const _bannerButtonOnClick = () => {
    window.location.replace(selectedBannerInformation.buttonLink);
  };
  const _getMainPageBanners = async () => {
    const getFormDataUrl = baseApiURL + "/MainPageBanner/GetBanners";
    const data = { langId: dirimart.langId };
    var config = {
      method: "post",
      url: getFormDataUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data.mainPageBanners))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _onChange = (e, v) => {
    let arr = banners.filter((item, index) => index === e.activeIndex);
    setBannerColor(arr[0]?.color ?? "#fff");
    setSelectedBannerIndex(e.activeIndex);
    setSelectedBannerInformation({
      title1: arr[0]?.title1,
      title2: arr[0]?.title2,
      subtitle: arr[0]?.subtitle,
      buttonText: arr[0]?.buttonText,
      buttonLink: arr[0]?.buttonLink,
      color: arr[0]?.color,
    });
    setSelectedItem(e);
  };
  const _prepare = () => {
    setBlockUiBanner(true);
    _getMainPageBanners().then((response) => {
      const selectedBanner = response[selectedBannerIndex] || response[0];
      setBannerColor(selectedBanner.color ?? "#fff");
      setBanners(response);
      setSelectedBannerInformation({
        title1: selectedBanner.title1,
        title2: selectedBanner.title2,
        subtitle: selectedBanner.subtitle,
        buttonText: selectedBanner.buttonText,
        buttonLink: selectedBanner.buttonLink,
        color: selectedBanner.color,
      });
      setBlockUiBanner(false);
    });

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };

  useEffect(() => {
    _prepare();
  }, []);
  useEffect(() => {
    if (carousel && carousel?.state?.selectedItem > 0) {
      carousel.state.selectedItem = 0;
    }
  }, [banners]);
  useEffect(() => {
    _prepare();
  }, [dirimart.langId]);
  return (
    <BlockUI
      blocking={blockUiBanner}
      keepInView
      loader={
        <Lottie options={lottieOption} height={300} width={300} speed={0.5} />
      }
    >
      <div className="mainpage ">
        {/* <svg
          id="logo2"
          width="237"
          height="35"
          viewBox="0 0 237 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ visibility: logoDisplay, cursor: "pointer" }}
        >
          <g clipPath="url(#clip0_1506_3164)">
            <path
              d="M37.0938 34.9998H41.6913V16.2975H37.0938V34.9998ZM37.0938 12.4324H41.6913V0.0532227H37.0938V12.4324Z"
              fill="white"
            ></path>
            <path
              d="M87.2324 34.9998H91.8299V16.2975H87.2324V34.9998ZM87.2324 12.4324H91.8299V0.0532227H87.2324V12.4324Z"
              fill="white"
            ></path>
            <path
              d="M164.641 28.1603L167.027 34.9822H171.816L169.378 28.1603H164.641ZM154.645 0.0534329L148.497 16.4758H153.13L156.961 5.7888L161.942 20.341H146.982L141.549 34.9466H146.39L150.204 24.2595H167.932L159.225 0H154.488L154.645 0.0534329Z"
              fill="white"
            ></path>
            <path
              d="M211.922 4.1145H218.383V0H211.904V4.1145H211.922ZM222.179 0V35H226.777V4.1145H236.982V0H222.179Z"
              fill="white"
            ></path>
            <path
              d="M184.007 3.91852V12.4325H179.549V0.053361H192.262C194.234 0.00589149 196.207 0.107122 198.165 0.356184C200.462 0.67177 202.555 1.86883 204.02 3.70463C205.485 5.54043 206.212 7.87748 206.054 10.2417C206.054 15.692 203.372 18.9516 197.974 19.8422C196.268 20.0405 194.553 20.1415 192.836 20.145H184.024V34.9821H179.549V16.2799H193.045C194.441 16.3281 195.837 16.2146 197.208 15.9414C198.471 15.7038 199.604 14.9984 200.391 13.9602C201.178 12.9219 201.564 11.6235 201.474 10.3129C201.523 9.39151 201.365 8.47087 201.012 7.62176C200.658 6.77264 200.12 6.01764 199.437 5.4147C198.574 4.70118 197.539 4.23958 196.441 4.07882C195.232 3.96169 194.016 3.91409 192.802 3.9363H183.99L184.007 3.91852ZM200.569 24.0101L207.535 34.9999H202.119L195.24 24.0101H200.586H200.569Z"
              fill="white"
            ></path>
            <path
              d="M8.4114 4.16772H4.63238V30.9921H8.4114V34.9998H0V0.0532227H8.4114V4.16772ZM12.1905 30.8853C14.2812 30.8918 16.3466 30.417 18.2334 29.496C21.7164 27.5129 23.4579 23.5231 23.4579 17.5265C23.4579 10.4612 21.0895 6.18641 16.3526 4.70211C15.009 4.35135 13.6293 4.16594 12.2427 4.14989V0.0888265C14.0437 0.0800929 15.8409 0.259168 17.6065 0.62321C20.0003 1.22301 22.1685 2.53004 23.8411 4.38145C26.6855 7.48069 28.1077 11.8683 28.1077 17.5443C28.1077 23.6716 26.5113 28.2551 23.3186 31.295C21.5885 32.9202 19.4668 34.0452 17.1711 34.5545C15.5468 34.8699 13.8959 35.019 12.2427 34.9998V30.9565L12.1905 30.8853Z"
              fill="white"
            ></path>
            <path
              d="M108.025 12.4324L105.709 6.14485L106.196 34.9998H101.703V0.0532227H107.555L112.082 12.4324H107.973H108.025ZM126.728 16.2975L119.762 34.9998H116.279L109.418 16.2975H113.528L118.073 28.7657L122.653 16.2975H126.711H126.728ZM128.644 0.0710518H134.495V34.9998H129.985L130.612 5.69956L128.139 12.4324H124.081L128.644 0.0710518Z"
              fill="white"
            ></path>
            <path
              d="M78.5586 10.2596C78.7065 11.9343 78.3734 13.6168 77.6001 15.1007C76.8267 16.5846 75.6467 17.8054 74.2048 18.6133L71.9061 15.051C72.6152 14.5154 73.1803 13.8049 73.5487 12.9859C73.9171 12.1668 74.077 11.2657 74.0133 10.3665C74.0622 9.44505 73.9039 8.52442 73.5507 7.67531C73.1975 6.82619 72.6587 6.07118 71.9757 5.46825C71.1152 4.75163 70.0793 4.28964 68.9804 4.13237C67.7767 4.01236 66.5672 3.96475 65.3581 3.98985H56.5461V16.369H65.5845C66.5771 16.369 67.4304 16.369 68.1619 16.2621L80.0563 35.0713H74.6402L65.3581 20.2341H52.1053V0.0713044H64.7833C66.7558 0.0266222 68.7289 0.127788 70.687 0.374073C72.2436 0.591691 73.7223 1.20399 74.9885 2.15524C76.1832 3.1002 77.1343 4.32902 77.7595 5.73509C78.3846 7.14115 78.6649 8.682 78.576 10.2239L78.5586 10.2596ZM56.5461 35H52.0879V24.0102H56.5461V35Z"
              fill="white"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_1506_3164">
              <rect width="237" height="35" fill="white"></rect>
            </clipPath>
          </defs>
        </svg> */}

        <Swiper
          grabCursor={true}
          effect={"fade"}
          speed={2000}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          onActiveIndexChange={_onChange}
          // creativeEffect={{
          //   prev: {
          //     shadow: true,
          //     translate: ["-20%", 0, -1],
          //   },
          //   next: {
          //     translate: ["100%", 0, 0],
          //   },
          // }}
          modules={[EffectFade, Autoplay, Pagination, Navigation]}
        >
          {banners.map(function (item, index) {
            return (
              <SwiperSlide key={index}>
                <div className={"slide"}>
                  <a href={selectedBannerInformation.buttonLink}>
                    {selectedBannerInformation.buttonText && (
                      <a href={selectedBannerInformation.buttonLink}>
                        <div
                          className="titleField"
                          style={{ color: bannerColor }}
                        >
                          <h2>{selectedBannerInformation?.title1}</h2>
                          <h2>{selectedBannerInformation?.title2}</h2>
                          <p>{selectedBannerInformation?.subtitle}</p>
                        </div>
                      </a>
                    )}
                    <img
                      className="caroselimages"
                      style={{ objectFit: "cover" }}
                      src={item.imageURL}
                      alt=""
                    ></img>
                  </a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </BlockUI>
  );
};
