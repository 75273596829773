import React from 'react'
import parse from 'html-react-parser';

export const FairListCard = (props) => {
    return (
        <div className="fairCard">
            <img src={props.imageUrl} alt="fair" />
            <div className="info">
                <p className="title">{props.title}</p>
                <p className="description">{props.summary&&parse(props.summary)}</p>
                <p className="date">{props.dateTitle}</p>
            </div>
        </div>
    )
}

