import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const PublicationComponentSkeleton = () => {
  return (
    <div id="publicationsContainer" className="publicationsContainer skeletonPublicationComponent">
      <h1 style={{width:"30%"}}><Skeleton height={"100%"} width={"100%"}></Skeleton></h1>
      <div className="publications noneClass">
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
        <div className="publicationCard">
          <div className="imgDiv" style={{height:"300px"}}>
          <Skeleton height={"100%"} width={"100%"}></Skeleton>
          </div>
          <h3><Skeleton height={"100%"} width={"100%"}></Skeleton></h3>
          <p><Skeleton height={"100%"} width={"100%"}></Skeleton></p>
        </div>
     
      
      </div>
      
    </div>
  )
}


