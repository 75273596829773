import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../ContextAPI/AppProvider";
import { baseApiURL, lottieOption } from "../../Helpers/Constant";
import { useNavigate } from "react-router-dom";
import { _htmlToPlaintext } from "../../Helpers/Helper";
import { NewsComponent } from "../../components/NewsComponent";
import Lottie from "react-lottie";
import BlockUI from "react-block-ui";
import axios from "axios";
import parse from "html-react-parser";
import "react-block-ui/style.css";
import { NewsDetailSkeleton } from "../../components/Skeletons/News/NewsDetailSkeleton";

export const NewsDetail = () => {
  const params = useParams();
  const { dirimart, translation } = useContext(AppContext);
  const [newsDetail, setNewsDetail] = useState({});
  const [blockUiNewsDetail, setBlockUiNewsDetail] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [initialLoad, setInitialLoad] = useState(true);

  const _getNewsDetail = () => {
    const getNewsDetailUrl = baseApiURL + "/news/getPublishedNewsDetailBySeoId";
    const data = { langId: dirimart.langId, seoId: params.Id };
    var config = {
      method: "post",
      url: getNewsDetailUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  useEffect(() => {
    setInitialLoad(false);
    _getNewsDetail().then((response) => {
      
      setNewsDetail(response.newsDetail);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    if (initialLoad) return;
    setBlockUiNewsDetail(true);
    _getNewsDetail().then((response) => {
      setNewsDetail(response.newsDetail);
      setIsLoading(false);
    });
  }, [params.Id]);
  useEffect(() => {
    if (initialLoad) return;
    setIsLoading(false);
    _getNewsDetail().then((response) => {
      setNewsDetail(response.newsDetail);
      setIsLoading(false);
    });
  }, [dirimart.langId]);

  return (
    <div className="newsDetailPage">
      {isLoading && <NewsDetailSkeleton></NewsDetailSkeleton>}
      {newsDetail?.detail && (
        <div className="newsDetail">
          <div className="leftSide">
            <img src={newsDetail.imageURL} alt="news" />
          </div>
          <div className="rightSide">
            <h2>{newsDetail.title}</h2>
            {newsDetail.detail && <p>{parse(newsDetail.detail)}</p>}
          </div>
        </div>
      )}

      <div
        id="newsContainer"
        className="newsContainer artistDetail exhibitionsDetail"
      >
        <div className="news artistsNews" style={{ display: "unset" }}>
          {newsDetail?.type &&newsDetail.newsId&&  (
            <NewsComponent
              recordCount={3}
              getNewsUrl={"/news/getPublishedNewsExceptGivenId"}
              getNewsTotalCountUrl={"/news/getTotalCountExceptgivenId"}
              type={newsDetail.type}
              Id={newsDetail.newsId}
              title={translation.News.relatednews}
              arrowStyles={{
                color: "black",
                backgroundCoolor: "black",
                border: "1px solid black",
                fontSize: "xx-large",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
