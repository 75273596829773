import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../ContextAPI/AppProvider";
import { removeSpecialCharactersAndWhiteSpace } from "../../Helpers/Helper";
export const FairCard = (props) => {
  let navigate = useNavigate();
  const { translation, dirimart } = useContext(AppContext);
  return (
    <div className="fairCard" key={props.item.index}>
      <Link
        to={
          (dirimart.langId === 1033 ? "/en" : "/tr") +
          "/Fairs/" +
          removeSpecialCharactersAndWhiteSpace(props.item.title) +
          "/" +
          props.item.seoId
        }
      >
        <img src={props.item.imageURL} alt="fair"></img>
        <div className="rightSide">
          <p className="title">{props.item.title}</p>
          <p className="date">{props.item.fairDatesTitle}</p>
          <p className="details">{props.translation.Common.seemore}</p>
        </div>
      </Link>
    </div>
  );
};
