import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../ContextAPI/AppProvider";
import {
  baseApiURL,
  artistSelectedWorkCount,
  lottieOption,
} from "../Helpers/Constant";
import { SelectedWorkCardComponent } from "./SelectedWorkCardComponent";
import { HiArrowRight, HiArrowLeft, HiArrowUpRight } from "react-icons/hi2";
import { SelectedWorksComponentSkeleton } from "./Skeletons/SelectedWorks/SelectedWorksComponentSkeleton";
import Lottie from "react-lottie";
import BlockUI from "react-block-ui";
import ReactModal from "react-modal";
import axios from "axios";
import "react-block-ui/style.css";

export const SelectedWorksComponent = (props) => {
  
  const [blockUi, setblockUi] = useState(true);
  const [selectedWorks, setSelectedWorks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedWorkDetail, setSelectedWorkDetail] = useState({});
  const [selectedWorksCurrentPage, setSelectedWorksCurrentPage] = useState(1);
  const [selectedWorksMaxPageCount, setSelectedWorksMaxPageCount] = useState(0);
  const [selectedWorksTotalCount, setSelectedWorksTotalCount] = useState(0);
  const [selectedWorksPerPage, setSelectedWorksPerPage] = useState(
    props.recordCount
  );
  const { dirimart, translation } = useContext(AppContext);
  const [showMore, setShowMore] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [selectedWorkIndexForModal, setSelectedWorkIndexForModal] = useState(0);
  const [selectedWorkModalNext, setSelectedWorkModalNext] = useState(false);
  const [selectedWorkModalPrev, setSelectedWorkModalPrev] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -41%)",
      height: "70vh",
      padding: "0",
      width: "70vw",
      border: "none",
      borderRadius: "0",
      overflow: "hidden",
    },
  };

  const _nextSelectedWork = () => {
    let nextIndex = selectedWorkIndexForModal + 1;
    if (nextIndex > selectedWorks.length - 1) {
      return;
    }
    if (nextIndex == selectedWorks.length - 1) {
      setSelectedWorkModalNext(false);
    }
    setSelectedWorkModalPrev(true);
    setSelectedWorkDetail(selectedWorks[selectedWorkIndexForModal + 1]);
    setSelectedWorkIndexForModal(selectedWorkIndexForModal + 1);
  
  };
  const _prevSelectedWork = () => {
    if (selectedWorkIndexForModal - 1 < 0) {
      return;
    }
    if (selectedWorkIndexForModal - 1 == 0) {
      setSelectedWorkModalPrev(false);
    }
    setSelectedWorkModalNext(true);
    setSelectedWorkDetail(selectedWorks[selectedWorkIndexForModal - 1]);
    setSelectedWorkIndexForModal(selectedWorkIndexForModal - 1);
  };

  const _showModal = (index) => {
    setSelectedWorkIndexForModal(index);
    setSelectedWorkDetail(selectedWorks[index]);
    if (index >= selectedWorks.length - 1) {
      setSelectedWorkModalNext(false);
      setSelectedWorkModalPrev(true);
    } else if (index <= 0) {
      setSelectedWorkModalNext(true);
      setSelectedWorkModalPrev(false);
    } else {
      setSelectedWorkModalNext(true);
      setSelectedWorkModalPrev(true);
    }
    setShowModal(!showModal);
  };
  const _closeModal = () => {
    setShowModal(false);
  };
  const _getSelectedWorks = async () => {
  
    const getartistSelectedWorkUrl = baseApiURL + props.getSelectedWorkUrl;
    const data = {
      langId: dirimart.langId,
      Id: props.Id,
      pageNumber: selectedWorksCurrentPage,
      recordCount: artistSelectedWorkCount,
    };
    var config = {
      method: "post",
      url: getartistSelectedWorkUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };

  const _getSelectedWorksTotalCount = async () => {
    const getPublishedArtistTotalCountUrl =
      baseApiURL + props.getSelectedWorkTotalCountUrl;
    const data = { Id: props.Id };
    var config = {
      method: "post",
      url: getPublishedArtistTotalCountUrl,
      data: data,
    };
    return new Promise(async (resolve, reject) => {
      await axios(config)
        .then((e) => resolve(e.data))
        .catch((e) => reject(e.response.data.ExceptionMessage));
    });
  };
  const _nextPage = () => {
    let nextPageNumber = selectedWorksCurrentPage + 1;
    if (nextPageNumber <= selectedWorksMaxPageCount) {
      setSelectedWorksCurrentPage(nextPageNumber);
    }
    if (nextPageNumber === selectedWorksMaxPageCount) {
      setShowMore(false);
    }
  };

  useEffect(() => {
    _getSelectedWorks()
      .then((response) => {
   
        setSelectedWorks(response.selectedWorks);
        setblockUi(false);
        setIsLoading(false)
      })
      .catch((error) => console.log(error));
    _getSelectedWorksTotalCount().then((response) => {
      setSelectedWorksTotalCount(response);
      var maxPage = Math.ceil(response / selectedWorksPerPage);
      setSelectedWorksMaxPageCount(maxPage);
      if (selectedWorksCurrentPage < maxPage) {
        setShowMore(true);
      }
    });
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }
    setblockUi(true);
    setIsLoading(true);
    _getSelectedWorks()
      .then((response) => {
        if (selectedWorksCurrentPage !== 1)
          setSelectedWorks(selectedWorks.concat(response.selectedWorks));
        else setSelectedWorks(response.selectedWorks);
        setblockUi(false);
        setIsLoading(false)

      })
      .catch((error) => console.log(error));
    if (selectedWorksCurrentPage < selectedWorksMaxPageCount) {
      setShowMore(true);
    }
  }, [selectedWorksCurrentPage]);

  useEffect(() => {
    if (isInitialLoad) return;
    if (selectedWorksCurrentPage !== 1) setSelectedWorksCurrentPage(1);
    setblockUi(true);
    setIsLoading(true);
    _getSelectedWorks()
      .then((response) => {
        setSelectedWorks(response.selectedWorks);
        setblockUi(false);
        setIsLoading(false)

      })
      .catch((error) => console.log(error));
  }, [dirimart.langId]);

  return (
    <>
      {
        isLoading &&
        <SelectedWorksComponentSkeleton></SelectedWorksComponentSkeleton>
      }
      {
        isLoading == false && selectedWorks[0] &&
        <>
          <div id="selectedWorks" className="selectedWorks">
            <h1>{translation.SelectedWork.Title}</h1>
            <div className="works">
              {selectedWorks.map((selectedWork, index) => {
                return (
                  <div onClick={() => _showModal(index)} key={selectedWork.selectedWorkName+index}>
                    <SelectedWorkCardComponent
                      overlay={selectedWork.selectedWorkName}
                      image={selectedWork.imageUrl}
                    />
                  </div>
                );
              })}
            </div>
            {
              isLoading &&
              <SelectedWorksComponentSkeleton></SelectedWorksComponentSkeleton>
            }
            {showMore &&
            <button
              className="btnWhite smallBtn"
              onClick={() => _nextPage()}
              // disabled={!showMore}
            >
              {translation.Common.seemoreWork}
            </button>
}
          </div>
          <ReactModal
            ariaHideApp={false}
            style={modalStyle}
            isOpen={showModal}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => _closeModal()}
          >
            <div className="modalContent">
              <div className="modalHeader" style={{ paddingRight: "1.5rem" }}>
                <svg
                  onClick={() => _closeModal()}
                  className="close"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25 15L15 25"
                    stroke="#25282E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M25 25L15 15"
                    stroke="#25282E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M32.5 6.25H7.5C6.80964 6.25 6.25 6.80964 6.25 7.5V32.5C6.25 33.1904 6.80964 33.75 7.5 33.75H32.5C33.1904 33.75 33.75 33.1904 33.75 32.5V7.5C33.75 6.80964 33.1904 6.25 32.5 6.25Z"
                    stroke="#25282E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </div>
              <div className="modalBody">
                <img src={selectedWorkDetail.imageUrl} alt="work" />
                <div>
                  <h3>{selectedWorkDetail.selectedWorkName}</h3>
                  <p>{selectedWorkDetail.detail}</p>
                </div>
              </div>
              <div
                className="modalFooter"
                style={{
                  marginLeft: "2rem",
                  marginRight: "2rem",
                  marginBottom: "2rem",
                  display: "flex",
                  justifyContent: "end",
                  gap: "1rem",
                }}
              >
                <div
                  className={
                    "iconDiv whiteIconDiv " +
                    (selectedWorkModalPrev ? "" : "disabled")
                  }
                  style={{ fontSize: "xx-large", height: "47px" }}
                  onClick={() => _prevSelectedWork()}
                >
                  <HiArrowLeft
                    style={{
                      height: "47px",
                      width: "47px",
                      border: "2px solid #0000000f",
                      padding: "0.5rem",
                      margin: "0",
                    }}
                  />
                </div>
                <div
                  className={
                    "iconDiv whiteIconDiv " +
                    (selectedWorkModalNext ? "" : "disabled")
                  }
                  style={{ fontSize: "xx-large", height: "47px" }}
                  onClick={() => _nextSelectedWork()}
                >
                  <HiArrowRight
                    style={{
                      height: "47px",
                      width: "47px",
                      border: "2px solid #0000000f",
                      padding: "0.5rem",
                      margin: "0",
                    }}
                  />
                </div>
              </div>
            </div>
          </ReactModal>
        </>
      }

    </>
  );
};
