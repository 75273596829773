
import React, { useEffect, useState, useContext } from "react";
import axios from 'axios'
import { AppContext } from '..//../ContextAPI/AppProvider'
import { useParams } from 'react-router-dom';
import { baseApiURL } from "../../Helpers/Constant";


export const Locations = (props) => {

    const params = useParams();
    const { dirimart, translation } = useContext(AppContext);
    const [locations, setLocations] = useState([]);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const [locationMaxPage, setLocationMaxPage] = useState(0);
    const [locationTotalCount, setLocationTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const _selectLocation = ({ latitude, longitude }, locationId) => {
        setSelectedLocationId(locationId)
        props.setGmapMarker({ latitude: latitude, longitude: longitude });
        props.setAdressDetail(locations.find(elem => elem.locationId == locationId).adress)
        return;
    }
    const _getPublishedLocations = async () => {
        const getDataUrl = baseApiURL + "/StaticWebInformationLocation/getNPublishedStaticWebInformationLocations";
        const data = { langId: dirimart.langId, staticWebInformationId: props.Id, recordCount: 4, pageNumber: currentPage };
        var config = {
            method: "post",
            url: getDataUrl,
            data: data
        };
        return new Promise(async (resolve, reject) => {
            await axios(config)
                .then((e) => resolve(e.data))
                .catch((e) => reject(e.response.data.ExceptionMessage));
        });
    }


    useEffect(() => {
        _getPublishedLocations().then((response) => {

            setLocations(response.locations);
            props.setBlockLocationsUi(false);
        })
    }, [])
    
    return (
        <div className="locationDiv">
        
            <div className="locations">
                {locations &&
                    locations.map(location => {
                        return (
                            <div className={(location.locationId===selectedLocationId?"activeLocation":"") + " location"} key={location.locationId}>
                                <img src={location.imageURL} alt={location.name} onClick={() => _selectLocation({ latitude: location.latitude, longitude: location.longitude }, location.locationId)} />
                                <h5>{location.name}</h5>
                                <p>{location.adress}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


